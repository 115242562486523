const { floxReducerStoreKey } = require('flox-web-client/src/ducks/constants');

const getTracks = (state = {}) => state[`${floxReducerStoreKey}`].floxStorage?.tracks;

const getContext = (state = {}) => state[`${floxReducerStoreKey}`]?.floxStorage?.context;

const getNoteData = (state = {}) => state[`${floxReducerStoreKey}`].floxStorage?.noteData;

const getAdvance = (state = {}) => state[`${floxReducerStoreKey}`].floxStorage?.noteData?.advance;

const getSearchData = (state = {}) => state[`${floxReducerStoreKey}`].floxStorage?.searchData;


module.exports = {
  getTracks,
  getContext,
  getNoteData,
  getAdvance,
  getSearchData,
};
