/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Thumbnail from '@andes/thumbnail';
import TitleAndSubTitle from '../TitleAndSubTitle';
import Fallback from '../Fallback';
import { getContext } from '../../helpers/selectors';
import { checkSuScope } from '../../helpers/checkSuScope';

const { Image } = require('nordic/image');

const CardChild = (props) => {
  const { title, image, className, children, events, action, performEvent, fallback } = props;
  const newEvents = events || action;
  const { device, suScope } = useSelector(getContext);
  let filterEvent;
  if (newEvents && !device?.webView) {
    filterEvent = newEvents?.filter((e) => e.type !== 'redirect');
  } else {
    filterEvent = newEvents;
  }
  const onClickEvent = () => {
    if (performEvent && filterEvent) {
      filterEvent.forEach((event) => performEvent(event));
    }
  };

  if (fallback) {
    return (
      <div className={className}>
        <Fallback {...fallback} className={`${className}-fallback`} />
      </div>
    );
  }

  const CardComponent = (
    <div className={className} aria-hidden="true" onClick={onClickEvent}>
      {image && (
        <Thumbnail size="72">
          <Image alt={image.alt} src={image.href} onload="on" />
        </Thumbnail>
      )}
      <div className={`${className}-content`}>
        <TitleAndSubTitle className={`${className}-title`} subTitle={title} subTitleSize="m" />
        {children && <div className={`${className}-info`}>{children}</div>}
      </div>
    </div>
  );

  return device?.webView ? (
    CardComponent
  ) : (
    <a href={checkSuScope(newEvents[0]?.data?.url, suScope)}>{CardComponent}</a>
  );
};

CardChild.propTypes = {
  title: PropTypes.string,
  image: PropTypes.shape({
    href: PropTypes.string,
    alt: PropTypes.string,
  }),
  fallback: PropTypes.shape(),
  events: PropTypes.shape(),
  action: PropTypes.shape(),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(), PropTypes.node]),
  performEvent: PropTypes.func,
};

CardChild.defaultProps = {
  className: 'sc-card-child',
  fallback: undefined,
  action: [],
};

export default CardChild;
