/* eslint-disable max-len */
const React = require('react');
const PropTypes = require('prop-types');

const DoubleCheck = ({ color }) => (
  <svg width="15" height="8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.79 5.26 8.365.684l1.028 1.028L3.79 7.317.61 4.137l1.028-1.03L3.789 5.26ZM8.786 5.26 13.363.684l1.029 1.028-5.606 5.605-1.951-1.952 1.055-1 .896.895Z"
      fill={color}
    />
  </svg>
);

DoubleCheck.defaultProps = {
  color: '#00A650',
};

DoubleCheck.propTypes = {
  color: PropTypes.string,
};

module.exports = DoubleCheck;
