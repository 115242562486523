/* eslint-disable max-len */
const React = require('react');

const GlobalIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_9056_280406)">
      <path
        d="M22.5 12C22.5 13.3789 22.2284 14.7443 21.7007 16.0182C21.1731 17.2921 20.3996 18.4496 19.4246 19.4246C18.4496 20.3996 17.2921 21.1731 16.0182 21.7007C14.7443 22.2284 13.3789 22.5 12 22.5C10.6211 22.5 9.25574 22.2284 7.98182 21.7007C6.7079 21.1731 5.55039 20.3996 4.57538 19.4246C3.60036 18.4496 2.82694 17.2921 2.29926 16.0182C1.77159 14.7443 1.5 13.3789 1.5 12C1.5 9.21523 2.60625 6.54451 4.57538 4.57538C6.54451 2.60625 9.21523 1.5 12 1.5C14.7848 1.5 17.4555 2.60625 19.4246 4.57538C21.3938 6.54451 22.5 9.21523 22.5 12Z"
        stroke="#3483FA"
        strokeWidth="1.5"
      />
      <path
        d="M16.2003 12C16.2003 13.3785 16.0911 14.7445 15.8801 16.0181C15.6701 17.2917 15.3603 18.4487 14.9698 19.4241C14.5802 20.3995 14.1172 21.1723 13.608 21.7004C13.0977 22.2275 12.5517 22.4994 12.0005 22.4994C11.4493 22.4994 10.9033 22.2275 10.3941 21.7004C9.88381 21.1723 9.42078 20.3985 9.03125 19.4241C8.64067 18.4487 8.33094 17.2927 8.1199 16.0181C7.90462 14.6896 7.79788 13.3458 7.80071 12C7.80071 10.6214 7.90886 9.2554 8.1199 7.98181C8.33094 6.70823 8.64067 5.55119 9.03125 4.57578C9.42078 3.60038 9.88381 2.82762 10.393 2.2995C10.9033 1.77347 11.4493 1.50049 12.0005 1.50049C12.5517 1.50049 13.0977 1.77242 13.6069 2.2995C14.1172 2.82762 14.5802 3.60143 14.9698 4.57578C15.3603 5.55119 15.6701 6.70718 15.8801 7.98181C16.0911 9.2554 16.2003 10.6214 16.2003 12Z"
        stroke="#3483FA"
        strokeWidth="1.5"
      />
      <path
        d="M1.50098 12H22.4999"
        stroke="#3483FA"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_9056_280406">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

module.exports = GlobalIcon;
