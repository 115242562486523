const React = require('react');
const PropTypes = require('prop-types');
const Spinner = require('@andes/spinner');

const Loading = props => {
  const { size, modifier, loadingStatus } = props;
  return (
    loadingStatus && (
      <Spinner
        className="spinner_index"
        modifier={modifier}
        size={size}
      />
    )
  );
};

Loading.propTypes = {
  loadingStatus: PropTypes.bool,
  modifier: PropTypes.string,
  size: PropTypes.string,
};

Loading.defaultProps = {
  loadingStatus: false,
  modifier: 'fullscreen',
  size: 'large',
};

export default Loading;
