/* eslint-disable max-len */
const React = require('react');
const PropTypes = require('prop-types');

const Flag24 = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4851_236265)">
      <path
        d="M7.63281 4.88696V22.7155"
        stroke="#3483FA"
        strokeWidth="1.92"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9757 14.487H7.63281V4.88696H19.9757L15.8614 9.68696L19.9757 14.487Z"
        stroke={color}
        strokeWidth="1.92"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4851_236265">
        <rect width="19.2" height="19.2" fill="white" transform="translate(4.20312 4.20068)" />
      </clipPath>
    </defs>
  </svg>
);

Flag24.defaultProps = {
  color: '#3483FA',
};

Flag24.propTypes = {
  color: PropTypes.string,
};

module.exports = Flag24;
