/* eslint-disable implicit-arrow-linebreak */
import { LoggerFactory } from 'nordic/logger';

const log = LoggerFactory('WK2-Methods');

export const canExecuteMobileWebkit = () => window && window.MobileWebKit;

const isValidWebkitEnvironment = () =>
  typeof MobileWebKit !== 'undefined' &&
  typeof MobileWebKit.executeNative === 'function' &&
  typeof MobileWebKit.events !== 'undefined' &&
  typeof MobileWebKit.events.subscribeMethod === 'function' &&
  typeof MobileWebKit.events.unsubscribe === 'function';

export const isWebkit2 = () =>
  isValidWebkitEnvironment() &&
  MobileWebKit?.engine === MobileWebKit?.Engines?.V200;

export const isConnected = async (numberOfRetries = 1, timeOut = 200) => {
  if (isValidWebkitEnvironment()) return true;
  if (numberOfRetries > 0) {
    return new Promise((resolve) => {
      setTimeout(() => {
        isConnected(numberOfRetries - 1, timeOut).then((v) => {
          resolve(v);
        });
      }, timeOut);
    });
  }
  return false;
};

export async function handleTopBarSetup(options) {
  const connected = await isConnected(5, 100);
  if (connected) {
    MobileWebKit.topbar
      .setup({
        use_web_title: true,
        bar_title: options.title || '',
        bar_elevation: options.elevation || 'default',
        bar_left_button_style: options.leftButton || 'back',
      })
      .catch((error) => {
        log.error('Error setting up topbar', error);
      });
  } else {
    log.error('Error setting up topbar: not connected');
  }
}

export const addNativeEventListener = (method, callback) => {
  let subscriptionId;
  try {
    subscriptionId = MobileWebKit.events.subscribeMethod(method, () => {
      if (typeof callback === 'function') callback();
    });
    // eslint-disable-next-line no-empty
  } catch (error) {}

  return subscriptionId;
};
