import { useEffect, useState } from 'react';


export const useYoutubeVideo = () => {
  const [scriptFlag, setScriptFlag] = useState(false);
  const [ytLocal, setYtLocal] = useState({});
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!scriptFlag) {
      const script = document.createElement('script');
      script.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
      script.onload = (scriptLoaded) => {
        if (scriptLoaded) {
          setScriptFlag(true);
        }
      };
    } else {
      setYtLocal(window.YT);
    }
  }, [scriptFlag]);

  return { scriptFlag, ytLocal };
};
