/* eslint-disable func-names */
const canUseDOM = typeof window !== 'undefined';
const ERRORS_TO_SKIP = ['receiveMessageFromApp', 'Script error.'];
const Console = console;

/**
 * Skip expected errors on third party scripts
 * see: https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror#notes
 * */

if (canUseDOM) {
  window.addEventListener('error', (ev) => {
    const { message, lineno, colno, filename, error } = ev;
    if (ERRORS_TO_SKIP.every((err) => !message.includes(err))) {
      let context = {};
      if (window.__PRELOADED_STATE__ instanceof HTMLElement) {
        context = JSON.parse(window.__PRELOADED_STATE__.innerText);
      } else {
        context = window.__PRELOADED_STATE__;
      }
      const user = context?.user;
      const stackData = {
        message,
        cause: `in: ${filename} at: ${lineno}:${colno}`,
        userId: user ? user.rootId : '',
        operatorId: user ? user.operator && user.operatorId : null,
        stackTrace: error ? error?.stack : '',
      };
      if (typeof window !== 'undefined' && typeof window.newrelic !== 'undefined') {
        window.newrelic.noticeError(message, stackData);
      }
      if (typeof console !== 'undefined') {
        Console.log('Error catched on object window listener', stackData);
      }
    }
    return false;
  });
}
