const React = require('react');
const PropTypes = require('prop-types');
const Dropdown = require('@andes/dropdown');
const { Text } = require('@andes/typography');
const { getParams, useQueryParams } = require('../../hooks/useQueryParams');
const { NAME_CONFIG_KEYS } = require('../../helpers/constants');

const { updateQuery } = useQueryParams();

const { DropdownItem } = Dropdown;

const DropdownWrapper = (props) => {
  const {
    className,
    type,
    options,
    label,
    placeholder,
    message,
    multiple,
    disabled,
    onDropdownChange,
    performEvent,
  } = props;
  const selectedValue = options?.filter((e) => e.checked)[0]?.id;
  const labelCheck = options?.filter((e) => e.checked)[0]?.label ?? label;

  const onChangeHandler = (_, value) => {
    if (value !== selectedValue) onDropdownChange?.(value);
    if (value) updateQuery({ key: 'sort', value });
    if (performEvent) {
      const requestBrick = [
        {
          type: 'request',
          data: {
            key: NAME_CONFIG_KEYS.SEARCH_CARD,
            arguments: getParams(),
          },
        },
      ];
      requestBrick.forEach((event) => performEvent(event));
    }
  };

  const transformInItems = () => options?.map((item) => {
    const { id: value, label: primary, optionType } = item;
    return {
      value,
      primary,
      optionType,
    };
  });

  const defaultClassName = 'sc-dropdown-wrapper';

  const items = transformInItems();

  const dropdownProps = {
    className: defaultClassName,
    type,
    position: false,
    label: labelCheck,
    placeholder,
    message,
    multiple,
    disabled,
    value: selectedValue,
    size: 'default',
    defaultValue: selectedValue,
    options: { items },
    onChange: onChangeHandler,
  };
  return (
    <div className={className}>
      <Text component="p" size="s" color="secondary">
        {label}:
      </Text>
      <Dropdown {...dropdownProps} menuAlignment="bottom">
        {items?.map((item, index) => (
          <DropdownItem
            // eslint-disable-next-line react/no-array-index-key
            key={`dropdown-item-${index}`}
            className={`${defaultClassName}__item`}
            title={item.primary}
            value={item.value}
          />
        ))}
      </Dropdown>
    </div>
  );
};

DropdownWrapper.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.oneOf(['default', 'form', 'standalone']),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      optionType: PropTypes.string,
    }),
  ).isRequired,
  selectedValue: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  message: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  onDropdownChange: PropTypes.func,
  performEvent: PropTypes.func.isRequired,
};

DropdownWrapper.defaultProps = {
  className: 'sc-dropdown',
  type: 'standalone',
  selectedValue: null,
  label: null,
  placeholder: null,
  message: null,
  multiple: false,
  disabled: false,
  onDropdownChange: null,
};

module.exports = DropdownWrapper;
