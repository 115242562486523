const React = require('react');
const PropTypes = require('prop-types');
const colorIcons = require('../common/color');

const Progress20 = ({ color, percentage }) => {
  // eslint-disable-next-line security/detect-object-injection
  const stroke = colorIcons[color];
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <circle cx="50%" cy="50%" r="8" fill="none" stroke={colorIcons.graySolid70} strokeWidth={4} />
      {percentage > 0 && (
        <circle
          transform="rotate(-90 10 10)"
          stroke={stroke}
          strokeDasharray={48.7}
          strokeDashoffset={48.7 - Math.min((48.7 * percentage) / 100, 48.7)}
          strokeWidth={4}
          strokeLinecap="round"
          cx="50%"
          cy="50%"
          r="8"
          fill="none"
        />
      )}
    </svg>
  );
};

Progress20.defaultProps = {
  percentage: 0,
  color: 'green500',
};

Progress20.propTypes = {
  percentage: PropTypes.number,
  color: PropTypes.string,
};

module.exports = Progress20;
