import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ExpandableRow from './ExpandableRow';
import SimpleRow from './SimpleRow';

const RowContentGuie = (props) => {
  const { brick, performEvent } = props;
  const [open, setOpen] = useState(null);

  return (
    <div className="content-row-guie">
      {brick?.bricks && brick?.bricks.map((item) => (
        // eslint-disable-next-line max-len, implicit-arrow-linebreak
        <>
          {item?.uiType === 'expandable_row' && (
            <ExpandableRow
              dataItem={item?.data}
              bricks={item?.bricks}
              performEvent={performEvent}
              expandableRow={open}
              setExpandableRow={setOpen}
              keyId={item?.id}
            />
          )}
          {item?.uiType === 'simple_row' && (
            <SimpleRow dataItem={item?.data} bricks={item?.bricks} performEvent={performEvent} />
          )}
        </>
      ))}
    </div>
  );
};

RowContentGuie.propTypes = {
  brick: PropTypes.shape({
    bricks: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  performEvent: PropTypes.func,
};

export default RowContentGuie;
