const { canExecuteMobileWebkit } = require('../../helpers/mobileWebkitMethodsV2');

export const nativeRedirect = ({ eventData }) => {
  if (canExecuteMobileWebkit()) {
    const { url, screenGroup, replace = false, transition = 'push', urlFallback } = eventData;
    const WEBKIT_2 = '&webkit-engine=2';
    window.MobileWebKit.navigation.canOpenDeeplink(`${url}${WEBKIT_2}`, { only_current_app: false })
      .then((canOpen) => {
        if (canOpen) {
          const options = {
            screen_group: screenGroup,
            replace,
            transition,
          };
          window.MobileWebKit.navigation.push(`${url}${WEBKIT_2}`, options).catch((error) => {
            // eslint-disable-next-line no-alert
            alert('nativeRedirect ERROR: ', error);
            window.MobileWebKit.navigation.openLinkInAppBrowser(urlFallback);
          });
        } else {
          console.log('CANT OPEN DEEPLINK: ', url);
        }
      })
      .catch((error) => {
        console.log('FAIL OPEN DEEPLINK: ', url, error);
      });
  }
};
