/* eslint-disable max-len */
const React = require('react');
const PropTypes = require('prop-types');

const Book20 = ({ color }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" width={21} height={21} viewBox="0 0 21 21">
    <path
      d="M15.572 13.223H5.653A1.65 1.65 0 0 0 4 14.871c0 .91.74 1.649 1.653 1.649h9.919"
      stroke={color}
      strokeWidth="1.725"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.572 13.223V2.509H6.48A2.476 2.476 0 0 0 4 4.98v9.89m10.744-1.647v3.297"
      stroke={color}
      strokeWidth="1.725"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Book20.defaultProps = {
  color: '#3483FA',
};

Book20.propTypes = {
  color: PropTypes.string,
};

module.exports = Book20;
