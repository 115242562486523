import React from 'react';
import PropTypes from 'prop-types';
import { Text, Title } from '@andes/typography';
import Image from 'nordic/image';
import Icon from '../Icon';

const ContentsModule = props => {
  const { title, subtitle, icon, contents, href, fallback } = props;

  const propsTitle = {
    component: href ? 'a' : 'p',
    color: href ? 'link' : 'primary',
    href,
  };

  if (title) {
    return (
      <section aria-labelledby="contents-module-title" className="contents-module">
        {!fallback && (
          <div className={`contents-module-description${(contents && '-extend') || ''}`}>
            <Title id="contents-module-title" className="contents-module-title" {...propsTitle}>
              {title}
            </Title>
            <div className={`contents-module-completed ${!icon && 'circle'}`}>
              {icon && <Icon containerClassName={['contents-module-completed-icon']} type={icon} color="grey" />}
              <Text className="contents-module-completed-text" component="p" size="l" color="secondary">
                {subtitle}
              </Text>
            </div>
          </div>
        )}
        {fallback && (
          <div className="contents-module-fallback">
            <Image
              className="fallback-image"
              src={fallback.image.href}
              alt="fallback image"
            />
            <Title
              className="fallback-title"
              size="xs"
            >
              {fallback.title}
            </Title>
            <Text>{fallback.subtitle}</Text>
          </div>
        )}
      </section>
    );
  }

  return null;
};

ContentsModule.propTypes = {
  title: PropTypes.string,
  totalContents: PropTypes.number,
  completedContents: PropTypes.number,
  icon: PropTypes.string,
  referrer: PropTypes.string,
  contents: PropTypes.arrayOf(PropTypes.shape({})),
  subtitle: PropTypes.string,
  href: PropTypes.string,
  fallback: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    image: PropTypes.shape({
      href: PropTypes.string,
    }),
  }),
};

export default ContentsModule;
