import React from 'react';
import PropTypes from 'prop-types';
import Script from 'nordic/script';
import MelidataTrack from 'nordic/melidata/melidata-track';
import { siteTitle, suScopePath } from '../helpers';
import { HOME_CONFIG_KEYS_FIRST_STEP_MODAL } from '../helpers/constants';
import { floxBrowserView } from '../flox/flox-browser-view';

const { ContextualCoachWrapper } = require('seller-journey-ui');
const { modes } = require('seller-journey-ui/src/constants');
const env = require('nordic/env');

const Home = (props) => {
  const { floxPreloadedState, auth, scopeProd, baseUrl, landing } = props;
  let { FloxView } = props;
  if (!FloxView) FloxView = floxBrowserView(floxPreloadedState);

  if (landing) {
    return (
      <>
        <Script on="now">{suScopePath}</Script>
        <MelidataTrack isDeferred="true" />
        <FloxView />
      </>
    );
  }

  return (
    <ContextualCoachWrapper
      mode={modes.FIRST_STEPS_MIX_MODE}
      flowKey={
        auth && auth.user
          ? HOME_CONFIG_KEYS_FIRST_STEP_MODAL[!scopeProd ? 'DEV' : 'PROD']
            .UNI_HOME_LOGGED
          : HOME_CONFIG_KEYS_FIRST_STEP_MODAL[!scopeProd ? 'DEV' : 'PROD']
            .UNI_HOME
      }
      referrer="desktop"
      clientSide
      baseURL={`${baseUrl}/api`}
      withAnimation={false}
      adjustToFooter
    >
      <Script on="now">{suScopePath}</Script>
      <MelidataTrack isDeferred="true" />
      <FloxView />
    </ContextualCoachWrapper>
  );
};

Home.propTypes = {
  FloxView: PropTypes.elementType,
  floxPreloadedState: PropTypes.shape({}),
  platform: PropTypes.shape({}),
  auth: PropTypes.shape({
    user: PropTypes.shape({}),
  }),
  landing: PropTypes.bool,
  baseUrl: PropTypes.string,
  device: PropTypes.shape({}),
  scopeProd: PropTypes.bool,
};

export const hydrate = 'hydrateRoot';

export default Home;

export const getServerSideProps = async (req, res) => {
  const { platform, auth, baseUrl } = req;
  const { floxPreloadedState, FloxView } = res.locals.state;
  const scopeProd = env.SCOPE === 'prod';

  return {
    props: {
      floxPreloadedState,
      FloxView,
      auth,
      scopeProd,
      baseUrl,
      landing: res.locals.landing || false,
    },
    settings: {
      title: siteTitle(platform.siteId),
    },
  };
};

export const setPageSettings = ({ settings }) => ({
  title: settings.title,
  className: 'sc-home',
});
