const React = require('react');
const { useState, useEffect } = require('react');
const PropTypes = require('prop-types');
const { get } = require('lodash');

const WebviewFilter = require('./index');
const CheckFilters = require('../lib/checkFilter');

class SubGroupFilterClass extends WebviewFilter {
  getInitialState() {
    const { subGroups } = this.props;

    const filters = subGroups.reduce((previusV, currentV) => previusV.concat(currentV.options), []);
    return filters.filter(option => option.checked).map(option => option.id);
  }

  getCurrentSelectionString(myCurrentSelection) {
    const { subGroups } = this.props;

    const selectionIds = myCurrentSelection || [];

    return subGroups
      .map(group => {
        const stringFilters = selectionIds
          .filter(Boolean)
          .map(selectionId => (group.options.find(option => option.id === selectionId)))
          .map(completeOption => completeOption?.label)
          .filter(Boolean)
          .join(', ');
        if (stringFilters) {
          return `${group.label}: ${stringFilters}`;
        }
        return '';
      })
      .filter(Boolean)
      .join('. ');
  }
}

const SubGroupFilterComponent = props => {
  const { subGroups, options, id, storageValue, myCurrentSelection, updateSelectionState } = props;

  const [optionsChecks, setOptionsChecks] = useState(options);

  const [subGroupsChecks, setSubGroupsChecks] = useState(subGroups);

  useEffect(() => {
    if (optionsChecks) {
      const cloneOptionsChecks = [...optionsChecks];
      const cloneSubGroupChecks = [...subGroupsChecks];
      cloneOptionsChecks.forEach(option => {
        const subgroup = cloneSubGroupChecks.find(sg => sg.id === option.id);
        if (subgroup) {
          option.master = true;
          if (subgroup.options.some(o => o.checked)) {
            option.checked = true;
          } else {
            option.checked = false;
            subgroup.options.forEach(o => { o.disabled = true; });
          }
        }
      });
      setOptionsChecks([...cloneOptionsChecks]);
      setSubGroupsChecks([...cloneSubGroupChecks]);
    }
  }, []);

  const updateStorageValue = ids => {
    const newValue = {
      ...get(storageValue, id),
      current: ids,
    };
    updateSelectionState(newValue);
  };


  const handleClickOption = (newValue, checkId) => {
    const cloneOptionsChecks = [...optionsChecks];
    const option = cloneOptionsChecks.find(o => o.id === checkId);
    if (option.master) {
      const cloneSubGroupChecks = [...subGroupsChecks];
      const subgroup = cloneSubGroupChecks.find(sg => sg.id === checkId);

      updateStorageValue(subgroup.options.map(o => {
        o.checked = !option.checked;
        o.disabled = option.checked;
        return !option.checked && o.id;
      }).filter(Boolean));

      setSubGroupsChecks([...cloneSubGroupChecks]);
    } else {
      updateSelectionState(newValue);
    }
    option.checked = !option.checked;
    setOptionsChecks([...optionsChecks]);
  };

  const handleClickSubGroupOption = (newValue, checkId) => {
    if (optionsChecks) {
      const cloneSubGroupChecks = [...subGroupsChecks];
      const subgroup = cloneSubGroupChecks.find(sg => sg.options.find(o => o.id === checkId));
      const cloneOptionsChecks = [...optionsChecks];

      const option = cloneOptionsChecks.find(o => o.id === subgroup.id);
      if (option.master) {
        const currentFilters = newValue.current;
        const subGroupIds = subgroup.options.map(sg => sg.id);
        if (!subGroupIds.some(r => currentFilters.includes(r))) {
          option.checked = false;
          subgroup.options.forEach(o => {
            o.disabled = true;
          });
          setOptionsChecks([...cloneOptionsChecks]);
          setSubGroupsChecks([...cloneSubGroupChecks]);
        }
      }
    }

    updateSelectionState(newValue);
  };

  return (
    <>
      { Boolean(options) && (
        <>
          {
            optionsChecks.map((option) => (
              <CheckFilters
                updateSelection={handleClickOption}
                myCurrentSelection={myCurrentSelection}
                storageValue={storageValue}
                idGroup={id}
                label={option.label}
                disabled={option.disabled}
                id={option.id}
                key={option.id}
                checked={option.checked}
              />
            ))
          }
          <div className="subgroup-group-separator" />
        </>
      )}
      {
        subGroupsChecks.map(g => (
          <div className="subgroup-container" key={g.label}>
            <div className="subgroup-title">{g.label}</div>
            <ul>
              {
                g.options.map((option) => (
                  <CheckFilters
                    updateSelection={handleClickSubGroupOption}
                    myCurrentSelection={myCurrentSelection}
                    storageValue={storageValue}
                    idGroup={id}
                    label={option.label}
                    disabled={option.disabled}
                    id={option.id}
                    key={option.id}
                  />
                ))
              }
            </ul>
            <div className="subgroup-separator" />
          </div>
        ))
      }
    </>
  );
};

SubGroupFilterComponent.propTypes = {
  subGroups: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()),
  id: PropTypes.string.isRequired,
  storageValue: PropTypes.shape({}).isRequired,
  myCurrentSelection: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateSelectionState: PropTypes.func.isRequired,
};

SubGroupFilterComponent.defaultProps = {
  options: null,
};

module.exports = { SubGroupFilterClass, SubGroupFilterComponent };
