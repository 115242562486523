/**
 * client-side restclient configuration object.
 *
 * NOTE: if this file is not loading properly client-side, please ensure that the
 * import path in your code matches the path in the package.json "browser" section
 *
 */

const config = (route = 'http://mercadolibre.com/university', timeout = 10000) => {
  const regex = /^((\/seller-journey)?\/university(\/preview)?|\/learning-center|)/i;
  const path = new URL(route).pathname.split(regex);
  const basePath = path.length === 1 ? '' : path[1];
  const baseURL = `${basePath}/api`;
  return {
    baseURL,
    timeout,
    headers: {
      newrelic: null,
    },
  };
};

module.exports = config;
