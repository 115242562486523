import React from 'react';
import PropTypes from 'prop-types';
import Button, { ButtonText } from '@andes/button';
import Icon from '../Icon';

const ALLOWED_EVENTS = ['login', 'sc_deeplink', 'back', 'sc_tracking'];

const CtaButton = (props) => {
  const {
    mainIcon,
    secondIcon,
    disabled,
    modifier,
    text,
    type,
    performEvent,
    action,
    events,
    results,
  } = props;

  const texts = results ? `${text} (${results})` : text;
  const activeHover = results ? 'result' : '';
  const classNameButton = `cta-button ${type} ${modifier} ${
    (mainIcon && 'cta-btn-main-icon') || ''
  } ${activeHover}`.trim();

  const mobileUrl = events?.find((event) => event?.type === 'redirect')?.data
    ?.url;

  const onClickEvent = () => {
    const newEvents = action?.type !== undefined ? [...events, action] : events;
    if (performEvent) {
      newEvents.forEach((event) => {
        if (ALLOWED_EVENTS.includes(event?.type)) {
          performEvent(event);
        }
      });
    }
  };

  return (
    <Button
      onClick={onClickEvent}
      href={action?.data?.url || mobileUrl}
      className={classNameButton}
      disabled={disabled || false}
    >
      {mainIcon && <Icon containerClassName={['main-icon']} type={mainIcon} />}
      <ButtonText component="p">{texts}</ButtonText>
      {secondIcon && (
        <Icon containerClassName={['second-icon']} type={secondIcon} />
      )}
    </Button>
  );
};

CtaButton.propTypes = {
  modifier: PropTypes.oneOf(['success']),
  text: PropTypes.string,
  mainIcon: PropTypes.string,
  secondIcon: PropTypes.string,
  type: PropTypes.oneOf(['outline', 'transparent']),
  performEvent: PropTypes.func,
  action: PropTypes.shape(),
  events: PropTypes.shape(),
  disabled: PropTypes.bool,
  results: PropTypes.bool,
};

CtaButton.defaultProps = {
  modifier: '',
  type: '',
  events: [],
  action: {},
};

export default CtaButton;
