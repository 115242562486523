/* eslint-disable consistent-return */
const supportsWebShareApi = () => typeof window !== 'undefined' && window.navigator && window.navigator.share;
const removeAccents = (stringStr) => {
  if (!stringStr) {
    return '';
  }
  const accents = {
    á: 'a',
    é: 'e',
    í: 'i',
    ó: 'o',
    ú: 'u',
    Á: 'A',
    É: 'E',
    Í: 'I',
    Ó: 'O',
    Ú: 'U',
  };
  return stringStr
    .split('')
    .map((letra) => accents[`${letra}`] || letra)
    .join('')
    .toString();
};

const waitForElm = (selector) => new Promise((resolve) => {
  if (document.querySelector(selector)) {
    return resolve(document.querySelector(selector));
  }

  const observer = new MutationObserver(() => {
    if (document.querySelector(selector)) {
      observer.disconnect();
      resolve(document.querySelector(selector));
    }
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });
});

const getExtensionURI = (url) => url.split('?')[0].split('.').pop();

module.exports = {
  supportsWebShareApi,
  removeAccents,
  getExtensionURI,
  waitForElm,
};
