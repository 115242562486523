const { getFloxState } = require('flox-web-client');
const { isEmpty } = require('lodash');

const configFloxState = (floxConfiguration) => {
  const { getState } = floxConfiguration.store;
  const state = getState();
  const floxState = getFloxState(state);
  const brickStack = { ...floxState.brickStack };
  return brickStack;
};

const findAndUpdateAllBricks = ({ brickArray, brickId, props }) => {
  const updatedBricks = [];
  brickArray.forEach((brick) => {
    let newBrick = { ...brick };

    if (newBrick.id === brickId) {
      newBrick.data = { ...brick.data, ...props.data };
      newBrick.bricks = props.bricks;
      newBrick.ui_type = props.ui_type;
    } else if (!isEmpty(brick.bricks)) {
      const recursiveUpdatedBrick = findAndUpdateAllBricks({
        brickArray: brick.bricks,
        brickId,
        props,
      });
      newBrick = { ...brick, bricks: recursiveUpdatedBrick.bricks };
    }
    updatedBricks.push({ ...newBrick });
  });
  return { bricks: updatedBricks };
};

export const updateCompleteBricks = ({
  eventData,
  floxConfiguration,
  performEvent,
}) => {
  const brickStack = configFloxState(floxConfiguration);
  let brickArray = Object.values(brickStack);
  eventData.bricks.forEach((brick) => {
    const { id: brickId, ...props } = brick;
    brickArray = findAndUpdateAllBricks({
      brickArray,
      brickId,
      props,
    }).bricks;
  });
  performEvent({
    type: 'register_and_render',
    data: {
      brick: brickArray[0],
    },
  });
};
