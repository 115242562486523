import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'nordic/image';
import { Text, Title } from '@andes/typography';
import { useSelector } from 'react-redux';
import { getContext } from '../../helpers/selectors';

const Fallback = props => {
  const { image, title, subtitle, button, className, performEvent } = props;
  const { fullUrl } = useSelector(getContext);

  const onClickEvent = () => {
    const newEvents = button.events || button.action;
    if (performEvent && newEvents) {
      if (Array.isArray(newEvents)) {
        newEvents.forEach(event => performEvent(event));
      } else {
        performEvent(newEvents);
      }
    }
  };

  const buttonChild = () => {
    if (button?.action) {
      return (
        <button className={`${className}-button`} onClick={onClickEvent}>
          {button?.text}
        </button>
      );
    }
    return (
      button !== undefined && (
        <Text className={`${className}-link`} component="a" color="link" href={fullUrl}>
          {button?.text}
        </Text>
      )
    );
  };

  return (
    <div className={className}>
      <Image className={`${className}-img`} src={image?.href} alt={image?.alt} />
      <Title className={`${className}-title`}>{title}</Title>
      {subtitle !== undefined && <Text className={`${className}-subtitle`}>{subtitle}</Text>}
      {buttonChild()}
    </div>
  );
};

Fallback.defaultProps = {
  className: 'sc-fallback',
};

Fallback.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  button: PropTypes.shape({
    text: PropTypes.string,
    action: PropTypes.shape(),
    events: PropTypes.shape(),
  }),
  image: PropTypes.shape({
    href: PropTypes.string,
    alt: PropTypes.string,
  }),
  className: PropTypes.string,
  performEvent: PropTypes.func,
};

export default Fallback;
