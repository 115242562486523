const isPathRegex = /^\/(?!\/)(.*?)/;
const isUrlRegex = /^https:\/\//;

export const checkSuScope = (value, serverSuScope) => {
  const scope = typeof window !== 'undefined' ? window.suScope : serverSuScope;
  let searchParams;
  if (isPathRegex.test(value)) {
    const [currPath, urlParams] = value.split('?');
    searchParams = new URLSearchParams(urlParams || '');
    if (scope) searchParams.set('suScope', scope);
    return `${currPath}${searchParams ? `?${searchParams.toString()}` : ''}`;
  }
  if (isUrlRegex.test(value)) {
    const url = new URL(value);
    if (scope) url.searchParams.set('suScope', scope);
    return url.toString();
  }
  return undefined;
};
