/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
const React = require('react');
const { useState, useEffect } = require('react');
const PropTypes = require('prop-types');
const { get, has } = require('lodash');
const Icon = require('../Icon');

const getFilterComponent = require('./class/getFilterComponent');
const { default: Fallback } = require('../Fallback');

const RowFilter = (props) => {
  const {
    className,
    label,
    type,
    initiallyExpanded,
    storageValue,
    onChangeStorageValue,
    id,
    fallback,
  } = props;

  const [filter, FilterComponent] = getFilterComponent(props);

  if (!filter || !FilterComponent) return null;

  const [myCurrentSelection, setMyCurrentSelection] = useState(() => filter.getInitialState());

  // calculate initial state
  useEffect(() => {
    setMyCurrentSelection(filter.getInitialState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // initialize shared state (client side)
  const initializedState = has(storageValue, id);
  if (!initializedState) {
    onChangeStorageValue({
      ...storageValue,
      [id]: {
        initial: filter.getInitialState(),
        current: filter.getInitialState(),
      },
      // IF row is initially expanded, save it to shared state
      ...(initiallyExpanded && { selected: id }),
    });
  }

  // get `open` value first by initiallyExpanded, and then by the value saved in shared state
  const isInitiallyExpanded = !initializedState && initiallyExpanded;
  const isCurrentlyExpanded = id === get(storageValue, 'selected');
  const open = isInitiallyExpanded || isCurrentlyExpanded;

  const onClickChevron = () => {
    onChangeStorageValue({
      ...storageValue,
      // update shared state to inform this row has been opened or closed
      selected: open ? null : id,
    });
  };

  // save state to storageValue
  const updateSelectionState = (newSelection) => {
    const newState = {
      ...storageValue,
      [id]: newSelection,
    };
    onChangeStorageValue(newState);
    setMyCurrentSelection(newSelection.current);
  };

  const currentSelectionString =
    filter.getCurrentSelectionString(myCurrentSelection);

  return (
    <>
      <div
        className={`${className} ${type} ${id} ${open ? 'open' : ''}`}
        onClick={onClickChevron}
        role="button"
      >
        <div className={`${className}__content`}>
          {label && (
            <span className={`${className}__label`}>
              {label}
              {!open && (
                <p className={`${className}__selected`}>
                  <span>{currentSelectionString}</span>
                </p>
              )}
            </span>
          )}
          <div className={`${className}__tag-chevron`}>
            <Icon
              className={`${className}__icon ${
                currentSelectionString ? 'activeBlue' : ''
              }`}
              type="ChevronDown20"
              activeTransition
              status={open}
            />
          </div>
        </div>
      </div>
      {open && (
        <div className={`${className}__options`}>
          {fallback && <Fallback {...fallback} />}
          <FilterComponent
            {...props}
            myCurrentSelection={myCurrentSelection}
            updateSelectionState={updateSelectionState}
          />
        </div>
      )}
    </>
  );
};

RowFilter.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  initiallyExpanded: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape()),
  subGroups: PropTypes.arrayOf(PropTypes.shape()),
  type: PropTypes.string,
  fallback: PropTypes.shape({}),
  storageValue: PropTypes.shape({}),
  onChangeStorageValue: PropTypes.func,
};

RowFilter.defaultProps = {
  className: 'sc-row-filter',
  label: null,
  initiallyExpanded: false,
  type: null,
  storageValue: null,
  onChangeStorageValue: null,
  subGroups: null,
};

module.exports = RowFilter;
