const MLM = require('./IconMLM');
const MCO = require('./IconMCO');
const MLB = require('./IconMLB');
const MLC = require('./IconMLC');

module.exports = {
  MLB,
  MLB_FULL: MLB,
  MCO,
  MCO_FULL: MCO,
  MLM,
  MLC,
  MLC_FULL: MLC,
  MLM_FULL: MLM,
};
