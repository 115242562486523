import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

const Tag = props => {
  const { text, type, modifier } = props;

  return (
    <div className={`tag ${type} ${modifier}`}>
      {modifier === 'success' && <Icon type="DoubleCheck" />}
      <p>{text}</p>
    </div>
  );
};

Tag.defaultProps = {
  type: 'solid',
  modifier: '',
};

Tag.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  modifier: PropTypes.string,
};

export default Tag;
