import { useRef, useState, useEffect } from 'react';

export const useScrollPercentage = () => {
  const scrollRef = useRef(null);
  const [scrollPercentage, setScrollPercentage] = useState(NaN);

  const watchScroll = (e) => {
    const intViewportHeight = window.innerHeight;
    const scrollHeight = window.pageYOffset;
    const pageHeight = document.body.clientHeight;
    const percentage = ((scrollHeight) / (pageHeight - intViewportHeight));
    const percentageRounded = Math.round(percentage * 100);
    if (percentage > 0 && percentage <= 100) {
      if (e !== undefined) {
        setScrollPercentage(percentageRounded);
      } else {
        setScrollPercentage(percentageRounded - (percentageRounded % 10));
      }
    }
  };

  useEffect(() => {
    const node = scrollRef.current;
    if (node !== null) {
      window.addEventListener('scroll', watchScroll, { passive: true });
    }
    return () => {
      if (node !== null) {
        window.removeEventListener('scroll', watchScroll, { passive: true });
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollRef]);

  useEffect(() => {
    watchScroll();
  }, []);

  return [scrollRef, Number.isNaN(scrollPercentage) ? 0 : scrollPercentage];
};
