/* global melidata */
const React = require('react');
const PropTypes = require('prop-types');

const { createContext } = React;
const Context = createContext({});

const createTrackingEvent = track => ({
  type: 'tracking',
  data: {},
  tracking: {
    tracks: [track],
  },
});

const TracksContext = props => {
  const { children, analyticsDimensions, performEvent } =
    props;

  const trackMelidata = (path, eventData, type = 'event') => {
    melidata('cleanAndSend', type, {
      path,
      event_data: eventData || {},
    });
  };

  const trackAnalytics = (action, label) => {
    const melidataEvent = {
      provider: 'google_analytics',
      data: {
        type: 'event',
        dimensions: analyticsDimensions,
        data: {
          category: 'SELLER_CENTRAL',
          action,
          label,
        },
      },
    };
    const trackingEvent = createTrackingEvent(melidataEvent);
    performEvent(trackingEvent);
  };

  const contextValue = { trackMelidata, trackAnalytics };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

TracksContext.propTypes = {
  children: PropTypes.node.isRequired,
  analyticsDimensions: PropTypes.shape({}).isRequired,
  melidataEventData: PropTypes.shape({}).isRequired,
  performEvent: PropTypes.func.isRequired,
};

module.exports = {
  Provider: TracksContext,
  Consumer: Context,
};
