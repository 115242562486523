import React, { useEffect, useRef, useState } from 'react';

import { Tab, Tabs } from '@andes/tabs';
import Spinner from '@andes/spinner';

import PropTypes from 'prop-types';
import TitleAndSubTitle from '../TitleAndSubTitle';
import IconWrapper from '../Icon';
import Fallback from '../Fallback';

const SectionContainer = props => {
  const {
    title,
    subTitle,
    icon,
    color,
    children,
    defaultClassName,
    className,
    categories,
    performEvent,
    textAlign,
    fallback,
  } = props;

  const [loading, setLoading] = useState(false);
  const firstLoad = useRef(true);
  const [cardsByCategory, setCardsByCategory] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(categories ? categories[0]?.categoryId : null);

  const onCLickEvents = (item) => {
    if (performEvent && item.events) {
      item.events.forEach((e) => {
        if (e.type === 'request') {
          setCurrentCategory(item.categoryId);
        }
        if (cardsByCategory[`${item.categoryId}`] === undefined) {
          setLoading(true);
          e.data.categoryId = item.categoryId;
          performEvent(e);
        }
      });
    }
  };

  useEffect(() => {
    if (categories) {
      setCardsByCategory(prev => ({
        ...prev,
        [currentCategory]: children,
      }));
      if (firstLoad.current) {
        setLoading(true);
        categories[0]?.events?.forEach((event) => {
          event.data.categoryId = categories[0].categoryId;
          performEvent(event);
        });
      } else {
        setLoading(false);
      }
    }
    return () => {
      firstLoad.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  const childrenComponets = () => {
    if (fallback) return <Fallback {...fallback} className={`${defaultClassName}-fallback`} />;
    if (loading) return <Spinner show modifier="inline" size="small" />;
    if (!cardsByCategory && children) return <div className={`${defaultClassName}-cards`}>{children}</div>;
    if (cardsByCategory === null) return <div className={`${defaultClassName}-cards`} />;
    return <div className={`${defaultClassName}-cards`}>{cardsByCategory[`${currentCategory}`]}</div>;
  };

  return (
    <section className={`${defaultClassName} ${className}`}>
      <div className={`${defaultClassName}-wrapper`}>
        <div className={`${defaultClassName}-content ${textAlign}`}>
          {icon && <IconWrapper containerClassName={[`${defaultClassName}-icon`]} type={icon} color={color} />}
          <TitleAndSubTitle className={defaultClassName} title={title} subTitle={subTitle} />
          {categories && (
            <div className={`${defaultClassName}-categories`}>
              <Tabs srLabel="Categories">
                {categories.map((item) => (
                  <Tab key={item.label} title={item.label} id={item.label} onClick={() => onCLickEvents(item)} />
                ))}
              </Tabs>
            </div>
          )}
        </div>
        {childrenComponets()}
      </div>
    </section>
  );
};

SectionContainer.propTypes = {
  defaultClassName: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  textAlign: PropTypes.string,
  className: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.shape()),
  performEvent: PropTypes.func,
  fallback: PropTypes.shape(),
  children: PropTypes.oneOfType([PropTypes.arrayOf(), PropTypes.node]),
};

SectionContainer.defaultProps = {
  defaultClassName: 'sc-section-container',
  className: '',
  textAlign: '',
  fallback: undefined,
};

export default SectionContainer;
