export function mapBrick(general, ids) {
  const currentId = ids[0] || null;
  const idsMap = ids;
  let foundBrick = null;
  if (general.bricks) {
    general.bricks.forEach(element => {
      if (element.id === currentId) {
        idsMap.shift();
        foundBrick = element;
      }
    });
  }
  if (idsMap.length > 0 && foundBrick) {
    return mapBrick(foundBrick, idsMap);
  }
  return foundBrick;
}

