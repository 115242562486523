import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@andes/typography';
import Image from 'nordic/image';
import Button from '../CtaButton';

const PushLoginRec = (props) => {
  const { title, image, button, performEvent } = props;

  return (
    <div className="push-login-rec">
      <Image
        className="push-login-rec-img"
        src={image?.href}
        alt={image?.alt}
      />
      <Text
        id="push-login-rec-text"
        className="push-login-rec-text"
        component="p"
        size="m"
        color="primary"
      >
        {title}
      </Text>
      <Button
        {...button}
        // TEMPORAL EVENT DEFINITION
        events={
          button?.events
            ? button.events
            : [
              {
                type: 'login',
              },
            ]
        }
        performEvent={performEvent}
        className="push-login-rec-btn"
        hierarchy="loud"
        size="large"
      />
    </div>
  );
};

PushLoginRec.propTypes = {
  title: PropTypes.string,
  image: PropTypes.shape({
    href: PropTypes.string,
    alt: PropTypes.string,
  }),
  performEvent: PropTypes.func,
  button: PropTypes.shape({
    text: PropTypes.string,
  }),
};

export default PushLoginRec;
