/* eslint-disable max-len */
const React = require('react');

const IconMCO = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_394_34441)">
      <path
        d="M16 7.99976H1C1 9.37476 1.375 10.6498 2 11.7498H15C15.65 10.6498 16 9.37476 16 7.99976Z"
        fill="#2A5F9E"
      />
      <path
        d="M8.5 0.499756C4.375 0.499756 1 3.84976 1 7.99976H16C16 3.84976 12.65 0.499756 8.5 0.499756Z"
        fill="#FFE62E"
      />
      <path
        d="M8.5 15.4998C11.275 15.4998 13.7 13.9998 15 11.7498H2C3.325 13.9998 5.725 15.4998 8.5 15.4998Z"
        fill="#ED4C5C"
      />
    </g>
    <defs>
      <clipPath id="clip0_394_34441">
        <rect width="16" height="16" fill="white" transform="translate(0.5 -0.000244141)" />
      </clipPath>
    </defs>
  </svg>
);

module.exports = IconMCO;
