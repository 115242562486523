/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useScrollBottom } from '../../hooks/useScrollBottom';
import Fallback from '../../components/Fallback';
import Loading from '../../components/Loading';
import { getParams, useQueryParams } from '../../hooks/useQueryParams';
import { NAME_CONFIG_KEYS } from '../../helpers/constants';

const SearchContentCards = props => {
  const { className, brick, children, performEvent, fallback, total } = props;
  const [scrollBottomRef, scrollBottomState] = useScrollBottom();
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCards, setTotalCards] = useState(Math.floor(total / 18));
  const { updateQuery } = useQueryParams();

  useEffect(() => {
    if (performEvent && offset > 0) {
      updateQuery({ key: 'offset', value: offset });
      updateQuery({ key: 'limit', value: 18 });
      const requestBrick = [
        {
          type: 'request',
          data: {
            key: NAME_CONFIG_KEYS.SEARCH_CARD,
            arguments: getParams(),
          },
        },
      ];
      requestBrick.forEach(event => performEvent(event));
    }
    if (offset > offset + 18 || totalCards === 0) {
      setIsLoading(false);
    }
  }, [offset]);

  useEffect(() => {
    if (brick?.bricks.length > 1 && scrollBottomState && totalCards !== 0) {
      let aux = totalCards;
      setTotalCards((aux -= 1));
      setOffset(offset + 18);
      setIsLoading(true);
    }
  }, [scrollBottomState]);

  if (fallback) {
    return (
      <Fallback {...fallback} className={fallback?.className ? `${fallback?.className}` : `${className}-fallback`} />
    );
  }

  return (
    <div ref={scrollBottomRef} className={`sc-${className}`}>
      {children}
      <Loading size="medium" modifier="inline" loadingStatus={isLoading} />
    </div>
  );
};

SearchContentCards.defaultProps = {
  className: 'search-content-cards',
};

SearchContentCards.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  performEvent: PropTypes.func,
  fallback: PropTypes.shape(),
  brick: PropTypes.shape({
    bricks: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  total: PropTypes.number,
};

export default SearchContentCards;
