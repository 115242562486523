/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
const React = require('react');
const PropTypes = require('prop-types');

const ShapeIcon = ({ color }) => (
  <svg width="16" height="23" viewBox="0 0 16 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.619531 2.99996C0.619555 1.75733 1.62691 0.749998 2.86953 0.75L13.149 0.750013C14.3916 0.750014 15.399 1.75738 15.399 3.00003L15.3988 22.8258L7.98453 15.3241L0.619141 22.8363L0.619531 2.99996ZM2.86953 2.25C2.45532 2.25 2.11954 2.58578 2.11953 2.99999L2.11921 19.1637L7.98024 13.1859L13.8988 19.1742L13.899 3.00002C13.899 2.5858 13.5632 2.25001 13.149 2.25001L2.86953 2.25Z" fill={color} fillOpacity="0.9" />
  </svg>
);

ShapeIcon.defaultProps = {
  color: '#3483FA',
};

ShapeIcon.propTypes = {
  color: PropTypes.string,
};

module.exports = ShapeIcon;
