/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
const React = require('react');
const PropTypes = require('prop-types');

const ReadGuide = ({ color }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="white" />
    <path
      d="M10.2031 27.2666H15.0031C18.2031 27.2666 18.7365 28.3333 19.8031 29.3999"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.8031 29.3998V17.6665C19.8031 14.7209 17.4153 12.3331 14.4698 12.3331H10.2031V26.9617"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.8047 29.3998V17.6665C19.8047 14.7209 22.1925 12.3331 25.138 12.3331H29.4047V26.9617"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.3969 27.2666H24.5969C21.3969 27.2666 20.8635 28.3333 19.7969 29.3999"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

ReadGuide.defaultProps = {
  color: '#3483FA',
};

ReadGuide.propTypes = {
  color: PropTypes.string,
};

module.exports = ReadGuide;
