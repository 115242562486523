const React = require('react');
const PropTypes = require('prop-types');
const WebviewFilter = require('./index');
const RadioButtonFilters = require('../lib/radioButtonFilter');

class RadioFilterClass extends WebviewFilter {
  getInitialState() {
    const { options } = this.props;
    return options.find(option => option.checked)?.id;
  }

  getCurrentSelectionString(myCurrentSelection) {
    const { options } = this.props;

    const selectionIds = [myCurrentSelection] || [];

    return selectionIds
      .filter(Boolean)
      .map(selectionId => (options.find(option => option.id === selectionId)))
      .map(completeOption => completeOption.label)
      .join(', ');
  }
}

const RadioFilterComponent = props => {
  const { options, id, storageValue, myCurrentSelection, updateSelectionState } = props;

  return options.map((option) => (
    <RadioButtonFilters
      updateSelection={updateSelectionState}
      myCurrentSelection={myCurrentSelection}
      storageValue={storageValue}
      idGroup={id}
      label={option.label}
      value={option.id}
      id={option.id}
      name={option.id}
      key={option.id}
      inverted
    />
  ));
};

RadioFilterComponent.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  id: PropTypes.string.isRequired,
  storageValue: PropTypes.shape({}).isRequired,
  myCurrentSelection: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateSelectionState: PropTypes.func.isRequired,
};


module.exports = { RadioFilterClass, RadioFilterComponent };
