export const CONFIG_KEYS = {
  HOME_CATEGORIES: { action: '/section-categories', method: 'GET' },
  GUIDES_SUB_ROWS: { action: '/guide/subrows', method: 'POST' },
  SEARCH_CARD: { action: '/search/search-cards', method: 'GET' },
};

export const NAME_CONFIG_KEYS = {
  HOME_CATEGORIES: 'HOME_CATEGORIES',
  GUIDES_SUB_ROWS: 'GUIDES_SUB_ROWS',
  SEARCH_CARD: 'SEARCH_CARD',
};

export const HOME_CONFIG_KEYS_FIRST_STEP_MODAL = {
  DEV: {
    UNI_HOME_LOGGED: 'uni_home_logged_in_test',
    UNI_HOME: 'uni_home_test',
  },
  PROD: {
    UNI_HOME_LOGGED: 'uni_home_logged_in',
    UNI_HOME: 'uni_home_not_logged_in',
  },
};

export const NOTE_CONFIG_KEYS_FIRST_STEP_MODAL = {
  DEV: {
    UNI_CONTENT_LOGGED: 'uni_content_logged_in',
    UNI_CONTENT: 'uni_content_no_logged_in',
  },
  PROD: {
    UNI_CONTENT_LOGGED: 'uni_content_logged_in',
    UNI_CONTENT: 'uni_content_not_logged_in',
  },
};

export const GUIDE_CONFIG_KEYS_FIRST_STEP_MODAL = {
  PROD: {
    FIRST_VIEW_SU_USER_LOGGED_IN: 'first_view_su_user_logged_in',
    FIRST_VIEW_SU_USER_LOGGED_OUT: 'first_view_su_user_logged_in',
  },
  CBT: {
    FIRST_VIEW_SU_USER_LOGGED_IN: 'first_view_su_user_cbt_logged_in',
    FIRST_VIEW_SU_USER_LOGGED_OUT: 'first_view_su_cbt_user_logged_out',
  },
};
