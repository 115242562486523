import React from 'react';
import PropTypes from 'prop-types';
import { Text, Title } from '@andes/typography';
import { MlIcon } from '../SuTitle/lib';

const Landing = (props) => {
  const { title, sites } = props;

  return (
    <div className="landing-wrapper">
      <MlIcon />
      <Title size="m" component="h1" className="landing-title">
        {title}
      </Title>
      <nav>
        <ul className="landing-country-list">
          {sites &&
            sites.length > 0 &&
            sites.map((site) => (
              <li key={site.id} className={`landing-country item-${site.id}`}>
                <Text component="a" id={site.id} href={site.href}>
                  {site.name}
                </Text>
              </li>
            ))}
        </ul>
      </nav>
    </div>
  );
};

Landing.propTypes = {
  title: PropTypes.string,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
};

export default Landing;
