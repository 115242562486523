import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TracksContext from '../../context/tracks-context';
import { getContext, getSearchData, getTracks } from '../../helpers/selectors';

const Header = (props) => {
  const { children, type } = props;
  const { trackMelidata } = useContext(TracksContext.Consumer);
  const { searchStatus } = useSelector(getSearchData);
  const tracks = useSelector(getTracks);
  const { isMobile } = useSelector(getContext);
  const trackData = tracks?.TYPIFICATION?.data;

  const classNameDefault = `header-container${type ? `-${type}` : ''} ${
    searchStatus ? 'sc-search-focus' : ''
  }`.trim();

  if (tracks?.TYPIFICATION !== undefined) {
    trackMelidata(trackData.path, trackData.eventData, trackData.type);
  }

  // old-header
  if (type !== 'new') {
    return (
      <header className="header">
        <div className="header-container">{children}</div>
      </header>
    );
  }

  return (
    <header className={`header${type ? `-${type}` : ''} ${isMobile ? 'header-mobile' : ''}`.trim()}>
      <div className={classNameDefault}>{children}</div>
    </header>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
};

export default Header;
