import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@andes/typography';

const NoteSubtitle = props => {
  const { text, performEvent } = props;

  performEvent({
    type: 'update_storage',
    data: {
      mode: 'merge',
      storage_data: {
        subtitle: text,
      },
    },
  });

  return (
    <Text className="note-subtitle" component="p" size="l">
      {text}
    </Text>
  );
};

NoteSubtitle.propTypes = {
  text: PropTypes.string,
  performEvent: PropTypes.func,
};

export default NoteSubtitle;
