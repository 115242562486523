import { handlerLogin } from '../../helpers/actionsAuth';
import { getContext } from '../../helpers/selectors';

export const login = () => {
  let context = {};
  if (window.__PRELOADED_STATE__ instanceof HTMLElement) {
    const contextJson = JSON.parse(window.__PRELOADED_STATE__.innerText);
    context = getContext(contextJson.pageState.floxPreloadedState);
  } else {
    context = getContext(window.__PRELOADED_STATE__.pageState.floxPreloadedState);
  }
  const { platform, fullUrl = '' } = context;
  window.location.href = handlerLogin(platform.siteId, fullUrl);
};
