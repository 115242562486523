import { createBrowserRenderer } from 'flox-web-client';
import bricksMap from './bricks-map';
import customEventPerformers from './custom-event-performer';
/** NEW RELIC */
require('../helpers/handleGlobalError');

export const floxBrowserView = (floxPreloadedState, clientEvents) => {
  const floxBrowserConfiguration = {
    jsonType: 'camel_case',
    preloadedState: floxPreloadedState,
    bricksMap,
    customEventPerformers,
  };

  const floxBrowserRenderer = createBrowserRenderer(floxBrowserConfiguration);
  if (clientEvents) floxBrowserRenderer.performEvent(clientEvents);
  return floxBrowserRenderer.getFloxView();
};
