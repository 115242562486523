/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TitleAndSubTitle from '../TitleAndSubTitle';
import Icon from '../Icon';
import Fallback from '../Fallback';
import { getContext } from '../../helpers/selectors';
import { checkSuScope } from '../../helpers/checkSuScope';
import GlobalTooltip from '../GlobalTooltip';

const { Image } = require('nordic/image');
const CardAndes = require('@andes/card');
const { CardContent } = require('@andes/card');

const Card = (props) => {
  const {
    title,
    description,
    events,
    action,
    image,
    className,
    iconVideo,
    isCardRead,
    modifier,
    children,
    fallback,
    type,
    performEvent,
    globalTooltip,
  } = props;
  const iconClass = iconVideo ? 'icon-video' : '';
  const newEvents = events || action;
  const { device, suScope } = useSelector(getContext);

  if (fallback) {
    return (
      <CardAndes shadow="elevated" className={className}>
        <CardContent className={`${className}-container`}>
          <Fallback {...fallback} className={`${className}-fallback`} />
        </CardContent>
      </CardAndes>
    );
  }

  const onClickEvent = () => {
    if (performEvent && newEvents) {
      newEvents.forEach((event) => performEvent(event));
    }
  };

  const determinateCustomClass = () => {
    if (type === 'guide') {
      return '-crop-secondary';
    }
    if (type === 'module') {
      return '-module';
    }
    return '';
  };

  const CardComponent = (
    <CardAndes
      shadow="elevated"
      className={`${className} ${modifier}`}
      onClick={onClickEvent}
    >
      {isCardRead && (
        <div className="sc-bg-read-guie">
          <Icon type="ReadGuide" />
        </div>
      )}
      {globalTooltip && <GlobalTooltip {...globalTooltip} />}
      <CardContent className={`${className}-container`}>
        {image && (
          <div className={`${className}-image-container`}>
            <Image
              className={`${className}-image${determinateCustomClass()} ${iconClass}`}
              onload="on"
              alt={image.alt}
              src={image.href}
            />
            {iconVideo && <Icon type="CardVideo" />}
          </div>
        )}
        <div className={`${className}-title-subtitle-container`}>
          <TitleAndSubTitle
            className={`${className}-title-subtitle`}
            title={title}
            subTitle={description}
            subTitleSize="xs"
            subTitleColor="secondary"
          />
        </div>
        {children && <div className={`${className}-info`}>{children}</div>}
      </CardContent>
    </CardAndes>
  );

  return device?.webView ? (
    CardComponent
  ) : (
    <a
      href={checkSuScope(newEvents[0]?.data?.url, suScope)}
      className="su-full-card"
    >
      {CardComponent}
    </a>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  modifier: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.shape({
    href: PropTypes.string,
    alt: PropTypes.string,
  }),
  iconVideo: PropTypes.bool,
  isCardRead: PropTypes.bool,
  action: PropTypes.shape(),
  link: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(), PropTypes.node]),
  events: PropTypes.shape(),
  fallback: PropTypes.shape(),
  performEvent: PropTypes.func,
  globalTooltip: PropTypes.shape({
    className: PropTypes.string,
    title: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

Card.defaultProps = {
  className: 'sc-card-content',
  modifier: '',
  fallback: undefined,
  action: [],
};

export default Card;
