/* eslint-disable max-len */
const React = require('react');

const CardVideo = () => (
  <svg width="73" height="70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#a)">
      <g filter="url(#b)">
        <circle cx="36.5" cy="30.5" r="20.5" fill="#fff" fillOpacity=".5" />
      </g>
      <path
        d="M30 37.48c.005.273.08.54.22.77.139.231.336.418.57.54a1.501 1.501 0 0 0 1.527 0l10.836-7.007c.236-.118.435-.304.574-.536a1.503 1.503 0 0 0 0-1.546 1.413 1.413 0 0 0-.574-.537L32.317 22.21a1.501 1.501 0 0 0-1.528 0c-.233.122-.43.309-.57.54-.139.23-.215.497-.219.77v13.96Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth="2.04"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter id="a" x="0" y="0" width="73" height="73" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="6" />
        <feGaussianBlur stdDeviation="8" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1288_36413" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_1288_36413" result="shape" />
      </filter>
      <filter
        id="b"
        x="12"
        y="6"
        width="49"
        height="49"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1288_36413" />
        <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_1288_36413" result="shape" />
      </filter>
    </defs>
  </svg>
);

module.exports = CardVideo;
