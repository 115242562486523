const React = require('react');
const PropTypes = require('prop-types');
const { get, xor } = require('lodash');
const Checkbox = require('@andes/checkbox');

const CheckFilters = props => {
  const {
    className,
    label,
    disabled,
    idGroup,
    id,
    storageValue,
    updateSelection,
    checked,
  } = props;

  const currentValue = get(storageValue, idGroup)?.current || [];
  const selected = currentValue.includes(id);

  const handleOptionClick = () => {
    const newSelectionList = xor(currentValue, [id]);
    const newValue = {
      ...get(storageValue, idGroup),
      current: newSelectionList,
    };
    updateSelection(newValue, id);
  };

  const getLabel = () => (
    <span>
      {label}
    </span>
  );


  return (
    <li className={className}>
      <div className={`${className}__icon-box`}>
        <Checkbox
          label={getLabel()}
          labelPosition="left"
          checked={selected || checked}
          disabled={disabled}
          onChange={handleOptionClick}
        />
      </div>
    </li>
  );
};

CheckFilters.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  storageValue: PropTypes.shape({}),
  updateSelection: PropTypes.func,
  idGroup: PropTypes.string,
  checked: PropTypes.bool,
};

CheckFilters.defaultProps = {
  className: 'sc-check-filter',
  label: null,
  id: null,
  disabled: false,
  storageValue: null,
  updateSelection: null,
  idGroup: null,
  checked: false,
};

module.exports = CheckFilters;
