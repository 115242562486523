import React from 'react';
import PropTypes from 'prop-types';
import TitleAndSubTitle from '../TitleAndSubTitle';

const HomeHeader = props => {
  const { title, subTitle, children, defaultClassName, className } = props;

  return (
    <div className={`${defaultClassName} ${className}`}>
      <div className={`${defaultClassName}-container`}>
        <div className={`${defaultClassName}-content`}>
          <TitleAndSubTitle className="home" title={title} subTitle={subTitle} />
        </div>
        {children}
      </div>
    </div>
  );
};

HomeHeader.propTypes = {
  defaultClassName: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(),
    PropTypes.node,
  ]),
};
HomeHeader.defaultProps = {
  defaultClassName: 'sc-home-header',
  className: '',
};

export default HomeHeader;
