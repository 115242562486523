import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RestClient } from 'nordic/restclient';
import { useSelector } from 'react-redux';
import { Image } from 'nordic/image';
import YoutubeVideo from '../YoutubeVideo';
import config from '../../../config/restclient/browser';
import { useTracks } from '../../hooks/useTracks';
import tracksContext from '../../context/tracks-context';
import { getContext, getNoteData } from '../../helpers/selectors';
import { useStateRef } from '../../hooks/useStateRef';


const CLASS_BY_TYPE = {
  VIDEO: 'main',
  IMAGE: 'secondary',
};

const CLASS_BY_NAME = {
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
};

const Banner = props => {
  const { type, source } = props;
  const [classType, setClassType] = useState(CLASS_BY_TYPE.IMAGE);
  const { advance, maxAdvance, parentNoteId, parentGuides, parentModules } = useSelector(getNoteData);
  const { auth, fullUrl } = useSelector(getContext);
  const { trackDataFromStorage } = useTracks(tracksContext.Consumer);
  const request = RestClient(config(fullUrl));
  const [advanceVideo, setAdvance, advanceVideoRef] = useStateRef(advance || 0);
  const [isRead, setIsRead, isReadRef] = useStateRef(false);

  const onStateChangeVideo = percentage => {
    if (advanceVideo < percentage && percentage <= 100) {
      setAdvance(percentage);
      if (auth?.user?.id && parentNoteId) {
        request
          .post('/note/advance', {
            data: {
              noteId: parentNoteId,
              percentage,
              parentGuides,
              parentModules,
            },
          });
      }

      if (!isRead && percentage >= maxAdvance) {
        setIsRead(true);
      }
    }
  };

  useEffect(() => {
    setClassType(CLASS_BY_TYPE[`${type}`] || 'secondary');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const sendTracks = () => {
    trackDataFromStorage('ADVANCE', {
      entity_advance: advanceVideoRef.current,
      type: CLASS_BY_NAME.VIDEO,
    });
    trackDataFromStorage('COMPLETED', { is_completed: isReadRef.current, type: CLASS_BY_NAME.VIDEO });
  };

  useEffect(() => {
    if (type === CLASS_BY_NAME.VIDEO) {
      window.addEventListener('beforeunload', sendTracks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getElementByType = () => {
    if (type === CLASS_BY_NAME.VIDEO) {
      return <YoutubeVideo src={source} intervalPercentage={10} onStateChange={onStateChangeVideo} />;
    }

    return (
      <Image className="presentation-image" alt="" src={source} />
    );
  };

  return <div className={`presentation-${classType}-container`}>{getElementByType()}</div>;
};

Banner.propTypes = {
  source: PropTypes.string,
  type: PropTypes.string,
};

export default Banner;
