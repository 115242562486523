import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

const FeedBackMessageView = props => {
  const { title, message } = props;
  return (
    <div className="container-message-feedback">
      <Icon type="SuccessChecked" />
      <h3>{title}</h3>
      <p>{message}</p>
    </div>
  );
};

FeedBackMessageView.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
};

export default FeedBackMessageView;
