import { LoggerFactory } from 'nordic/logger';
import { RestClient } from 'nordic/restclient';
import configRestclient from '../../../config/restclient/browser';
import { CONFIG_KEYS } from '../../helpers/constants';
import { getContext } from '../../helpers/selectors';
import { checkSuScope } from '../../helpers/checkSuScope';

const { replaceKeysInObj } = require('../utils/helpers');

const log = LoggerFactory('evetRequest');

export const request = (floxProps) => {
  let context = {};
  if (window.__PRELOADED_STATE__ instanceof HTMLElement) {
    context = JSON.parse(window.__PRELOADED_STATE__.innerText);
    context = context?.pageState?.floxPreloadedState;
  } else if (window?.__PRELOADED_STATE__?.pageState?.floxPreloadedState) {
    context = window?.__PRELOADED_STATE__?.pageState?.floxPreloadedState;
  } else if (document?.scripts) {
    const contextDoc = document?.querySelector('#__PRELOADED_STATE__');
    context = JSON.parse(contextDoc?.innerText);
  }

  const fullUrl = getContext(context)?.fullUrl;
  const HOST_PREVIEW = getContext(context)?.previewHost;
  const newRequest = RestClient(configRestclient(fullUrl));

  const { eventData, performEvent } = floxProps;
  const isValidPath = CONFIG_KEYS[eventData.key];
  if (isValidPath) {
    const currUrl = fullUrl && new URL(fullUrl);
    const lowerCasePath = isValidPath.method.toLowerCase();
    let path = isValidPath.action;
    if (currUrl && currUrl.hostname === HOST_PREVIEW) {
      path = `${isValidPath.action}?${currUrl.searchParams.toString()}`;
    }
    newRequest[`${lowerCasePath}`](checkSuScope(path), {
      [lowerCasePath === 'get' ? 'params' : 'data']: eventData,
    })
      .then((resp) => {
        let response = replaceKeysInObj('storageKey', 'storage_key')(resp.data);
        response = replaceKeysInObj('storageData', 'storage_data')(response);
        performEvent(response);
      })
      .catch((error) => {
        log.error({ path, eventData, error });

        if (eventData?.fallback?.events?.length) {
          const fallbackEvents = eventData.fallback.events;
          fallbackEvents.forEach((event) => performEvent(event));
        }
        if (
          typeof window !== 'undefined' &&
          typeof window.newrelic !== 'undefined'
        ) {
          window.newrelic.setErrorHandler(() => true);
        }
      });
  }
};
