const React = require('react');
const PropTypes = require('prop-types');
const { getParams, useQueryParams } = require('../../hooks/useQueryParams');
const { NAME_CONFIG_KEYS } = require('../../helpers/constants');
const Component = require('../../components/Pagination');


const PaginationContainer = props => {
  const { performEvent, limit } = props;
  const { updateQuery } = useQueryParams();
  const onChangeHandler = (e, newPage) => {
    e.preventDefault();
    const eventData = {
      page: newPage,
      offset: (newPage - 1) * limit,
    };
    updateQuery([
      { key: 'page', value: eventData.page },
      { key: 'offset', value: eventData.offset },
    ]);

    if (performEvent) {
      const requestBrick = [
        {
          type: 'request',
          data: {
            key: NAME_CONFIG_KEYS.SEARCH_CARD,
            arguments: getParams(),
          },
        },
      ];
      requestBrick.forEach(event => performEvent(event));
    }
  };
  return <Component {...props} onChange={onChangeHandler} />;
};

PaginationContainer.propTypes = {
  performEvent: PropTypes.func.isRequired,
  onChangeStorageValue: PropTypes.func.isRequired,
  events: PropTypes.shape(),
  limit: PropTypes.number,
};

module.exports = PaginationContainer;
