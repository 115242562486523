import React from 'react';
import PropTypes from 'prop-types';
import TitleAndSubTitle from '../TitleAndSubTitle';
import Icon from '../Icon';

const CardIcon = (props) => {
  const { label, className, icon, color } = props;

  return (
    <div className={className}>
      {icon && <Icon containerClassName={[icon]} type={icon} color={color} />}
      <TitleAndSubTitle
        className={`${className}-label`}
        title={label}
        subTitleSize="xs"
        subTitleColor="secondary"
      />
    </div>
  );
};

CardIcon.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
};
CardIcon.defaultProps = {
  className: 'sc-card-icon',
};

export default CardIcon;
