/* eslint-disable max-len */
const React = require('react');
const PropTypes = require('prop-types');

const Growing = ({ color }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1288_36359)">
      <path
        d="M20.1423 7.80054H28.3709V16.0291"
        stroke={color}
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.3696 7.80054L16.7467 19.4234C16.5544 19.6119 16.2959 19.7174 16.0267 19.7174C15.7575 19.7174 15.499 19.6119 15.3067 19.4234L10.5753 14.692C10.383 14.5035 10.1245 14.3979 9.85528 14.3979C9.58605 14.3979 9.32755 14.5035 9.13528 14.692L1.62671 22.2005"
        stroke="#3483FA"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1288_36359">
        <rect
          width="28.8"
          height="28.8"
          fill="white"
          transform="translate(0.600098 0.599976)"
        />
      </clipPath>
    </defs>
  </svg>
);

Growing.defaultProps = {
  color: '#3483FA',
};

Growing.propTypes = {
  color: PropTypes.string,
};

module.exports = Growing;
