/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */

const React = require('react');
const PropTypes = require('prop-types');
const Pagination = require('@andes/pagination');
const { useSelector } = require('react-redux');
const { getContext } = require('../../helpers/selectors');
const { handlerRedirect } = require('../../helpers/searchRedirect');

const PaginationWrapper = props => {
  const { prevLabel, nextLabel, total, limit, offset, onChange } = props;
  const { platform, suScope } = useSelector(getContext);
  const pageSelected = Math.ceil(offset / limit) + 1;
  const actualPath = new URL(window.location.href);
  const querySearch = actualPath.searchParams.get('q');

  const paginationProps = {
    prevLabel,
    nextLabel,
    pageQuantity: Math.ceil(total / limit),
    pageSelected,
    onChange: (e, paginator) => onChange(e, paginator),
    href: handlerRedirect(suScope, platform.siteId, '', querySearch, '', true),
  };
  return <Pagination {...paginationProps} />;
};

PaginationWrapper.propTypes = {
  prevLabel: PropTypes.string.isRequired,
  nextLabel: PropTypes.string.isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

module.exports = PaginationWrapper;
