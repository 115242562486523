
/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
const React = require('react');
const PropTypes = require('prop-types');

const BookMarkIsChecked = ({ color }) => (
  <svg width="16" height="23" viewBox="0 0 16 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.618554 2.99996C0.618578 1.75733 1.62593 0.749998 2.86856 0.75L13.148 0.750013C14.3906 0.750014 15.398 1.75738 15.398 3.00003L15.3978 22.8258L7.98355 15.3241L0.618164 22.8363L0.618554 2.99996Z" fill={color} fillOpacity="0.9" />
  </svg>
);

BookMarkIsChecked.defaultProps = {
  color: '#3483FA',
};

BookMarkIsChecked.propTypes = {
  color: PropTypes.string,
};

module.exports = BookMarkIsChecked;
