/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { RestClient } from 'nordic/restclient';
import Image from 'nordic/image';
import { Text, Title } from '@andes/typography';
import { useSelector } from 'react-redux';
import config from '../../../config/restclient/browser';
import { getContext } from '../../helpers/selectors';

const FallbackSellerCoach = (props) => {
  const { image, title, subtitle, button, recommendationKeys, setCoachData } =
    props;
  const { fullUrl } = useSelector(getContext);
  const url = fullUrl.split('/');
  const request = RestClient(config(fullUrl));
  const getData = () => {
    request
      .get('/note/recommendations', {
        headers: {
          id: url[url.length - 1],
          recommendationKeys,
        },
      })
      .then((response) => {
        setCoachData({
          ...response?.data?.seller_coach_content,
          tags: response?.data?.seller_coach_content?.tags || [],
          dismissOption: {},
          tracks: response.data.tracks,
        });
      });
  };

  return (
    <div className="fallback-seller-coach">
      <Image
        className="fallback-seller-coach-img"
        src={image.href}
        alt="fallback image"
      />
      <Title className="fallback-seller-coach-title">{title}</Title>
      <Text className="fallback-seller-coach-subtitle">{subtitle}</Text>
      <button className="fallback-seller-coach-link" onClick={() => getData()}>
        {button.text}
      </button>
    </div>
  );
};

FallbackSellerCoach.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  button: PropTypes.shape({
    text: PropTypes.string,
  }),
  image: PropTypes.shape({
    href: PropTypes.string,
    alt: PropTypes.string,
  }),
  recommendationKeys: PropTypes.string,
  setCoachData: PropTypes.func,
};

export default FallbackSellerCoach;
