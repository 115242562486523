import { checkSuScope } from './checkSuScope';

const defineUrlsAndDomains = (siteId) => {
  if (siteId === 'CBT') return '/learning-center';
  return '';
};

export function handlerRedirect(
  suScope,
  siteId,
  countryId,
  query,
  isMobile,
  isPagination = null,
  offset = null,
  limit = null,
) {
  const getInfoDefine = defineUrlsAndDomains(siteId);
  if (offset != null && limit) {
    return checkSuScope(
      `${getInfoDefine}/search?q=${query}&offset=${offset}&limit=${limit}`,
      suScope,
    );
  }
  if (isMobile) {
    return checkSuScope(
      `${getInfoDefine}/search?q=${query}&offset=0&limit=18`,
      suScope,
    );
  }
  if (isPagination) {
    return checkSuScope(
      `${getInfoDefine}/search?offset=$page&q=${query}`,
      suScope,
    );
  }
  return checkSuScope(`${getInfoDefine}/search?q=${query}`, suScope);
}
