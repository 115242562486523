const Book20 = require('../lib/Book20');
const GuideBook16 = require('../lib/GuideBook16');
const ReadGuide = require('../lib/ReadGuide');
const CardVideo = require('../lib/CardVideo');
const BookOpen34 = require('../lib/BookOpen34');
const Flag24 = require('../lib/Flag24');
const Flag32 = require('../lib/Flag32');
const Player20 = require('../lib/Player20');
const Rocket40 = require('../lib/Rocket40');
const Rocket54 = require('../lib/Rocket54');
const Rocket64 = require('../lib/Rocket64');
const Warranty30 = require('../lib/Warranty30');
const Progress20 = require('../lib/Progress20');
const ProgressLinear8 = require('../lib/ProgressLinear8');
const Shape = require('../lib/Shape');
const Like = require('../lib/Like');
const DisLike = require('../lib/DisLike');
const BookMarkIsChecked = require('../lib/BookMarkIsChecked');
const SuccessChecked = require('../lib/SuccessChecked');
const DoubleCheck = require('../lib/DoubleCheck');
const Growing = require('../lib/Growing');
const UserLogged = require('../lib/UserLogged');
const MultiCountry = require('../lib/MultiCountry');
const GlobalIcon = require('../lib/GlobalIcon');
const SiteIcons = require('../lib/sites');
const FallbackIcon = require('../lib/FallbackIcon');

module.exports = {
  Book20,
  GuideBook16,
  CardVideo,
  BookOpen34,
  Flag24,
  Flag32,
  Rocket40,
  Rocket54,
  Rocket64,
  Warranty30,
  Progress20,
  ProgressLinear8,
  Shape,
  BookMarkIsChecked,
  Like,
  DisLike,
  SuccessChecked,
  DoubleCheck,
  Growing,
  Player20,
  ReadGuide,
  UserLogged,
  MultiCountry,
  GlobalIcon,
  ...SiteIcons,
  FallbackIcon,
};
