import logger from 'nordic/logger';

const log = logger('redirect');

export const redirect = ({ eventData }) => {
  const { url, target } = eventData;
  try {
    const newUrl = new URL(url);
    if (window.suScope) {
      newUrl.searchParams.set('suScope', window.suScope);
    }
    if (target === '_blank') {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(newUrl.toString(), '_blank');
    } else {
      const [path, newP] = newUrl.toString()?.split('?');
      const fragment = newUrl.toString()?.split('#');
      let locationPath = `${path}?${new URLSearchParams(Object.fromEntries(new URLSearchParams(newP))).toString()}`;
      if (fragment?.length === 2) {
        locationPath += `#${fragment[1]}`;
      }
      window.location.href = locationPath;
    }
  } catch (e) {
    log(e);
  }
};
