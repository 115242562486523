/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'nordic/image';
import { Text, Title } from '@andes/typography';
import AndesCard, { CardContent } from '@andes/card';
import IconText from '../IconText';

const FloatingInfoCard = (props) => {
  const { img, title, description, label, items, children, stickyTitle, className } = props;
  const [showResume, setShowResume] = useState(false);
  const card = useRef(null);
  let observer;

  if (stickyTitle && typeof document !== 'undefined') {
    observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setShowResume(!entry.isIntersecting);
        });
      },
      {
        rootMargin: '10px',
        threshold: 0.5,
      },
    );
  }

  useEffect(() => {
    if (stickyTitle) observer.observe(document.querySelector('.andes-card'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card]);

  return (
    <>
      <AndesCard className={className || 'floating-card'} shadow="elevated" paddingSize="24">
        <CardContent>
          {img && <Image className="floating-info-image" src={img} alt="imagen de referencia" />}
          <div className={`floating-info${items ? ' floating-info-with-children' : ''}`}>
            <Title className="floating-info-title" component="h5" size="m" color="primary">
              {title}
            </Title>
            <div data-testid="card-reference" ref={card} id="card-reference" />
            {description && (
              <Text className="floating-info-subtitle" component="p" size="s" color="secondary">
                {description}
              </Text>
            )}
            {label && (
              <Text className="floating-info-content" component="p" size="s" color="positive">
                {label}
              </Text>
            )}
            {items && (
              <div className="floating-info-children">
                {items.map((ele, i) => (
                  <IconText key={`floating-info-children-${i}`} {...ele} />
                ))}
              </div>
            )}
          </div>
          {children}
        </CardContent>
      </AndesCard>
      {stickyTitle && showResume && (
        <AndesCard className="floating-card-resume" shadow="outline">
          <CardContent>
            <Title className="floating-info-title" component="h5" size="m" color="primary">
              {stickyTitle}
            </Title>
            {children}
          </CardContent>
        </AndesCard>
      )}
    </>
  );
};

FloatingInfoCard.propTypes = {
  title: PropTypes.string,
  stickyTitle: PropTypes.string,
  description: PropTypes.string,
  contentCount: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  img: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
};

export default FloatingInfoCard;
