import React from 'react';

import PropTypes from 'prop-types';
import { Text, Title } from '@andes/typography';
import Icon from '../Icon';

const { Image } = require('nordic/image');

const InitialInformation = (props) => {
  const { className, image, informationContents } = props;

  return (
    <div className={className}>
      {image && (
        <Image
          className={`${className}-image`}
          alt={image.alt}
          src={image.href}
        />
      )}
      {informationContents && (
        <div className={`${className}-info`}>
          {informationContents?.map(
            ({ icon, label, title, subtitle, className: itemClass, color }) => (
              <div className={`${className}-info-item`}>
                <div className={itemClass}>
                  <Icon
                    type={icon}
                    color={color}
                    containerClassName={[`${itemClass}-info-item-icon`]}
                  />
                  {title && (
                    <Title component="h6" size="l">
                      {title}
                    </Title>
                  )}
                </div>
                {label && (
                  <Text className="label" component="p" size="m">
                    {label}
                  </Text>
                )}
                <Text
                  component="p"
                  className={`${itemClass}-info-item-subtitle`}
                >
                  {subtitle}
                </Text>
              </div>
            ),
          )}
        </div>
      )}
    </div>
  );
};

InitialInformation.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  informationContents: PropTypes.oneOf([]),
};
InitialInformation.defaultProps = {
  className: 'sc-initial-information',
};

export default InitialInformation;
