/* eslint-disable max-len */
const React = require('react');
const PropTypes = require('prop-types');

const BookOpen34 = ({ color }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32">
    <path
      d="M4.48 24.96h5.76c3.84 0 4.48 1.28 5.76 2.56"
      stroke={color}
      strokeWidth="2.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 27.52V13.44a6.4 6.4 0 0 0-6.4-6.4H4.48v17.554M16 27.52V13.44a6.4 6.4 0 0 1 6.4-6.4h5.12v17.554"
      stroke={color}
      strokeWidth="2.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.521 24.96h-5.76c-3.84 0-4.48 1.28-5.76 2.56"
      stroke={color}
      strokeWidth="2.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

BookOpen34.defaultProps = {
  color: '#3483FA',
};

BookOpen34.propTypes = {
  color: PropTypes.string,
};

module.exports = BookOpen34;
