const colorIcons = {
  cyan: '#3aa19a',
  red: '#db4040',
  black: '#000000',
  gray: '#474747',
  blue: '#3483FA',
  graySolid70: '#E5E5E5',
  gray550: 'rgba(0,0,0, 0.55)',
  graySolid250: '#BFBFBF',
  green500: '#00A650',
};

module.exports = colorIcons;
