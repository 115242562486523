/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
const React = require('react');

const Warranty30 = () => (
  <svg width="30" height="35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m15 22.568-7.538-4.311-5.915 10.146 5.3-.025 2.222 4.365L15 22.568ZM15 22.568l7.539-4.311 5.914 10.146-5.3-.025-2.221 4.365L15 22.568Z"
      fill="#fff"
      stroke="#333"
      strokeWidth="1.5"
    />
    <path
      d="M26.043 13.25C26.043 19.154 21.133 24 15 24 8.868 24 3.958 19.154 3.958 13.25S8.868 2.5 15 2.5c6.133 0 11.043 4.846 11.043 10.75Z"
      fill="#FFE600"
      stroke="#fff"
      strokeWidth="3"
    />
    <ellipse
      cx="15"
      cy="13.25"
      rx="12.543"
      ry="12.25"
      stroke="#333"
      strokeWidth="1.5"
    />
  </svg>
);

module.exports = Warranty30;
