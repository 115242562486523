/* eslint-disable max-len */
const React = require('react');

const IconMLB = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_394_34444)">
      <path
        d="M15.875 6.69976C15.275 3.17476 12.2 0.499756 8.5 0.499756C4.8 0.499756 1.725 3.17476 1.125 6.69976L8.5 2.99976L15.875 6.69976ZM1.125 9.29976C1.725 12.8248 4.8 15.4998 8.5 15.4998C12.2 15.4998 15.275 12.8248 15.875 9.29976L8.5 12.9998L1.125 9.29976Z"
        fill="#128807"
      />
      <path
        d="M8.5 2.99976L1.125 6.69976C1.05 7.12476 1 7.54976 1 7.99976C1 8.44976 1.05 8.87476 1.125 9.29976L8.5 12.9998L15.875 9.29976C15.95 8.87476 16 8.44976 16 7.99976C16 7.54976 15.95 7.12476 15.875 6.69976L8.5 2.99976Z"
        fill="#F9CB38"
      />
      <path
        d="M7 7.09985C6.2 7.09985 5.45 7.27485 4.775 7.57485C4.75 7.72485 4.75 7.84985 4.75 7.99985C4.75 10.0749 6.425 11.7499 8.5 11.7499C9.9 11.7499 11.125 10.9749 11.775 9.84985C10.85 8.22485 9.05 7.09985 7 7.09985Z"
        fill="#002776"
      />
      <path
        d="M12.2 8.59976C12.225 8.39976 12.25 8.19976 12.25 7.99976C12.25 5.92476 10.575 4.24976 8.5 4.24976C7.025 4.24976 5.75 5.09976 5.125 6.34976C5.725 6.17476 6.35 6.07476 7 6.07476C9.125 6.07476 11 7.07476 12.2 8.59976Z"
        fill="#002776"
      />
      <path
        d="M7.00002 6.07471C6.35002 6.07471 5.72502 6.17471 5.12502 6.34971C4.95002 6.72471 4.82502 7.12471 4.77502 7.57471C5.45002 7.27471 6.20002 7.09971 7.00002 7.09971C9.05002 7.09971 10.85 8.19971 11.775 9.82471C12 9.44971 12.125 9.02471 12.2 8.59971C11 7.07471 9.12502 6.07471 7.00002 6.07471Z"
        fill="white"
      />
      <path
        d="M6 8.24976C6.13807 8.24976 6.25 8.13783 6.25 7.99976C6.25 7.86168 6.13807 7.74976 6 7.74976C5.86193 7.74976 5.75 7.86168 5.75 7.99976C5.75 8.13783 5.86193 8.24976 6 8.24976Z"
        fill="white"
      />
      <path
        d="M7 9.74976C7.13807 9.74976 7.25 9.63783 7.25 9.49976C7.25 9.36168 7.13807 9.24976 7 9.24976C6.86193 9.24976 6.75 9.36168 6.75 9.49976C6.75 9.63783 6.86193 9.74976 7 9.74976Z"
        fill="white"
      />
      <path
        d="M8.5 9.74976C8.63807 9.74976 8.75 9.63783 8.75 9.49976C8.75 9.36168 8.63807 9.24976 8.5 9.24976C8.36193 9.24976 8.25 9.36168 8.25 9.49976C8.25 9.63783 8.36193 9.74976 8.5 9.74976Z"
        fill="white"
      />
      <path
        d="M8.5 10.7498C8.63807 10.7498 8.75 10.6378 8.75 10.4998C8.75 10.3617 8.63807 10.2498 8.5 10.2498C8.36193 10.2498 8.25 10.3617 8.25 10.4998C8.25 10.6378 8.36193 10.7498 8.5 10.7498Z"
        fill="white"
      />
      <path
        d="M10.5 9.74976C10.6381 9.74976 10.75 9.63783 10.75 9.49976C10.75 9.36168 10.6381 9.24976 10.5 9.24976C10.3619 9.24976 10.25 9.36168 10.25 9.49976C10.25 9.63783 10.3619 9.74976 10.5 9.74976Z"
        fill="white"
      />
      <path
        d="M10.5 10.7498C10.6381 10.7498 10.75 10.6378 10.75 10.4998C10.75 10.3617 10.6381 10.2498 10.5 10.2498C10.3619 10.2498 10.25 10.3617 10.25 10.4998C10.25 10.6378 10.3619 10.7498 10.5 10.7498Z"
        fill="white"
      />
      <path
        d="M9.5 10.2498C9.63807 10.2498 9.75 10.1378 9.75 9.99976C9.75 9.86168 9.63807 9.74976 9.5 9.74976C9.36193 9.74976 9.25 9.86168 9.25 9.99976C9.25 10.1378 9.36193 10.2498 9.5 10.2498Z"
        fill="white"
      />
      <path
        d="M6 9.24976C6.13807 9.24976 6.25 9.13783 6.25 8.99976C6.25 8.86168 6.13807 8.74976 6 8.74976C5.86193 8.74976 5.75 8.86168 5.75 8.99976C5.75 9.13783 5.86193 9.24976 6 9.24976Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_394_34444">
        <rect width="16" height="16" fill="white" transform="translate(0.5 -0.000244141)" />
      </clipPath>
    </defs>
  </svg>
);

module.exports = IconMLB;
