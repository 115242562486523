const React = require('react');
const PropTypes = require('prop-types');
const WebviewFilter = require('./index');
const CheckFilters = require('../lib/checkFilter');

class CheckFilterClass extends WebviewFilter {
  getInitialState() {
    const { options } = this.props;
    return options.filter(option => option.checked).map(option => option.id);
  }

  getCurrentSelectionString(myCurrentSelection) {
    const { options } = this.props;

    const selectionIds = myCurrentSelection || [];

    return selectionIds
      .filter(Boolean)
      .map(selectionId => (options.find(option => option.id === selectionId)))
      .map(completeOption => completeOption.label)
      .join(', ');
  }
}

const CheckFilterComponent = props => {
  const { options, id, storageValue, myCurrentSelection, updateSelectionState } = props;

  return (
    <ul>
      {
        options.map((option) => (
          <CheckFilters
            updateSelection={updateSelectionState}
            myCurrentSelection={myCurrentSelection}
            storageValue={storageValue}
            idGroup={id}
            label={option.label}
            disabled={option.disabled}
            tagActive={option.tagActive}
            id={option.id}
            key={option.id}
          />
        ))
      }
    </ul>);
};

CheckFilterComponent.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  id: PropTypes.string.isRequired,
  storageValue: PropTypes.shape({}).isRequired,
  myCurrentSelection: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateSelectionState: PropTypes.func.isRequired,
};


module.exports = { CheckFilterClass, CheckFilterComponent };
