/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useYoutubeVideo } from '../../hooks/useYoutubeVideo';
import { useInterval } from '../../hooks/useInterval';

const YoutubeVideo = props => {
  const VIDEO = {
    PLAYING: 1,
    PAUSED: 2,
  };
  const ERROR_MARGIN = 2;
  const { src, intervalPercentage, onStateChange } = props;
  const [second, setSecond] = useState(0);
  const [playing, setPlaying] = useState(2);
  const [player, setPlayer] = useState({});
  const [increment, setIncrement] = useState(intervalPercentage);
  const [percentage, setPercentage] = useState(0);
  const { scriptFlag, ytLocal } = useYoutubeVideo();

  const onChangeSecond = () => {
    if (playing === VIDEO.PLAYING) {
      const percentageLocal = Math.round((player.getCurrentTime() * 100) / player.getDuration());
      setSecond(second + 1);
      setPercentage(percentageLocal);
    }
  };

  useEffect(() => {
    if (percentage >= (increment - ERROR_MARGIN) && percentage <= (100 - ERROR_MARGIN)) {
      onStateChange(increment);
      setIncrement(increment + intervalPercentage);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentage, increment]);

  useInterval(onChangeSecond, 1000);

  const getIdVideo = (url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
  };

  const onPlayerStateChange = (e) => {
    setPlaying(e.data);
  };

  useEffect(() => {
    if (scriptFlag) {
      const videoId = getIdVideo(src);
      window.YT.ready(() => {
        const playerYT = new ytLocal.Player(`videoid-${videoId}`, {
          height: '360',
          width: '640',
          videoId,
          events: {
            onStateChange: onPlayerStateChange,
          },
        });
        setPlayer(playerYT);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, ytLocal, scriptFlag]);


  return (
    <div className="yt-video" id={`videoid-${getIdVideo(src)}`} />
  );
};

YoutubeVideo.propTypes = {
  src: PropTypes.string,
  onStateChange: PropTypes.func,
  intervalPercentage: PropTypes.number,
};

export default YoutubeVideo;
