import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@andes/typography';
import Icon from '../Icon';

const IconText = props => {
  const { color, icon, label, styles } = props;
  return (
    <div className="icon-text" style={styles}>
      <Icon type={icon} color={color} />
      <Text component="p">{label}</Text>
    </div>
  );
};

IconText.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  styles: PropTypes.shape({}),
};

export default IconText;
