/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import MelidataTrack from 'nordic/melidata/melidata-track';
import RenderChildren from '../../components/RenderChildren';
import Fallback from '../../components/Fallback';
import { mapBrick } from '../../helpers/mapBrick';

import { getContext } from '../../helpers/selectors';

const Wrapper = (className, type) => (props) => {
  const { children, brick, performEvent, events = [] } = props;
  const { platform } = useSelector(getContext);
  const siteClass = platform.siteId === 'CBT' ? 'sc-cbt-view' : '';
  const trackView = brick?.data?.tracks?.PAGE_VIEW;

  let extraClass = '';
  const fallbackClass = brick.data?.fallback ? 'fallback' : '';
  if (brick.id === 'view_note') {
    // eslint-disable-next-line max-len
    extraClass =
      mapBrick(brick, ['note_container', 'html_container'])?.data
        ?.bannerType === 'VIDEO'
        ? 'sc-view-note-video'
        : '';
  }

  useEffect(() => {
    if (performEvent && events.length >= 1) {
      events.forEach((e) => {
        performEvent(e);
      });
    }
  }, []);

  const initTrack = trackView ? trackView.data : events[0]?.data?.data;

  return (
    <RenderChildren
      {...props}
      type={type}
      className={`sc-${className} ${siteClass} ${extraClass} ${fallbackClass}`.trim()}
    >
      <MelidataTrack path={initTrack?.path} type={initTrack?.type} event_data={initTrack?.eventData} />
      {children}
      {brick.data?.fallback && (
        <Fallback {...brick.data?.fallback} performEvent={performEvent} />
      )}
    </RenderChildren>
  );
};

export default Wrapper;
