/* eslint-disable max-len */
const React = require('react');
const PropTypes = require('prop-types');

const GuideBook16 = ({ color }) => (
  <svg className="sc-guide-book-icon" width="16" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 13.458h3c2 0 2.333.708 3 1.417"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 14.875V7.084c0-1.956-1.492-3.542-3.333-3.542H2v9.714M8 14.875V7.084c0-1.956 1.492-3.542 3.333-3.542H14v9.714"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 13.458h-3c-2 0-2.333.708-3 1.417"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

GuideBook16.defaultProps = {
  color: '#737373',
};

GuideBook16.propTypes = {
  color: PropTypes.string,
};

module.exports = GuideBook16;
