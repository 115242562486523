import React from 'react';
import PropTypes from 'prop-types';
import { Title, Text } from '@andes/typography';
import Image from 'nordic/image';
import IconText from '../IconText';
import MetaDataNote from '../MetaDataNote';
import GlobalTooltip from '../GlobalTooltip';

const Presentation = (props) => {
  const { title, description, image, icons, children, globalTooltip } = props;

  return (
    <section className="guide-presentation">
      <MetaDataNote title={title} subtitle={description} href="" />
      <div className="guide-presentation-detail">
        {children && (
          <div className="guide-presentation-button">{children}</div>
        )}
        <Title className="guide-presentation-title" size="xl">
          {title}
        </Title>
        <Text className="guide-presentation-description" color="secondary">
          {description}
        </Text>
        <div className="guide-presentation-tags-container">
          {icons?.map((tag) => (
            <IconText {...tag} />
          ))}
          {globalTooltip && <GlobalTooltip {...globalTooltip} />}
        </div>
      </div>
      <div className="guide-presentation-image-container">
        <Image
          className="guide-presentation-image"
          src={image.href}
          alt={image.alt}
        />
      </div>
    </section>
  );
};

Presentation.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.shape({
    href: PropTypes.string,
    alt: PropTypes.string,
  }),
  globalTooltip: PropTypes.shape({
    className: PropTypes.string,
    title: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  icons: PropTypes.arrayOf(PropTypes.shape({})),
  children: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Presentation;
