/* eslint-disable max-len */
const React = require('react');
const PropTypes = require('prop-types');

const Player20 = ({ color }) => (
  <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <g fill={color}>
      <path d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0Zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8Z" />
      <path d="m7 15 8-5-8-5v10Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={color} d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

Player20.defaultProps = {
  color: '#3483FA',
};

Player20.propTypes = {
  color: PropTypes.string,
};

module.exports = Player20;
