const defineUrlsAndDomains = (siteId, redirectUrl) => {
  let redirectUrlGo = redirectUrl;
  let domainName = 'https://www.mercadolibre.com';
  if (!redirectUrl && typeof window !== 'undefined') {
    redirectUrlGo = window.location.href;
  }
  if (siteId === 'MLB') {
    domainName = 'https://www.mercadolivre.com';
  }
  return {
    domainName,
    redirectUrlGo,
  };
};

export function handlerLogout(siteId, redirectUrl) {
  const getInfoDefine = defineUrlsAndDomains(siteId, redirectUrl);
  // eslint-disable-next-line max-len
  return `${getInfoDefine.domainName}/jms/${siteId.toLowerCase()}/lgz/logout?go=${getInfoDefine.redirectUrlGo}`;
}


export function handlerLogin(siteId = '', redirectUrl) {
  const getInfoDefine = defineUrlsAndDomains(siteId, redirectUrl);
  // eslint-disable-next-line max-len
  return `${getInfoDefine.domainName}/jms/${siteId.toLowerCase()}/lgz/login?platform_id=ml&go=${encodeURIComponent(getInfoDefine.redirectUrlGo)}`;
}
