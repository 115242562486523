const { CheckFilterClass, CheckFilterComponent } = require('./checkFilter');
const { RadioFilterClass, RadioFilterComponent } = require('./radioFilter');
const { SubGroupFilterClass, SubGroupFilterComponent } = require('./subGroupFilter');

const getFilterComponent = props => {
  const { type } = props;
  switch (type) {
    case 'check': {
      return [new CheckFilterClass(props), CheckFilterComponent];
    }
    case 'radio': {
      return [new RadioFilterClass(props), RadioFilterComponent];
    }
    case 'subGroup': {
      return [new SubGroupFilterClass(props), SubGroupFilterComponent];
    }
    default: {
      console.log('NO existe el filtro ', type);
    }
  }
  return [null, null];
};

module.exports = getFilterComponent;
