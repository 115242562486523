/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable security/detect-object-injection */

const React = require('react');
const PropTypes = require('prop-types');
const { icons } = require('./helper');

const Icon = props => {
  const { type, containerClassName, containerStyles, onClick } = props;
  const IconCustom = icons[type];
  const onClickHandler = event => onClick?.(event);
  // eslint-disable-next-line react/destructuring-assignment
  if (onClick === undefined) delete props.onClick;

  if (IconCustom === undefined) return <Icon {...props} type="FallbackIcon" />;

  return (
    // eslint-disable-next-line max-len, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      onClick={onClickHandler}
      style={{ ...containerStyles }}
      className={`icon-custom ${containerClassName?.join(' ')}`}
    >
      <IconCustom {...props} />
    </div>
  );
};
Icon.defaultProps = {
  type: 'Add16',
  containerStyles: {},
  containerClassName: [''],
};
Icon.propTypes = {
  type: PropTypes.string.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func,
  containerClassName: PropTypes.array,
};
module.exports = Icon;
