/* eslint-disable security/detect-object-injection */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SellerCoach as SellerCoachComponent } from 'seller-journey-ui';
import { useSelector } from 'react-redux';
import FallbackSellerCoach from '../../components/FallbackSellerCoach';

import { getContext } from '../../helpers/selectors';
import { waitForElm } from '../../helpers/dom-utils';

const BASE_URL = '/api';

const SellerCoach = (props) => {
  const { sellerCoachContent, tracks, performEvent } = props;
  const [coachData, setCoachData] = useState({
    ...sellerCoachContent,
    coachmark: undefined,
    tags: sellerCoachContent?.tags || [],
    tracks,
  });

  const { anchorId, referer, clientKey, fallback, tags } = coachData;
  const classWithTags =
    tags.length > 0 ? 'seller-coach-with-tags' : 'seller-coach-without-tags';

  const { device, platform } = useSelector(getContext);

  if (fallback || coachData === {}) {
    return <FallbackSellerCoach {...fallback} setCoachData={setCoachData} />;
  }

  useEffect(() => {
    if (tracks && tracks.length > 0) {
      tracks.forEach((item) => {
        performEvent({ type: 'sc_tracking', data: item });
      });
    }
  }, [coachData]);

  useEffect(() => {
    if (device.type === 'mobile') {
      waitForElm('.seller-coach .tags-mobile-controls').then((ele) => {
        if (ele.lastChild) ele.removeChild(ele.lastChild);
        if (platform.siteId === 'CBT' && tags.length > 0) {
          const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
              if (mutation.type === 'attributes') {
                const eleTag = mutation.target;
                const position = Array.from(eleTag.parentNode.children).indexOf(
                  eleTag,
                );
                if (!eleTag.classList.contains(tags[position]?.key)) {
                  eleTag.classList.add(tags[position]?.key);
                }
              }
            });
          });
          tags.forEach((tag, i) => {
            ele.children[i].classList.add(tag.key);
            observer.observe(ele.children[i], {
              attributes: true,
            });
          });
        }
      });
    }
  }, []);

  return (
    <>
      <div
        id={anchorId}
        className={`seller-coach-anchor ${
          coachData.tags.length > 0
            ? `tag-modifier-${coachData.tags.length}`
            : ''
        } ${classWithTags}`.trim()}
      />
      <SellerCoachComponent
        referrer={referer || device?.type}
        baseURL={BASE_URL}
        clientId={clientKey}
        groupTagsSlider={device?.type !== 'mobile' && tags.length > 0}
        cardsPerPage={2.8}
        mockedData={coachData}
      />
    </>
  );
};

SellerCoach.defaultProps = {
  sellerCoachContent: {
    baseTracks: {},
    bookmark: {
      cards: [],
    },
    dismissOption: {},
    emptyState: {},
    tags: [],
    wordings: {},
    parentSiteId: null,
    clientKey: 'selling_coach',
  },
  tracks: [],
};

SellerCoach.propTypes = {
  sellerCoachContent: PropTypes.shape({
    tags: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  tracks: PropTypes.arrayOf(PropTypes.shape({})),
  performEvent: PropTypes.func,
};

export default SellerCoach;
