/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
const React = require('react');
const PropTypes = require('prop-types');

const Rocket54 = ({ color }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 54">
    <rect x=".5" width="54" height="54" rx="27" fill={color} />
    <path
      d="M30.22 42.999a2.298 2.298 0 0 1-1.702-.682 2.27 2.27 0 0 1-.63-1.193l-1.295-6.427-6.812-6.82-6.42-1.295a2.35 2.35 0 0 1-1.822-2.728 2.49 2.49 0 0 1 .647-1.193l5.875-5.882h10.9l1.617-1.551A13.098 13.098 0 0 1 39.962 11a10.76 10.76 0 0 1 1.515.102 2.315 2.315 0 0 1 1.942 1.944 12.782 12.782 0 0 1-4.121 10.91l-1.516 1.518v10.91l-5.875 5.882a2.266 2.266 0 0 1-1.686.733Zm-1.48-8.524 1.123 5.609a.563.563 0 0 0 .562.46h.17l.222-.17 4.853-4.842v-7.978l-6.93 6.921Zm11.103-21.43a11.916 11.916 0 0 0-7.868 3.632L21.757 26.906l5.807 5.796 10.218-10.229a11.252 11.252 0 0 0 3.56-9.001v-.324h-.307a8.774 8.774 0 0 0-1.192-.051v-.051ZM13.94 23.838v.375a.545.545 0 0 0 .426.41l5.603 1.125 6.93-6.939h-7.97l-4.989 5.03Zm10.03 11.678-1.532-1.551a9.839 9.839 0 0 1-2.35 3.494 13.277 13.277 0 0 1-5.654 2.592 13.492 13.492 0 0 1 2.606-5.643 9.826 9.826 0 0 1 3.49-2.353l-1.549-1.551a12.743 12.743 0 0 0-3.406 2.455 16.63 16.63 0 0 0-3.406 7.603 1.399 1.399 0 0 0 .392 1.313 1.413 1.413 0 0 0 1.294.392 16.63 16.63 0 0 0 7.63-3.41 12.194 12.194 0 0 0 2.486-3.29v-.051Z"
      fill="#fff"
    />
  </svg>
);

Rocket54.defaultProps = {
  color: '#3483FA',
};

Rocket54.propTypes = {
  color: PropTypes.string,
};

module.exports = Rocket54;
