/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'nordic/image';
import Button, { ButtonText } from '@andes/button';
import { Text } from '@andes/typography';
import { Pill } from '@andes/badge';
import Thumbnail from '@andes/thumbnail';
import { useSelector } from 'react-redux';
import { handlerLogin, handlerLogout } from '../../helpers/actionsAuth';
import { getContext, getSearchData } from '../../helpers/selectors';
import Icon from '../Icon';
import Modal from '../Modal';

// old-header
import Search from '../Search';
import { checkSuScope } from '../../helpers/checkSuScope';
import { useStateRef } from '../../hooks/useStateRef';

const Menu = (props) => {
  const {
    login,
    logout,
    icon,
    userName,
    nickName,
    image,
    labelNavigation,
    navigation,
    greeting,
    performEvent,
  } = props;

  const { platform, isMobile, fullUrl, auth, suScope } =
    useSelector(getContext);
  const [hamburgerMenu, setHamburgerMenu] = useState(false);
  const [reducedDesktop, setReducedDesktop] = useStateRef(false);

  // old-header
  const searchData = useSelector(getSearchData);
  const oldAvatar = {
    alt: 'avatar',
    href: 'https://http2.mlstatic.com/storage/developers-site-cms-admin/OpenPlatform/366154775951-svgexport-2@2x.png',
  };

  const handleStateHamburger = () => {
    setHamburgerMenu(!hamburgerMenu);
  };

  const onClickEvent = (events) => {
    const newEvents = events;
    newEvents.map((event) => {
      if (event.type === 'sc_tracking') {
        event.data.data.eventData = {
          ...event.data.data.eventData,
          previous_page: fullUrl,
        };
      }
      return newEvents;
    });
    if (performEvent && newEvents) {
      newEvents.forEach((event) => performEvent(event));
    }
  };

  useEffect(() => {
    let handleResize;
    if (!isMobile) {
      handleResize = () => {
        if (window.innerWidth < 1025) {
          setReducedDesktop(true);
        } else {
          setReducedDesktop(false);
        }
      };
      handleResize();
      window.addEventListener('resize', handleResize);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!isMobile && !reducedDesktop && navigation) {
    return (
      <a
        href={checkSuScope(navigation[0]?.events[0]?.data?.url, suScope)}
        alt={navigation[0]?.title}
        className="nav-logo-label"
      >
        {navigation[0]?.title}
      </a>
    );
  }
  const childrenComponents = () => {
    /* istanbul ignore next */
    if (nickName) {
      return (
        <div className="user-icon-text">
          <p>{nickName}</p>
        </div>
      );
    }
    if (image) {
      return (
        <Thumbnail size="48">
          <Image alt={image.alt} src={image.href} />
        </Thumbnail>
      );
    }
    /* istanbul ignore next */
    return icon ? (
      <Icon
        containerClassName={['user-container-icon']}
        color="#000"
        type={icon}
      />
    ) : (
      <></>
    );
  };

  const mapItems = (items) => items.map((item) => (
    <Text
      component="a"
      onClick={() => {
        onClickEvent(item?.events);
      }}
      className="sc-navigation-mobile-items"
      size="s"
    >
      {item?.title}
      {item?.pill && (
        <Pill type={item?.color} size="small">
          {item?.pill}
        </Pill>
      )}
    </Text>
  ));

  const ModalContent = (
    <div
      role="button"
      aria-label="overlay menu"
      tabIndex={0}
      className="sc-menu-modal-content"
      onClick={() => {
        setHamburgerMenu(false);
      }}
    />
  );

  return (
    <div
      role="menu"
      className={`nav-container ${
        isMobile || reducedDesktop ? 'nav-mobile-container' : ''
      }`.trim()}
    >
      <div className="menu">
        {(isMobile || reducedDesktop) && (
          <div
            role="button"
            className={`hamburger-btn ${hamburgerMenu ? 'open' : ''}`.trim()}
            htmlFor="nav-header-menu-switch"
            aria-controls="nav-header-menu"
            onClick={() => handleStateHamburger()}
            tabIndex={0}
            aria-label="menu-btns"
          >
            <span className="hamburger-top-bread" />
            <span className="hamburger-patty" />
            <span className="hamburger-bottom-bread" />
          </div>
        )}
        {/* old header */}
        {hamburgerMenu && navigation?.length === 0 && (
          <div className="menu-container">
            {!auth && (
              <Button
                className="login-btn"
                hierarchy="transparent"
                size="small"
                href={handlerLogin(platform.siteId, fullUrl)}
              >
                <ButtonText>{login}</ButtonText>
              </Button>
            )}
            {auth && (
              <div className="nav-section__user-photo mobile-only">
                <div className="user-photo__wrapper">
                  <Image
                    className="user-photo"
                    src={oldAvatar?.href}
                    alt={oldAvatar?.alt}
                  />
                </div>
                <span className="user-photo__message">{greeting}</span>
              </div>
            )}
            <Search {...searchData} />
            {auth && (
              <Button
                className="login-btn"
                hierarchy="transparent"
                size="small"
                href={handlerLogout(platform.siteId, fullUrl)}
              >
                <ButtonText>{logout}</ButtonText>
              </Button>
            )}
          </div>
        )}
        {/* old header */}
        <>
          {navigation?.length > 0 && (
            <Modal
              className="sc-menu-mobile"
              open={hamburgerMenu}
              onClose={() => {
                setHamburgerMenu(false);
              }}
              content={ModalContent}
            >
              <div className="sc-navigation-mobile">
                <div className="sc-navigation-mobile-header">
                  {mapItems(navigation.filter((item) => item?.type === 'item'))}
                </div>
                <div className="sc-navigation-mobile-body">
                  <Text>{labelNavigation}</Text>
                  {mapItems(
                    navigation.filter((item) => item?.type === 'group'),
                  )}
                </div>
              </div>
              {(auth || (!auth && login)) && (
                <div className={`menu-container ${auth ? 'login' : ''}`}>
                  {!auth && login && (
                    <Button
                      className="login-btn"
                      size="medium"
                      href={handlerLogin(platform?.siteId, fullUrl)}
                    >
                      <ButtonText>{login}</ButtonText>
                    </Button>
                  )}
                  {auth && (
                    <div className="sc-user-profile">
                      {childrenComponents()}
                      <div className="sc-data-profile">
                        <Text className="sc-data-profile-name">{userName}</Text>
                        {logout && (
                          <Text
                            component="a"
                            color="link"
                            href={handlerLogout(platform?.siteId, fullUrl)}
                          >
                            {logout}
                          </Text>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Modal>
          )}
        </>
      </div>
    </div>
  );
};

Menu.propTypes = {
  labelNavigation: PropTypes.string,
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      events: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      ),
    }),
  ),
  login: PropTypes.string,
  logout: PropTypes.string,
  icon: PropTypes.string,
  userName: PropTypes.string,
  nickName: PropTypes.string,
  greeting: PropTypes.string,
  image: PropTypes.shape({
    href: PropTypes.string,
    alt: PropTypes.string,
  }),
  performEvent: PropTypes.func,
};

Menu.defaultProps = {
  navigation: [],
};

export default Menu;
