/* eslint-disable max-len */
const React = require('react');

const IconMLM = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_394_34438)">
      <path
        d="M16 7.9998C16 4.7248 13.925 1.9498 11 0.924805V15.0748C13.925 14.0498 16 11.2748 16 7.9998Z"
        fill="#E3283E"
      />
      <path d="M1 7.9998C1 11.2748 3.1 14.0498 6 15.0748V0.924805C3.1 1.9498 1 4.7248 1 7.9998Z" fill="#128A60" />
      <path
        d="M11 0.924756C10.225 0.649756 9.375 0.499756 8.5 0.499756C7.625 0.499756 6.775 0.649756 6 0.924756V15.0748C6.775 15.3498 7.625 15.4998 8.5 15.4998C9.375 15.4998 10.225 15.3498 11 15.0748V0.924756Z"
        fill="white"
      />
      <path
        d="M9.17498 9.42471C9.12498 9.42471 9.09998 9.37471 9.07498 9.34971C9.07498 9.29971 9.27498 9.24971 9.29998 9.24971V9.19971C8.97498 9.29971 7.99998 9.37471 7.79998 9.02471C7.69998 9.17471 7.52498 9.07471 7.34998 8.94971C7.37498 8.99971 7.47498 9.24971 7.47498 9.22471C7.52498 9.24971 7.84998 9.32471 7.84998 9.39971L7.77498 9.47471C8.29998 9.64971 8.62498 9.69971 9.17498 9.42471Z"
        fill="#428BC1"
      />
      <path
        d="M8.85 9.42478C8.875 9.39978 8.875 9.37478 8.875 9.32478L8.85 9.24978C8.9 9.19978 8.85 9.07478 8.775 9.09978C8.725 9.12478 8.75 9.12478 8.7 9.09978C8.675 9.09978 8.675 9.07478 8.65 9.07478H8.2C8.15 9.07478 8.15 9.04978 8.1 9.04978C8.05 9.02478 8.025 9.07478 8 9.09978C8 9.14978 8.025 9.14978 8.025 9.17478C8.025 9.19978 8 9.22478 8 9.27478C8 9.32478 8.025 9.34978 8.05 9.37478C7.95 9.44978 8.05 9.57478 8.15 9.49978C8.175 9.54978 8.25 9.54978 8.325 9.54978H8.6C8.65 9.54978 8.75 9.57478 8.775 9.49978C8.85 9.62478 8.95 9.47478 8.85 9.42478Z"
        fill="#ED4C5C"
      />
      <path
        d="M9.64996 8.77472C9.44996 8.52472 9.34996 8.89972 9.12496 8.87472C9.17496 8.57472 8.72496 8.67472 8.59996 8.74972C8.62496 8.72472 8.64996 8.67472 8.67496 8.62472C8.57496 8.62472 8.44996 8.67472 8.37496 8.59972C8.22496 8.49972 8.02496 8.44972 7.89996 8.62472C7.79996 8.47472 7.52496 8.32472 7.37496 8.47472C7.37496 8.32472 7.22496 8.09972 7.04996 8.12472C6.84996 8.14972 6.92496 8.39972 7.02496 8.49972C7.09996 8.57472 7.19996 8.59972 7.29996 8.59972C7.29996 8.64972 7.32496 8.67472 7.34996 8.69972C7.47496 8.79972 7.72496 8.82472 7.84996 8.72472C7.84996 8.97472 8.34996 8.97472 8.47496 8.84972C8.42496 8.97472 8.37496 9.22472 8.54996 9.22472C8.69996 9.22472 8.64996 9.07472 8.77496 9.02472C8.87496 8.97472 8.99996 8.94972 9.09996 8.99972C9.27496 9.09972 9.82496 9.07472 9.64996 8.77472Z"
        fill="#428BC1"
      />
      <path
        d="M7.60003 8.14984C7.57503 8.09984 7.52503 8.04984 7.52503 8.04984C7.42503 8.07484 7.42503 7.99984 7.40003 7.92484C7.37503 7.79984 7.20003 7.74984 7.20003 7.64984C7.20003 7.54984 7.30003 7.44984 7.22503 7.34984C7.15003 7.24984 7.02503 7.19984 7.02503 7.24984C7.00003 7.29984 7.12503 7.29984 7.12503 7.42484C7.12503 7.57484 6.97503 7.69984 7.15003 7.82484C7.25003 7.97484 7.25003 8.24984 7.42503 8.24984C7.50003 8.24984 7.57503 8.22484 7.60003 8.14984Z"
        fill="#75A843"
      />
      <path
        d="M7.975 8.02481C7.925 7.92481 7.975 7.77481 7.875 7.69981C7.75 7.59981 7.5 7.79981 7.475 7.54981C7.475 7.49981 7.75 7.19981 7.8 7.17481C7.875 7.07481 7.85 6.89981 7.775 6.84981C7.675 6.79981 7.65 6.84981 7.575 6.94981C7.6 6.97481 7.7 7.02481 7.7 7.04981C7.625 7.22481 7.4 7.24981 7.35 7.42481C7.3 7.54981 7.325 7.74981 7.45 7.82481C7.525 7.87481 7.575 7.87481 7.65 7.87481C7.875 7.84981 7.75 7.89981 7.775 7.94981C7.775 7.99981 7.85 8.17481 7.975 8.02481ZM7.225 6.79981C7.225 6.94981 7.375 6.92481 7.475 6.89981C7.475 6.84981 7.475 6.79981 7.5 6.74981C7.45 6.77481 7.375 6.77481 7.35 6.74981C7.275 6.69981 7.525 6.59981 7.5 6.59981C7.5 6.59981 7.25 6.72481 7.35 6.57481C7.375 6.52481 7.425 6.47481 7.45 6.42481C7.475 6.37481 7.375 6.42481 7.375 6.42481C7.35 6.42481 7.325 6.42481 7.3 6.44981C7.15 6.57481 7.2 6.64981 7.225 6.79981Z"
        fill="#75A843"
      />
      <path
        d="M9.87505 6.79976C9.75005 6.62476 9.27505 6.04976 8.60005 5.99976C8.52505 5.99976 8.32505 6.07476 8.25005 6.09976C8.02505 6.24976 8.50005 6.37476 8.52505 6.47476C8.52505 6.52476 8.55005 6.54976 8.55005 6.59976C8.47505 6.47476 8.40005 6.39976 8.37505 6.34976C8.25005 6.39976 8.05005 6.32476 8.02505 6.19976C8.00005 6.24976 8.00005 6.27476 7.95005 6.32476C7.95005 6.24976 7.92505 6.24976 7.90005 6.19976C7.90005 6.27476 7.90005 6.37476 7.82505 6.39976C7.85005 6.34976 7.82505 6.29976 7.80005 6.24976C7.80005 6.32476 7.80005 6.37476 7.75005 6.42476C7.77505 6.37476 7.72505 6.34976 7.72505 6.29976C7.70005 6.49976 7.42505 6.57476 7.70005 6.64976C7.80005 6.67476 7.85005 6.64976 7.95005 6.69976C7.97505 6.72476 8.02505 6.77476 8.02505 6.74976C8.02505 6.77476 7.92505 6.87476 8.00005 6.89976C7.97505 6.92476 7.90005 7.07476 7.90005 7.07476C7.97505 7.07476 7.92505 7.12476 7.87505 7.17476C7.80005 7.29976 7.87505 7.42476 7.90005 7.57476C7.90005 7.52476 7.97505 7.59976 7.97505 7.67476C7.97505 7.77476 8.07505 7.82476 8.10005 7.89976C7.97505 7.77476 7.95005 7.89976 8.10005 7.97476C7.97505 7.97476 7.95005 8.04976 8.07505 8.09976C7.77505 8.09976 8.02505 8.22476 8.07505 8.24976C8.27505 8.29976 8.42505 8.14976 8.42505 8.12476C8.42505 8.12476 8.82505 8.52476 8.85005 8.52476C8.87505 8.52476 9.07505 8.42476 9.12505 8.47476C9.17505 8.54976 9.22505 8.47476 9.27505 8.49976C9.32505 8.54976 9.52505 8.49976 9.57505 8.47476C9.60005 8.47476 9.85005 8.42476 9.80005 8.37476C9.67505 8.27476 9.52505 8.17476 9.40005 8.07476C9.27505 7.97476 9.15005 7.89976 9.05005 7.79976C9.00005 7.74976 9.00005 7.64976 8.97505 7.57476C9.20005 7.79976 9.42505 7.92476 9.40005 7.49976C9.55005 7.62476 9.85005 8.32476 9.87505 8.32476C9.95005 8.32476 9.87505 7.57476 9.87505 7.49976C9.97505 7.59976 9.97505 8.42476 10.0001 8.42476C10.1251 8.44976 10.3001 7.34976 9.87505 6.79976Z"
        fill="#89664C"
      />
      <path
        d="M8.07495 8.07478C8.04995 8.07478 7.92495 7.89978 7.92495 7.87478C7.99995 8.17478 7.69995 7.99978 7.77495 7.87478C7.67495 7.84978 7.42495 7.99978 7.52495 8.14978C7.47495 8.24978 7.87495 8.17478 7.89995 8.12478C7.99995 8.34978 8.24995 8.09978 8.07495 8.07478ZM9.24995 8.82478C9.37495 8.64978 8.84995 8.59978 8.89995 8.39978C8.82495 8.44978 8.72495 8.62478 8.62495 8.57478C8.57495 8.54978 8.37495 8.67478 8.47495 8.74978C8.47495 8.64978 8.52495 8.72478 8.59995 8.69978C8.57495 8.77478 8.49995 8.84978 8.62495 8.89978C8.57495 8.74978 8.77495 8.82478 8.74995 8.74978C8.69995 8.69978 8.92495 8.69978 8.94995 8.69978C9.02495 8.69978 9.27495 8.77478 9.17495 8.84978C9.17495 8.84978 9.22495 8.84978 9.24995 8.82478ZM7.57495 6.67478C7.54995 6.72478 7.44995 6.79978 7.44995 6.87478C7.44995 6.92478 7.57495 7.04978 7.62495 6.97478C7.47495 6.87478 7.64995 6.79978 7.72495 6.79978C7.74995 6.79978 7.74995 6.84978 7.74995 6.84978C7.77495 6.87478 7.97495 6.79978 7.94995 6.74978C7.97495 6.64978 7.64995 6.64978 7.57495 6.67478Z"
        fill="#FFCE31"
      />
    </g>
    <defs>
      <clipPath id="clip0_394_34438">
        <rect width="16" height="16" fill="white" transform="translate(0.5 -0.000244141)" />
      </clipPath>
    </defs>
  </svg>
);

module.exports = IconMLM;
