/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
const React = require('react');
const PropTypes = require('prop-types');

const Rocket40 = ({ color }) => (
  <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill={color} />
    <g>
      <path
        d="M21.7 30a1.435 1.435 0 0 1-1.064-.427 1.417 1.417 0 0 1-.394-.746l-.809-4.017-4.257-4.262-4.013-.81a1.469 1.469 0 0 1-1.139-1.704c.064-.282.204-.54.405-.746l3.672-3.676h6.812l1.011-.97A8.185 8.185 0 0 1 27.789 10c.316-.001.633.02.947.064a1.447 1.447 0 0 1 1.213 1.214 7.989 7.989 0 0 1-2.576 6.82l-.947.948v6.819l-3.672 3.676a1.415 1.415 0 0 1-1.053.458Zm-.925-5.328.702 3.505a.352.352 0 0 0 .351.288h.107l.138-.107 3.033-3.026v-4.986l-4.332 4.326Zm6.94-13.394a7.447 7.447 0 0 0-4.918 2.27L16.41 19.94l3.629 3.623 6.386-6.393a7.032 7.032 0 0 0 2.225-5.626v-.203h-.192a5.486 5.486 0 0 0-.745-.032v-.032Zm-16.19 6.745v.234a.341.341 0 0 0 .266.256l3.502.703 4.332-4.336h-4.981l-3.119 3.143Zm6.27 7.299-.959-.97a6.15 6.15 0 0 1-1.468 2.184 8.299 8.299 0 0 1-3.534 1.62 8.432 8.432 0 0 1 1.628-3.527 6.142 6.142 0 0 1 2.182-1.47l-.968-.97c-.79.39-1.51.909-2.13 1.534a10.395 10.395 0 0 0-2.128 4.752.874.874 0 0 0 .245.82.883.883 0 0 0 .809.246 10.393 10.393 0 0 0 4.768-2.131 7.627 7.627 0 0 0 1.554-2.056v-.032Z"
        fill="#fff"
      />
    </g>
  </svg>
);

Rocket40.defaultProps = {
  color: '#3483FA',
};

Rocket40.propTypes = {
  color: PropTypes.string,
};

module.exports = Rocket40;
