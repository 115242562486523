import React from 'react';
import { useSelector } from 'react-redux';
import tracksContext from '../context/tracks-context';
import { getTracks } from '../helpers/selectors';


export const useTracks = () => {
  const tracks = useSelector(getTracks);
  const { trackMelidata } = React.useContext(tracksContext.Consumer);
  const trackDataFromStorage = (name, extraParameter = {}) => {
    if (tracks) {
      // eslint-disable-next-line security/detect-object-injection
      const { path, eventData, type } = tracks[name].data;
      const data = { ...eventData, ...extraParameter };
      trackMelidata(path, data, type);
    }
  };

  return { trackDataFromStorage };
};

