/* eslint-disable react/prop-types */
import React from 'react';

import { useSelector } from 'react-redux';
import { getBrickStack } from 'flox-web-client/src/ducks/flox-selectors';
import RenderChildren from '../../components/RenderChildren';
import { mapBrick } from '../../helpers/mapBrick';
import Fallback from '../../components/Fallback';

const Wrapper = (className, type) => (props) => {
  const { children, brick, performEvent } = props;
  const brickStack = useSelector(getBrickStack);
  let extraClass = '';

  if (brick.id === 'backing_note_container') {
    // eslint-disable-next-line max-len
    const htmlContainer = mapBrick(brickStack.page, [
      'view_note',
      'note_container',
      'html_container',
    ]).data;
    if (!htmlContainer.content && htmlContainer?.bannerType === 'VIDEO') {
      extraClass = 'backing-note-container-video';
    }
  }
  return (
    <RenderChildren
      {...props}
      type={type}
      className={`sc-${className} ${extraClass}`.trim()}
    >
      {children}
      {brick.data?.fallback && (
        <Fallback {...brick.data?.fallback} performEvent={performEvent} />
      )}
    </RenderChildren>
  );
};

export default Wrapper;
