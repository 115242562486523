/* eslint-disable */
import React from 'react';

import ViewRender from '../containers/ViewRender';
import renderChildrenWrapper from '../containers/RenderChildren';
import noteContainer from '../containers/NoteContainer';
import PaginationContainer from '../containers/PaginationContainer';
import ctaButton from '../components/CtaButton';
import floatPanelCard from '../components/FloatPanelCard';
import iconText from '../components/IconText';
import htmlContainer from '../components/NoteHtml';
import pushLoginRec from '../components/PushLoginRec';
import header from '../components/Header';
import footer from '../components/Footer';
import menu from '../components/Menu';
import suTitle from '../components/SuTitle';
import search from '../components/Search';
import user from '../components/User';
import ActionsSharedAndFavorite from '../components/ActionsSharedAndFavorite';
import FeedBack from '../components/FeedBack';
import CtaNewNotes from '../components/CtaNewNotes';
import SellerCoach from '../containers/SellerCoach/index';
import NoteTitle from '../components/NoteTitle';
import NoteSubtitle from '../components/NoteSubtitle';
import CategoriesNote from '../components/CategoriesNote';
import ShareButton from '../components/ShareButton';
import Presentation from '../components/Presentation';
import Tag from '../components/Tag';
import RowContentGuie from '../components/RowContentGuie';
import FilterView from '../components/FilterView';
import HomeHeader from '../components/HomeHeader';
import CardContainer from '../components/CardContainer';
import CardChild from '../components/CardChild';
import Card from '../components/Card';
import CardIcon from '../components/CardIcon';
import RecoContainer from '../components/RecoContainer';
import Carrousel from '../components/Carrousel';
import SectionContainer from '../components/SectionContainer';
import FindContainer from '../components/InitialInformation';
import Dropdown from '../components/Dropdown';
import SearchContentCards from '../containers/SearchContentCards';
import RowFilter from '../components/RowFilter';
import ButtonFilter from '../components/ActionButtonFilter';
import Loading from '../components/Loading';
import TitleAndSubTitle from "../components/TitleAndSubTitle";
import Landing from '../components/Landing';

const ContainersUI = {
  page: renderChildrenWrapper('page', 'fragment'),
  view_note: ViewRender('view-note', 'main'),
  guide_view: ViewRender('view-guide', 'main'),
  home_view: ViewRender('view-home', 'main'),
  landing_view: ViewRender('view-landing', 'main'),
  search_view: ViewRender('view-search', 'main'),
  su_main: renderChildrenWrapper('main', 'div'),
  container: renderChildrenWrapper('container'),
  cards_content: renderChildrenWrapper('content-card'),
  note_container: noteContainer('note-container'),
  backing_note_container: renderChildrenWrapper('backing-note-container'),
  search_cards_container: renderChildrenWrapper('filter-right', 'div'),
  section_search_container: renderChildrenWrapper('container'),
  search_filter_container: renderChildrenWrapper('search-container-filter', 'div'),
  note_presentation_container: renderChildrenWrapper('note_presentation_container'),
  toolbar: renderChildrenWrapper('toolbar', 'div'),
  filter_container: renderChildrenWrapper('row-filter-container'),
  row_content: renderChildrenWrapper('row-container-filter-rows'),
};

const GenericComponents = {
  text: ({ text, className }) => (text ? <p className={className || ''}>{text}</p> : null),
}

const MobileUI = {
  search_mobile_container: SearchContentCards,
  button_mobile_container: renderChildrenWrapper('row-container-button-filter'),
  row_filter: RowFilter,
  action_button_filter: ButtonFilter,
  loading: Loading,
}

const Components = {
  html_container: htmlContainer,
  push_login_rec: pushLoginRec,
  su_footer: footer,
  su_header: header,
  menu: menu,
  header_title: suTitle,
  input_search: search,
  user_avatar: user,
  bookmark: ActionsSharedAndFavorite,
  shared_link: ActionsSharedAndFavorite,
  feedback_container: FeedBack,
  seller_coach_container: SellerCoach,
  button_container: CategoriesNote,
  action_button: CtaNewNotes,
  note_title: NoteTitle,
  note_subtitle: NoteSubtitle,
  list: RowContentGuie,
  share_button: ShareButton,
  presentation_container: Presentation,
  tag: Tag,
  button: ctaButton,
  float_panel_container: floatPanelCard,
  search_filter_groups_container: FilterView,
  icon_text: iconText,
  home_header_container: HomeHeader,
  float_container: CardContainer,
  card_child: CardChild,
  card: Card,
  dropdown: Dropdown,
  pagination: PaginationContainer,
  card_icon: CardIcon,
  cards_container: RecoContainer,
  section_container: SectionContainer,
  carrousel: Carrousel,
  find_container: FindContainer,
  search_filter_count: TitleAndSubTitle,
  landing: Landing,
};

const bricksMap = {
  ...ContainersUI,
  ...Components,
  ...GenericComponents,
  ...MobileUI,
};

export default bricksMap;
