/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import { getContext } from '../../helpers/selectors';
import { useStateRef } from '../../hooks/useStateRef';

const Carrousel = (props) => {
  const {
    children,
    className,
    pagination,
    arrows,
    defaultClassName,
    gap,
    events,
    performEvent,
  } = props;

  const [current, setCurrent] = useStateRef(1);
  const cards = useRef(null);
  const [translate, setTranslate] = useState({});
  const [data, setData] = useState(null);
  const [slidesPerView, setSlidesPerView] = useState(null);
  const { isMobile } = useSelector(getContext);
  const [mobile, setMobile] = useStateRef(isMobile);
  const [swap, setSwap, swapRef] = useStateRef({
    position: 0,
    moveByJs: false,
    overScrolled: false,
  });
  const [last, setLast] = useState(false);
  const [total, setTotal] = useStateRef(0);
  const initalPosition = 'translate(0)';
  const controller = useRef(null);
  let timer = null;

  const clickEvent = (e) => {
    e.preventDefault();
  };

  const mobilePositionHandler = () => {
    const { card } = data;
    const cardTotalWidth = card.width + gap;
    const cardsModule = children.length % slidesPerView;
    let principalMovement;
    let lastMovement;
    let lastCard;
    const totalScroll = cards.current.scrollWidth - cards.current.clientWidth;
    let maxScroll;
    let firstCard;
    if (pagination) {
      if (swap.direction === 'r') {
        principalMovement = slidesPerView * cardTotalWidth * (current - 1);
      } else {
        const lengthModule = cardsModule * cardTotalWidth;
        const length =
          slidesPerView *
          cardTotalWidth *
          (total - (cardsModule ? current + 1 : current));
        principalMovement = totalScroll - (length + lengthModule);
      }
      maxScroll = slidesPerView * (cardTotalWidth + gap) - gap;
      lastMovement = cards.current.scrollWidth;
      lastCard = current === total;
      firstCard = current === 1;
    } else {
      if (swap.direction === 'r') {
        principalMovement = cardTotalWidth * (current - 1) + gap;
      } else {
        const length = cardTotalWidth * (total - (current + cardsModule));
        principalMovement = totalScroll - length - gap;
      }
      maxScroll = cardTotalWidth;
      lastMovement = cards.current.scrollWidth;
      firstCard = current === 1;
      if (slidesPerView === 1) {
        lastCard = current === total;
      } else if (total % slidesPerView === 0) {
        lastCard = current === total - 1;
      } else {
        lastCard = current === total - slidesPerView + cardsModule;
      }
    }
    setLast(lastCard);
    let position;
    if (current === 1) {
      position = 0;
    } else if (lastCard) {
      position = lastMovement;
    } else {
      position = principalMovement;
    }
    setSwap((prev) => ({
      position,
      direction: prev.direction,
      moveByJs: true,
      maxScroll,
      lastCard,
    }));
    cards.current.scrollTo(position, 0);
    if (firstCard || lastCard) {
      setTimeout(() => {
        setSwap((prev) => ({ ...prev, moveByJs: false }));
      }, 400);
    }
    setTimeout(() => {
      controller.current.abort();
    }, 450);
  };

  const desktopPositionHandler = () => {
    const { card, wrapper, parent } = data;
    const cardTotalWidth = card.width + gap;
    const cardsModule = children.length % slidesPerView;
    let principalMovement;
    let lastMovement;
    let lastCard;
    if (pagination) {
      if (swap.direction === 'r') {
        principalMovement = `translate(calc(-${
          slidesPerView * cardTotalWidth * (current - 1)
        }px))`;
      } else {
        const lengthModule = cardsModule * cardTotalWidth;
        let length = slidesPerView * cardTotalWidth * (total - current - 1);
        if (slidesPerView === 1) length = slidesPerView * cardTotalWidth * (total - current);
        principalMovement = `translate(calc(-${
          wrapper.width - parent.width - (length + lengthModule)
        }px))`;
      }
      lastMovement = `translate(calc(-${wrapper.width - parent.width}px))`;
      lastCard = current === total;
    } else {
      if (swap.direction === 'r') {
        principalMovement = `translate(calc(-${
          cardTotalWidth * (current - 1)
        }px))`;
      } else {
        const length = cardTotalWidth * (total - (current + slidesPerView - 1));
        principalMovement = `translate(calc(-${
          wrapper.width - parent.width - length
        }px))`;
      }
      lastMovement = `translate(calc(-${wrapper.width - parent.width}px))`;
      if (slidesPerView === 1) {
        lastCard = current === total;
      } else if (total % slidesPerView === 0) {
        lastCard = current === total - 1;
      } else {
        lastCard = current === total - slidesPerView + cardsModule;
      }
    }
    setLast(lastCard);
    const translateMove = () => {
      if (current === 1) return initalPosition;
      if (lastCard) return lastMovement;
      return principalMovement;
    };
    setTranslate({
      transitionDuration: '1s',
      transitionTimingFunction: 'ease-in-out',
      transform: translateMove(),
    });
  };

  const scrollEvent = (e) => {
    e.preventDefault();
    if (timer !== null) {
      clearTimeout(timer);
    }

    const scroll = cards.current.scrollLeft;
    const { moveByJs, position, maxScroll } = swapRef.current;
    const goRigth = scroll > position && !moveByJs;
    const goLeft = scroll < position && !moveByJs;
    const overScrolledRight = goRigth && scroll > position + maxScroll + gap;
    const overScrolledLeft = goLeft && scroll < position - maxScroll - gap;

    cards.current.addEventListener('click', clickEvent, {
      signal: controller.current?.signal,
    });

    if (overScrolledRight || overScrolledLeft) {
      cards.current.style.overflow = 'hidden';
      setTimeout(() => {
        cards.current.style.overflow = 'scroll';
      }, 80);
    }

    timer = setTimeout(() => {
      if (goRigth || goLeft) {
        cards.current.addEventListener('scroll', clickEvent, {
          signal: controller.current.signal,
        });
        const moveTo = goRigth ? position + maxScroll : position - maxScroll;
        setSwap((prev) => ({
          ...prev,
          position: moveTo,
          direction: goRigth ? 'r' : 'l',
        }));
        setCurrent((prev) => (goRigth ? prev + 1 : prev - 1));
      }
      setSwap((prev) => ({ ...prev, moveByJs: false, overScrolled: false }));
    }, 80);
  };

  const startMobile = (e) => {
    const { moveByJs } = swapRef.current;
    if (moveByJs) {
      e.preventDefault();
    }
  };

  const defineBoundings = () => {
    const wrapper = cards.current;
    const boundings = {
      card: wrapper.childNodes[current - 1].getBoundingClientRect(),
      wrapper: wrapper.getBoundingClientRect(),
      parent: wrapper.parentNode.getBoundingClientRect(),
    };
    const screenWidth = document.body.getBoundingClientRect().width;
    if (screenWidth <= 1023) {
      setMobile(true);
    } else if (mobile && screenWidth >= 1024) {
      setMobile(false);
    }
    setData(boundings);

    const { parent, card } = boundings;
    const iterator = wrapper.childNodes.entries();
    let inView;
    while (inView === undefined) {
      const ele = iterator.next();
      if (
        ele.value[`${0}`] <= wrapper.childNodes.length - 1 &&
        ele.value[1].offsetLeft + card.width > parent.width
      ) {
        inView = ele.value[`${0}`];
        break;
      }
      if (ele.value[`${0}`] === wrapper.childNodes.length - 1) {
        inView = ele.value[`${0}`] + 1;
      }
    }
    setSlidesPerView(inView || 1);
    setTotal(
      pagination ? Math.ceil(children.length / (inView || 1)) : children.length,
    );
    setSwap((prev) => ({ ...prev, maxScroll: inView * (card.width + gap) }));
  };

  useEffect(() => {
    if (mobile && cards.current !== null) {
      controller.current = new AbortController();
      cards.current.style.overflow = 'scroll';
      cards.current.addEventListener('scroll', scrollEvent);
      cards.current.addEventListener('touchstart', startMobile, {
        passive: true,
      });
    }
    if (!mobile && cards.current !== null) {
      cards.current.removeEventListener('scroll', scrollEvent);
      cards.current.removeEventListener('touchstart', startMobile);
      cards.current.style.overflow = 'visible';
    }
  }, [mobile]);

  useEffect(() => {
    if (cards.current !== null) {
      defineBoundings();
      window.addEventListener('resize', defineBoundings);
    }
    return () => {
      window.removeEventListener('resize', defineBoundings);
    };
  }, [cards]);

  useEffect(() => {
    if (data) {
      if (!mobile) {
        desktopPositionHandler();
      } else {
        mobilePositionHandler();
      }
    }
  }, [current]);

  useEffect(() => {
    if (events) {
      events.forEach((event) => {
        performEvent(event);
      });
    }
  }, []);

  const showArrows = () => {
    if (arrows && pagination) {
      return children.length > slidesPerView;
    }
    return arrows;
  };

  return (
    <div className={`${className} ${defaultClassName}`}>
      {showArrows() && current !== 1 && (
        <button
          disabled={current === 1}
          onClick={() => {
            setCurrent((prev) => prev - 1);
            setSwap((prev) => ({ ...prev, direction: 'l' }));
          }}
          className={`sc-carrousel-btns-left${
            current === 1 ? '-disabled' : ''
          }`}
          aria-label="icon"
        >
          <Icon type="ChevronLeft16" />
        </button>
      )}
      <div className="sc-carrousel-wrapper">
        <div
          ref={cards}
          className="sc-carrousel-cards"
          style={{ ...translate, gap }}
        >
          {children}
        </div>
      </div>
      {showArrows() && current !== total && (
        <button
          disabled={current === children.length || last}
          onClick={() => {
            setCurrent((prev) => prev + 1);
            setSwap((prev) => ({ ...prev, direction: 'r' }));
          }}
          className={`sc-carrousel-btns-right${
            current === children.length || last ? '-disabled' : ''
          }`}
          aria-label="icon"
        >
          <Icon type="ChevronRight16" />
        </button>
      )}
      {pagination && slidesPerView && slidesPerView < children.length && (
        <Icon
          {...pagination}
          current={current}
          total={total}
          handleCircle={(n) => {
            setCurrent(n + 1);
          }}
        />
      )}
    </div>
  );
};

Carrousel.defaultProps = {
  defaultClassName: 'sc-carrousel',
  className: '',
  gap: 16,
};

Carrousel.propTypes = {
  defaultClassName: PropTypes.string,
  className: PropTypes.string,
  pagination: PropTypes.shape({
    type: PropTypes.string,
  }),
  events: PropTypes.arrayOf(PropTypes.shape({})),
  performEvent: PropTypes.func,
  gap: PropTypes.number,
  arrows: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(), PropTypes.node]),
  icon: PropTypes.string,
};

export default Carrousel;
