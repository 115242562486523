import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Tooltip from '@andes/tooltip';
import { Title } from '@andes/typography';
import Button from '@andes/button';

import Icon from '../Icon';
import ListSites from '../ListSites';
import { getContext } from '../../helpers/selectors';
import Modal from '../Modal';

const Dialog = ({ title, onClickClose, data }) => (
  <div role="dialog" aria-label={title} className="sc-global-tooltip-dialog">
    <Button hierarchy="transparent" onClick={onClickClose}>
      <Icon type="Close24" />
    </Button>
    <div className="sc-global-tooltip-dialog-content">
      <Title component="p" size="s">{title}</Title>
      <ListSites data={data} />
    </div>
  </div>
);

Dialog.propTypes = {
  title: PropTypes.string,
  onClickClose: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

const GlobalTooltip = (props) => {
  const {
    id,
    defaultClassName,
    className,
    title,
    data,
    side,
    type,
    offsetX,
    icon,
    offsetY,
  } = props;

  const { device } = useSelector(getContext);
  const [isMobile, setIsMobile] = useState(device?.isMobile);
  const [open, setOpen] = useState(false);

  if (!data || data.length === 0) {
    return (
      <div
        aria-label={title}
        className={`${defaultClassName} just-icon ${className}`.trim()}
      >
        <Icon type={icon} containerClassName={[`${defaultClassName}-icon`]} />
      </div>
    );
  }

  useEffect(() => {
    if (
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    ) {
      setIsMobile(true);
    }
  }, []);

  const handleModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen((prev) => !prev);
    return false;
  };

  if (isMobile) {
    return (
      <div
        aria-label={title}
        className={`${defaultClassName} tooltip-mobile ${className}`.trim()}
      >
        <button
          className={`${defaultClassName}-button`}
          onKeyUp={(e) => {
            if (e.key === 'Enter') handleModal(e);
          }}
          onClick={handleModal}
          aria-label="icon"
        >
          <Icon type={icon} containerClassName={[`${defaultClassName}-icon tooltip-mobile`]} />
        </button>
        <Modal
          open={open}
          className={`${defaultClassName}-modal tooltip-mobile`}
          content={<Dialog title={title} data={data} onClickClose={handleModal} />}
        />
      </div>
    );
  }

  return (
    <div className={`${defaultClassName} ${className}`.trim()}>
      <Tooltip
        side={side}
        offsetX={offsetX || 0}
        offsetY={offsetY || 0}
        id={id}
        trigger="hover"
        type={type}
        title={title}
        content={<ListSites data={data} />}
      >
        <Icon type={icon} containerClassName={[`${defaultClassName}-icon`]} />
      </Tooltip>
    </div>
  );
};

GlobalTooltip.defaultProps = {
  id: 'tooltip-id',
  defaultClassName: 'sc-global-tooltip',
  className: '',
  side: 'bottom',
  type: 'light',
};

GlobalTooltip.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  offsetX: PropTypes.number,
  icon: PropTypes.string,
  type: PropTypes.string,
  offsetY: PropTypes.number,
  title: PropTypes.string,
  defaultClassName: PropTypes.string,
  side: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default GlobalTooltip;
