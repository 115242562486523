/* eslint-disable max-len */
const React = require('react');
const PropTypes = require('prop-types');

const Flag32 = ({ color }) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <g clipPath="url(#a)" stroke={color} strokeWidth="1.92" strokeLinecap="round" strokeLinejoin="round">
      <path d="M9.555 6.264v21.394m14.811-9.874H9.555V6.264h14.811l-4.937 5.76 4.937 5.76Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M5.441 5.44h23.04v23.04H5.441z" />
      </clipPath>
    </defs>
  </svg>
);

Flag32.defaultProps = {
  color: '#3483FA',
};

Flag32.propTypes = {
  color: PropTypes.string,
};

module.exports = Flag32;
