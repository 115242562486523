/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const Wrapper = () => (props) => {
  const { children, performEvent, maxAdvance, parentNoteId, advance, parentGuides, parentModules } = props;

  performEvent({
    type: 'update_storage',
    data: {
      mode: 'merge',
      storage_data: {
        noteData: {
          maxAdvance,
          parentNoteId,
          advance,
          parentGuides,
          parentModules,
        },
      },
    },
  });

  return <>{children}</>;
};

Wrapper.propTypes = {
  noteId: PropTypes.number,
  maxAdvance: PropTypes.number,
  advance: PropTypes.number,
  parentNoteId: PropTypes.string,
  performEvent: PropTypes.func,
};

export default Wrapper;
