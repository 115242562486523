import { redirect } from './redirect';
import { back } from './back';
import { login } from './login';
import { request } from './request';
import { updateCompleteBricks } from './updateCompleteBricks';
import { nativeSubscribeEvent } from './nativeSubscribeEvent';
import { nativeUnsubscribeEvent } from './nativeUnsubscribeEvent';
import { nativeRedirect } from './nativeRedirect';
import { nativeDispatchEvent } from './nativeDispatchEvent';
import { trackingEvent } from './track';

export default {
  redirect,
  back,
  login,
  updateCompleteBricks,
  request,
  nativeSubscribeEvent,
  nativeUnsubscribeEvent,
  nativeRedirect,
  nativeDispatchEvent,
  trackingEvent,
};
