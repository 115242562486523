import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MlIcon } from './lib';
import { getContext } from '../../helpers/selectors';
import { checkSuScope } from '../../helpers/checkSuScope';

const SuTitle = (props) => {
  const { href, title, className, performEvent } = props;
  const [screenSize, setScreenSize] = useState(1020);
  const { isMobile, platform, suScope, device: { webView } } = useSelector(getContext);

  useEffect(() => {
    setScreenSize(window.innerWidth);
    window.addEventListener('resize', (e) => {
      setScreenSize(e.target.innerWidth);
    });
  }, []);

  const webViewEvent = [
    {
      type: 'sc_deeplink',
      data: {
        url: href,
      },
    },
  ];

  const onClickEvent = () => {
    if (performEvent) {
      webViewEvent.forEach(event => performEvent(event));
    }
  };

  const renderSuTitleCBT = () => (
    <span className={`${className}-cbt`}>{title}</span>
  );

  const renderSuTitle = () => (
    <>
      <MlIcon />
      <span>{title}</span>
    </>
  );

  return (
    <a
      href={checkSuScope(href, suScope)}
      className={className}
      tabIndex="0"
      onClick={webView ? () => onClickEvent() : null}
    >
      {isMobile || screenSize < 768 ? (
        <MlIcon />
      ) : (
        <>
          <div className={`${className}-title`}>
            {platform?.siteId === 'CBT' ? renderSuTitleCBT() : renderSuTitle()}
          </div>
        </>
      )}
    </a>
  );
};

SuTitle.defaultProps = {
  className: 'nav-logo',
};

SuTitle.propTypes = {
  href: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  performEvent: PropTypes.func,
};

export default SuTitle;
