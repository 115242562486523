/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getContext } from '../../helpers/selectors';
import { getParams, useQueryParams } from '../../hooks/useQueryParams';

const React = require('react');
const PropTypes = require('prop-types');
const AndesButton = require('@andes/button');
const { useState } = require('react');
const { isEqual } = require('lodash');

const ButtonFilter = (props) => {
  const {
    id,
    className,
    modifier,
    text,
    disabled,
    events,
    storageValue,
    performEvent,
  } = props;
  const {
    device: { webView },
    platform,
  } = useSelector(getContext);
  const [disable, setDisable] = useState(true);
  const { getQuery, updateQuery, deleteAllQuery } = useQueryParams();
  const isClearFilterButton = id === 'clear_filter_button' || '';

  useEffect(() => {}, [disable]);
  // obtengo las entradas en storageValue correspondiente a filters, ignorando la correspondiente a selected
  const getFilterEntries = () => Object.entries(storageValue).filter((item) => {
    const [itemId] = item;
    return itemId !== 'selected';
  });

  const generateFilterParams = () => {
    const sort = storageValue?.row_filter_sort?.current;
    const q = getQuery('q');
    const filters = getFilterEntries()
      .reduce((prev, item) => {
        const [itemId, itemValue] = item;
        const value = itemValue.current;
        if (itemId === 'row_filter_sort' || !value) {
          return prev;
        }
        const valueArray = Array.isArray(value) ? value : [value];
        return [...prev, ...valueArray];
      }, [])
      .join('|');
    return {
      sort,
      filters,
      q,
    };
  };

  const isApplyButtonDisable = () => getFilterEntries().every((item) => {
    const [itemId, itemValue] = item;
    return isEqual(itemValue.initial, itemValue.current);
  });

  const isClearButtonDisable = () => disabled;

  React.useEffect(() => {
    setDisable(
      isClearFilterButton ? isClearButtonDisable() : isApplyButtonDisable(),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageValue]);

  const handleEvent = (paramsUrl) => {
    if (performEvent) {
      let path = '';
      if (platform.siteId === 'CBT') path = '/learning-center';
      const requestBrick = [
        {
          type: 'update_bricks',
          data: {
            bricks: [
              {
                id: 'loading',
                uiType: 'loading',
                data: {
                  loadingStatus: true,
                },
              },
            ],
          },
        },
        {
          type: 'request',
          data: {
            method: 'GET',
            path: `/university/search${paramsUrl}`,
          },
        },
        {
          type: 'redirect',
          data: {
            url: `${window.location.origin}${path}/search${paramsUrl}`,
          },
        },
      ];
      requestBrick.forEach((event) => performEvent(event));
    }
  };

  const handleApplyFilters = () => {
    const filterParams = generateFilterParams();
    const { filters, sort } = filterParams;

    if (webView) {
      const updateListEvent = {
        type: 'dispatch_event',
        data: {
          topic: 'update_list_filters',
          msg: filterParams,
        },
      };
      if (performEvent) {
        [...events, updateListEvent].forEach((event) => performEvent(event));
      }
    } else {
      const params = [];
      if (filters) params.push({ key: 'filters', value: filters });
      if (sort) params.push({ key: 'sort', value: sort });
      if (params.length > 0) updateQuery(params);
      const respQuery = getParams();

      handleEvent(respQuery);
    }
  };

  const handleClearFilters = () => {
    if (webView) {
      const emptyFilterParams = {
        filters: '',
        sorts: '',
      };
      const updateListFiltersEvent = {
        type: 'dispatch_event',
        data: {
          topic: 'update_list_filters',
          msg: emptyFilterParams,
        },
      };

      if (performEvent) {
        [updateListFiltersEvent, ...events].forEach((event) => performEvent(event));
      }
    } else {
      deleteAllQuery();
      if (events && performEvent) {
        events.forEach((event) => performEvent(event));
      }
    }
  };

  return (
    <div className={className}>
      <AndesButton
        className={id}
        hierarchy={modifier}
        disabled={disable}
        onClick={isClearFilterButton ? handleClearFilters : handleApplyFilters}
      >
        {text}
      </AndesButton>
    </div>
  );
};

ButtonFilter.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  text: PropTypes.string,
  modifier: PropTypes.string,
  disabled: PropTypes.bool,
  events: PropTypes.arrayOf(PropTypes.shape()),
  storageValue: PropTypes.shape({
    row_filter_sort: PropTypes.shape({
      current: PropTypes.string,
    }),
  }),
  performEvent: PropTypes.func,
};

ButtonFilter.defaultProps = {
  className: 'sc-button-filter',
  modifier: null,
  text: null,
  disabled: false,
  events: [],
  storageValue: '',
  performEvent: null,
};

export default ButtonFilter;
