import { useRef, useState, useEffect } from 'react';

export const useScrollBottom = () => {
  const scrollBottomRef = useRef(null);
  const [scrollBottomState, setScrollBottomState] = useState(NaN);

  const watchScroll = () => {
    if (typeof window !== 'undefined') {
      const intViewportHeight = window.innerHeight;
      const scrollHeight = window.pageYOffset;
      const pageHeight = document.body.clientHeight;
      const bottom = scrollHeight >= pageHeight - intViewportHeight - 350;
      setScrollBottomState(bottom);
    }
  };

  useEffect(() => {
    const node = scrollBottomRef.current;
    if (node !== null) {
      window.addEventListener('scroll', watchScroll, { passive: true });
    }
    return () => {
      if (node !== null) {
        window.removeEventListener('scroll', watchScroll, { passive: true });
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollBottomRef]);

  useEffect(() => {
    watchScroll();
  }, []);

  return [scrollBottomRef, scrollBottomState];
};
