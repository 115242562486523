/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Text, Title } from '@andes/typography';
import { getContext } from '../../helpers/selectors';

const TitleAndSubTitle = (props) => {
  const {
    title,
    titleSize,
    titleColor,
    subTitle,
    subTitleSize,
    subTitleColor,
    className,
    id,
    events,
    performEvent,
  } = props;
  const filterCount = id === 'search_filter_count';
  const { isMobile } = useSelector(getContext);
  const [count, setCount] = useState(isMobile ? 1 : 0);

  const getQuery = () => {
    const url = new URL(window.location.href);
    return url.searchParams.get('q');
  };

  useEffect(() => {
    if (events && getQuery() !== null && count === 1) {
      events.forEach((e) => {
        performEvent(e);
      });
      setCount(2);
    }
    if (count === 0) setCount(1);
  }, [events]);

  useEffect(() => {
    if (events && getQuery() !== null && count === 2) {
      events.forEach((e) => {
        performEvent(e);
      });
    }
  }, [title]);

  const renderTitleSubtitle = (
    <>
      {title && (
        <Title
          className={`${className}-title`}
          component="h1"
          size={titleSize}
          color={titleColor}
        >
          {title.charAt(0)?.toUpperCase() + title.slice(1)}
        </Title>
      )}
      {subTitle && (
        <Text
          className={`${className}-subtitle`}
          component="p"
          size={subTitleSize}
          color={subTitleColor}
        >
          {subTitle}
        </Text>
      )}
    </>
  );

  return filterCount ? (
    <div className="sc-filter-results">{renderTitleSubtitle}</div>
  ) : (
    renderTitleSubtitle
  );
};

TitleAndSubTitle.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  events: PropTypes.arrayOf(PropTypes.shape({})),
  performEvent: PropTypes.func,
  titleSize: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  titleColor: PropTypes.oneOf([
    'primary',
    'secondary',
    'disabled',
    'inverted',
    'link',
    'negative',
    'caution',
    'positive',
  ]),
  subTitle: PropTypes.string,
  subTitleSize: PropTypes.oneOf(['xs', 's', 'm', 'l']),
  subTitleColor: PropTypes.oneOf([
    'primary',
    'secondary',
    'disabled',
    'inverted',
    'link',
    'negative',
    'caution',
    'positive',
  ]),
};

TitleAndSubTitle.defaultProps = {
  className: 'sc-title-and-subtitle',
};

export default TitleAndSubTitle;
