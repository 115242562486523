/* eslint-disable max-len */
const React = require('react');
const PropTypes = require('prop-types');

const Book20 = ({ color }) => (
  <svg fill={color} xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20">
    <circle cx="10" cy="10" r="10" />
  </svg>
);

Book20.defaultProps = {
  color: '#3483FA',
};

Book20.propTypes = {
  color: PropTypes.string,
};

module.exports = Book20;
