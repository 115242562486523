/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
const React = require('react');
const PropTypes = require('prop-types');

const LikeIcon = ({ color }) => (
  <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" strokeWidth="0" clipRule="evenodd" d="M6.745 8.16234L9.07039 6.92334L13.5136 0.551758L15.3814 1.43314C16.8513 2.12677 17.5521 3.82491 16.9993 5.35336L16.1322 7.75038H20.308C20.7645 7.75038 21.2154 7.84956 21.6297 8.04106C23.2089 8.771 23.8973 10.6429 23.1674 12.2221L20.4796 18.0368C19.431 20.3052 16.9957 21.5931 14.5306 21.1829L10.4994 20.512H6.745V21.7504H0.75V6.75038H6.745V8.16234ZM10.0943 8.07741L14.0192 2.44899L14.7412 2.78969C15.5112 3.15302 15.8783 4.04252 15.5887 4.84314L13.9946 9.25038H20.308C20.5471 9.25038 20.7833 9.30233 21.0004 9.40264C21.8275 9.78499 22.1881 10.7655 21.8058 11.5927L19.118 17.4075C18.3528 19.0628 16.5757 20.0026 14.7769 19.7032L10.6845 19.0222L10.5614 19.012H6.745V9.86198L10.0943 8.07741ZM2.25 8.25038V20.2504H5.245V8.25038H2.25Z" style={{ fill: `${color} !important` }} fillOpacity="0.9" />
  </svg>
);

LikeIcon.defaultProps = {
  color: '#000000',
};

LikeIcon.propTypes = {
  color: PropTypes.string,
};

module.exports = LikeIcon;
