/* eslint-disable react/prop-types */
import React from 'react';
import ContentsModule from '../../components/ContentsModule';
import PushLogin from '../../components/PushLogin';

const RightContent = props => {
  const { push, contentsModule, className } = props;
  return (
    <div className={className}>
      <ContentsModule {...contentsModule} />
      { push && <PushLogin {...push} />}
    </div>
  );
};

export default RightContent;
