import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@andes/typography';
import { useSelector } from 'react-redux';
import { handlerLogin } from '../../helpers/actionsAuth';
import { getContext } from '../../helpers/selectors';

const Footer = props => {
  const { origin, text, copyright, navigations } = props;
  const { auth, platform, fullUrl } = useSelector(getContext);

  if (!origin) {
    return (
      <footer className="guide-footer">
        <div className="guide-footer-login">
          {!auth ? (
            <Text component="a" color="link" href={handlerLogin(platform.siteId, fullUrl)}>
              {text}
            </Text>
          ) : (
            <Text component="p">{text}</Text>
          )}
        </div>
        <div className="guide-footer-item">
          <p>{copyright}</p>
        </div>
        {navigations.map((anchor, index) => (
          <div className={`guide-footer-item item${index}`}>
            <Text component="a" color="link" href={anchor?.href}>
              {anchor?.title}
            </Text>
          </div>
        ))}
      </footer>
    );
  }

  return (
    <footer className="footer">
      <div className="footer-item">
        <p>{copyright}</p>
        <div className="anchor-container">
          {navigations.map(anchor => (
            <Text component="a" color="link" href={anchor?.href}>
              {anchor?.title}
            </Text>
          ))}
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  origin: PropTypes.string,
  text: PropTypes.string,
  copyright: PropTypes.string,
  navigations: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Footer;
