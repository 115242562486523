const fromEntries = (iterable) => [...iterable].reduce((obj, [key, val]) => {
  obj[`${key}`] = val;
  return obj;
}, {});

// Used to change case from 'storageKey' to 'storage_key'
const replaceKey = (f) => (o) => {
  if (Array.isArray(o)) {
    return o.map(replaceKey(f));
  }
  if (Object(o) === o) {
    return fromEntries(Object.entries(o).map(([k, v]) => [f(k), replaceKey(f)(v)]));
  }
  return o;
};

export const replaceKeysInObj = (oldKey, newKey) => replaceKey((k) => (k === oldKey ? newKey : k));
