/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Portal } from '@andes/common';

import { isConnected } from '../../helpers/mobileWebkitMethodsV2';

const Modal = (props) => {
  const { className, open, children, container, onClose, content } = props;
  const [isOpen, setIsOpen] = useState(open);

  const handlerWebViewClose = async () => {
    const connected = await isConnected();
    if (connected) {
      const subscriptionId = MobileWebKit.lifecycle.onViewDisappeared(() => {
        setIsOpen(false);
        onClose();
        MobileWebKit.lifecycle.unsubscribe(subscriptionId);
      });
    }
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    handlerWebViewClose();
  }, []);

  return (
    <div
      className={`sc-modal-container sc-modal-container__${
        isOpen ? 'open' : 'close'
      } ${className}`.trim()}
      aria-hidden={!isOpen}
    >
      <div className={`${className}-content`}>{children}</div>
      {isOpen && (
        <Portal
          className={`sc-modal-container__overlay ${className}`.trim()}
          container={container}
        >
          {content}
        </Portal>
      )}
    </div>
  );
};

Modal.defaultProps = {
  className: '',
  container: undefined,
  onClose: () => {},
  content: <div />,
};

Modal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
  container: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
};

export default Modal;
