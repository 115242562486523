import React from 'react';
import { useSelector } from 'react-redux';
import Head from 'nordic/head';
import PropTypes from 'prop-types';
import { getContext } from '../../helpers/selectors';
import { replaceSingleQuote } from '../../helpers/replaceSingleQuote';

const MetaDataNote = props => {
  const { title, subtitle, href } = props;
  const { fullUrl } = useSelector(getContext);

  const cleanTitle = replaceSingleQuote(title);
  const cleanSubtitle = replaceSingleQuote(subtitle);

  return (
    <Head>
      <meta name="description" content={cleanSubtitle} />
      <meta name="author" content="Open Platform Mercado Libre" />
      <meta itemProp="name" content={cleanTitle} />
      <meta itemProp="description" content={cleanSubtitle} />
      <meta
        itemProp="image"
        content="https://http2.mlstatic.com/storage/developers-site-cms-admin/DevSite/326111443501-cover-devsite.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@melidevelopers" />
      <meta name="twitter:title" content={cleanTitle} />
      <meta name="twitter:description" content={cleanSubtitle} />
      <meta
        name="twitter:image"
        content="https://http2.mlstatic.com/storage/developers-site-cms-admin/DevSite/326111443501-cover-devsite.jpg"
      />
      <meta property="og:title" content={cleanTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://mercadolibre.com" />
      <meta
        property="og:image:secure_url"
        content="https://http2.mlstatic.com/storage/developers-site-cms-admin/DevSite/326111443501-cover-devsite.jpg"
      />
      <meta property="og:description" content={cleanSubtitle} />
      <meta property="og:site_name" content={cleanTitle} />
      <link rel="canonical" href={href || fullUrl} />
    </Head>
  );
};

MetaDataNote.defaultProps = {
  title: '',
  subtitle: '',
  href: '',
};

MetaDataNote.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  href: PropTypes.string,
};

export default MetaDataNote;
