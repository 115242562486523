import React from 'react';

import PropTypes from 'prop-types';
import TitleAndSubTitle from '../TitleAndSubTitle';
import Button from '../CtaButton';
import Fallback from '../Fallback';
import GlobalTooltip from '../GlobalTooltip';
import Tag from '../Tag';

const { Image } = require('nordic/image');

const RecoContainer = (props) => {
  const {
    title,
    subTitle,
    children,
    className,
    button,
    tag,
    image,
    imageAlign,
    fallback,
    globalTooltip,
  } = props;

  if (fallback) {
    return <Fallback {...fallback} className={`${className}-fallback`} />;
  }

  const titleAndSubTitle = () => (
    <TitleAndSubTitle className={className} title={title} subTitle={subTitle} />
  );

  return (
    <div className={`${className} ${imageAlign}`}>
      {image && (
        <Image
          className={`${className}-image`}
          alt={image.alt}
          src={image.href}
        />
      )}
      <div
        className={`${className}-${imageAlign || 'content'} ${
          globalTooltip ? 'with-tooltip' : ''
        }`.trim()}
      >
        {tag ? (
          <div className={`${className}-tag-title`}>
            <Tag {...tag} />
            {titleAndSubTitle()}
            {globalTooltip && <GlobalTooltip {...globalTooltip} />}
          </div>
        ) : (
          <>
            {titleAndSubTitle()}
            {globalTooltip && <GlobalTooltip {...globalTooltip} />}
          </>
        )}
        {children && <div className={`${className}-cards`}>{children}</div>}
        {button && <Button {...button} />}
      </div>
    </div>
  );
};

RecoContainer.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  fallback: PropTypes.shape(),
  button: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      secondIcon: PropTypes.string,
      action: PropTypes.oneOfType([PropTypes.arrayOf(), PropTypes.node]),
      type: PropTypes.string,
    }),
  ),
  children: PropTypes.oneOfType([PropTypes.arrayOf(), PropTypes.node]),
  imageAlign: PropTypes.oneOf(['left', 'right']),
  image: PropTypes.string,
  tag: PropTypes.shape({}),
  globalTooltip: PropTypes.shape({
    className: PropTypes.string,
    title: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};
RecoContainer.defaultProps = {
  className: 'sc-reco-container',
  imageAlign: '',
  fallback: undefined,
};

export default RecoContainer;
