/* eslint-disable max-len */
const React = require('react');

const IconMLC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_9082_285163)">
      <path
        d="M0.5 7.99976C0.5 12.1498 3.85 15.4998 8 15.4998C12.15 15.4998 15.5 12.1498 15.5 7.99976H0.5Z"
        fill="#ED4C5C"
      />
      <path
        d="M8 0.499756C12.15 0.499756 15.5 3.84976 15.5 7.99976H8V0.499756Z"
        fill="#F9F9F9"
      />
      <path
        d="M8 0.499756C3.85 0.499756 0.5 3.84976 0.5 7.99976H8V0.499756Z"
        fill="#2A5F9E"
      />
      <path
        d="M5 5.42476L6.225 6.24976L5.775 4.92476L7 4.04976H5.475L5 2.74976L4.55 4.04976H3L4.225 4.92476L3.775 6.24976L5 5.42476Z"
        fill="#F9F9F9"
      />
    </g>
    <defs>
      <clipPath id="clip0_9082_285163">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 -0.000244141)"
        />
      </clipPath>
    </defs>
  </svg>
);

module.exports = IconMLC;
