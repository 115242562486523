const React = require('react');
const PropTypes = require('prop-types');
const colorIcons = require('../common/color');

const ProgressLinear8 = (props) => {
  const { total, current, color, selectedColor, handleCircle } = props;
  const width = 9 * total - 3;
  const circles = new Array(total).fill().map((e, i) => i);
  return (
    <svg
      width={width}
      height="6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width} 6`}
    >
      {circles.map((n) => (
        <circle
          role={handleCircle ? 'button' : undefined}
          onClick={() => {
            handleCircle(n);
          }}
          key={`progress-bar-${n}`}
          cx={`${3 + 9 * n}px`}
          cy="50%"
          r="3"
          fill={current - 1 === n ? selectedColor : color}
        />
      ))}
    </svg>
  );
};

ProgressLinear8.defaultProps = {
  total: 2,
  current: 1,
  color: colorIcons.graySolid250,
  selectedColor: colorIcons.gray550,
};

ProgressLinear8.propTypes = {
  total: PropTypes.number,
  current: PropTypes.number,
  color: PropTypes.string,
  selectedColor: PropTypes.string,
  handleCircle: PropTypes.func,
};

module.exports = ProgressLinear8;
