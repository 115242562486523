export function useQueryParams() {
  const getQuery = key => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(key);
  };

  const updateQuery = query => {
    const currentUrl = new URL(window.location.href);
    if (!Array.isArray(query)) {
      currentUrl.searchParams.set(query.key, query.value);
    } else if (query.length > 0) {
      query.forEach(q => {
        currentUrl.searchParams.set(q.key, q.value);
      });
    }
    const url = currentUrl.toString();
    const [path, newP] = url.split('?');
    const withoutDuplicates = `${path}?${new URLSearchParams(
      Object.fromEntries(new URLSearchParams(newP)),
    ).toString()}`;
    window.history.pushState({}, '', withoutDuplicates);
  };

  const deleteAllQuery = () => {
    window.history.pushState({}, '', window.location.origin + window.location.pathname);
  };

  const deleteQuery = key => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete(key);
    window.history.pushState({}, '', currentUrl.toString());
  };

  return {
    getQuery,
    updateQuery,
    deleteAllQuery,
    deleteQuery,
  };
}

export function getParams() {
  const pathSafe = ['suScope', 'sort', 'filters', 'q'];
  const pathSafeNum = ['offset', 'limit', 'page'];
  const regexNumber = /^[0-9]*$/;
  const regexString = /[^a-zÀ-ÿÑ0-9\s|]+/gi;
  const arraySafe = [];
  const clearPath = new URLSearchParams(Object.fromEntries(new URLSearchParams(window?.location?.search)));
  clearPath?.forEach((value, key) => {
    if (pathSafeNum.includes(key) && regexNumber.test(value)) {
      arraySafe.push(`${key}=${value}`);
    }
    if (pathSafe.includes(key)) {
      arraySafe.push(`${key}=${value.replaceAll(regexString, '')}`);
    }
  });
  return encodeURI(`?${arraySafe?.join('&')}`);
}
