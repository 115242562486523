export const nativeSubscribeEvent = async ({
  eventData,
  performEvent,
}) => {
  const {
    topic,
    eventMethod = 'close',
  } = eventData;

  const subscriptionId = MobileWebKit.events.subscribe(topic, (event) => {
    if (eventMethod === 'close') {
      MobileWebKit.events.unsubscribe(subscriptionId);
      MobileWebKit.navigation.pop({ animated: false });
    }
    if (eventMethod === 'event_arrived') {
      MobileWebKit.events.unsubscribe(subscriptionId);
      const {
        q,
        sort,
        filters,
      } = event.args.msg;
      const url = new URL(`${window.location.origin}${window.location.pathname}?limit=18&offset=0`);
      if (q) url.searchParams.append('q', q);
      if (sort) url.searchParams.append('sort', sort);
      if (filters) url.searchParams.append('filters', filters);
      const newUrl = `meli://webview/?url=${encodeURIComponent(
        url.toString(),
      )}&webkit-engine=2`;
      const options = {
        replace: true,
        transition: 'push',
      };
      MobileWebKit.navigation.push(newUrl, options);
      const loadingEvent = {
        id: 'loading',
        uiType: 'loading',
        data: {
          loadingStatus: true,
          modifier: 'fullscreen',
          size: 'large',
        },
      };
      [loadingEvent].forEach(e => {
        performEvent(e);
      });
    }
  });
};
