/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
const React = require('react');

const UserLogged = () => (
  <svg width="32px" height="32px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="currentColor">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.501 12.25C23.501 16.3932 20.1422 19.752 15.999 19.752C11.8558 19.752 8.497 16.3932 8.497 12.25C8.497 8.10676 11.8558 4.748 15.999 4.748C20.1422 4.748 23.501 8.10676 23.501 12.25ZM9.997 12.25C9.997 15.5648 12.6842 18.252 15.999 18.252C19.3138 18.252 22.001 15.5648 22.001 12.25C22.001 8.93519 19.3138 6.248 15.999 6.248C12.6842 6.248 9.997 8.93519 9.997 12.25Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 31.75C24.6985 31.75 31.75 24.6985 31.75 16C31.75 7.30152 24.6985 0.25 16 0.25C7.30152 0.25 0.25 7.30152 0.25 16C0.25 24.6985 7.30152 31.75 16 31.75ZM4.83019 24.8495C2.90175 22.4187 1.75 19.3439 1.75 16C1.75 8.12994 8.12994 1.75 16 1.75C23.8701 1.75 30.25 8.12994 30.25 16C30.25 19.3448 29.0976 22.4204 27.1682 24.8515C26.7721 22.7997 24.9665 21.25 22.799 21.25H9.199C7.03219 21.25 5.2271 22.7987 4.83019 24.8495ZM6.249 26.3914C8.79806 28.7843 12.2279 30.25 16 30.25C19.7711 30.25 23.2001 28.7851 25.749 26.3933V25.7C25.749 24.0708 24.4282 22.75 22.799 22.75H9.199C7.56976 22.75 6.249 24.0708 6.249 25.7V26.3914Z"
      fill="currentColor"
    />
  </svg>
);

module.exports = UserLogged;
