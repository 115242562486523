
const Add16 = require('@andes/icons/Add16');
const Add20 = require('@andes/icons/Add20');
const Add24 = require('@andes/icons/Add24');

const Answer16 = require('@andes/icons/Answer16');
const Answer20 = require('@andes/icons/Answer20');
const Answer24 = require('@andes/icons/Answer24');

const ArrowDown16 = require('@andes/icons/ArrowDown16');
const ArrowDown20 = require('@andes/icons/ArrowDown20');
const ArrowDown24 = require('@andes/icons/ArrowDown24');
const ArrowDown32 = require('@andes/icons/ArrowDown32');
const ArrowLeft16 = require('@andes/icons/ArrowLeft16');
const ArrowLeft20 = require('@andes/icons/ArrowLeft20');
const ArrowLeft24 = require('@andes/icons/ArrowLeft24');
const ArrowLeft32 = require('@andes/icons/ArrowLeft32');
const ArrowRight16 = require('@andes/icons/ArrowRight16');
const ArrowRight20 = require('@andes/icons/ArrowRight20');
const ArrowRight24 = require('@andes/icons/ArrowRight24');
const ArrowRight32 = require('@andes/icons/ArrowRight32');
const ArrowUp16 = require('@andes/icons/ArrowUp16');
const ArrowUp20 = require('@andes/icons/ArrowUp20');
const ArrowUp24 = require('@andes/icons/ArrowUp24');
const ArrowUp32 = require('@andes/icons/ArrowUp32');

const Atention16 = require('@andes/icons/Atention16');
const Atention20 = require('@andes/icons/Atention20');
const Atention24 = require('@andes/icons/Atention24');

const Attach16 = require('@andes/icons/Attach16');
const Attach20 = require('@andes/icons/Attach20');
const Attach24 = require('@andes/icons/Attach24');

const Avatar16 = require('@andes/icons/Avatar16');
const Avatar20 = require('@andes/icons/Avatar20');
const Avatar24 = require('@andes/icons/Avatar24');
const Avatar32 = require('@andes/icons/Avatar32');
const Avatar48 = require('@andes/icons/Avatar48');

const BTC48 = require('@andes/icons/BTC48');

const BankTransfer16 = require('@andes/icons/BankTransfer16');
const BankTransfer24 = require('@andes/icons/BankTransfer24');
const BankTransfer32 = require('@andes/icons/BankTransfer32');
const BankTransfer48 = require('@andes/icons/BankTransfer48');
const Bill16 = require('@andes/icons/Bill16');
const Bill20 = require('@andes/icons/Bill20');
const Bill24 = require('@andes/icons/Bill24');
const Bill32 = require('@andes/icons/Bill32');
const Bill48 = require('@andes/icons/Bill48');
const Boleto16 = require('@andes/icons/Boleto16');
const Boleto20 = require('@andes/icons/Boleto20');
const Boleto24 = require('@andes/icons/Boleto24');
const Boleto32 = require('@andes/icons/Boleto32');
const Boleto48 = require('@andes/icons/Boleto48');

const BookmarkFilled16 = require('@andes/icons/BookmarkFilled16');
const BookmarkFilled20 = require('@andes/icons/BookmarkOutlined20');
const BookmarkFilled24 = require('@andes/icons/BookmarkOutlined24');

const BookmarkOutlined16 = require('@andes/icons/BookmarkOutlined16');
const BookmarkOutlined20 = require('@andes/icons/BookmarkOutlined20');
const BookmarkOutlined24 = require('@andes/icons/BookmarkOutlined24');

const CBT16 = require('@andes/icons/CBT16');
const CBT20 = require('@andes/icons/CBT20');
const CBT24 = require('@andes/icons/CBT24');
const CBT32 = require('@andes/icons/CBT32');
const CBT48 = require('@andes/icons/CBT48');

const Calendar16 = require('@andes/icons/Calendar16');
const Calendar20 = require('@andes/icons/Calendar20');
const Calendar24 = require('@andes/icons/Calendar24');

const Camera16 = require('@andes/icons/Camera16');
const Camera20 = require('@andes/icons/Camera20');
const Camera24 = require('@andes/icons/Camera24');

const CardAdd24 = require('@andes/icons/CartAdd24');

const CartEmpty16 = require('@andes/icons/CartEmpty16');
const CartEmpty20 = require('@andes/icons/CartEmpty20');
const CartEmpty24 = require('@andes/icons/CartEmpty24');
const CartEmpty32 = require('@andes/icons/CartEmpty32');
const CartEmpty48 = require('@andes/icons/CartEmpty48');
const CartFilled124 = require('@andes/icons/CartFilled124');
const CartFilled224 = require('@andes/icons/CartFilled224');
const CartFilled324 = require('@andes/icons/CartFilled324');
const CartFilled424 = require('@andes/icons/CartFilled424');
const CartFilled524 = require('@andes/icons/CartFilled524');
const CartFilled624 = require('@andes/icons/CartFilled624');
const CartFilled724 = require('@andes/icons/CartFilled724');
const CartFilled824 = require('@andes/icons/CartFilled824');
const CartFilled924 = require('@andes/icons/CartFilled924');

const CartFilled9Plus24 = require('@andes/icons/CartFilled9Plus24');

const Cash16 = require('@andes/icons/Cash16');
const Cash20 = require('@andes/icons/Cash20');
const Cash24 = require('@andes/icons/Cash24');
const Cash32 = require('@andes/icons/Cash32');
const Cash48 = require('@andes/icons/Cash48');

const Categories24 = require('@andes/icons/Categories24');

const Charge24 = require('@andes/icons/Charge24');

const ChevronDown12 = require('@andes/icons/ChevronDown12');
const ChevronDown16 = require('@andes/icons/ChevronDown16');
const ChevronDown20 = require('@andes/icons/ChevronDown20');
const ChevronDown24 = require('@andes/icons/ChevronDown24');
const ChevronDown32 = require('@andes/icons/ChevronDown32');

const ChevronLeft12 = require('@andes/icons/ChevronLeft12');
const ChevronLeft16 = require('@andes/icons/ChevronLeft16');
const ChevronLeft20 = require('@andes/icons/ChevronLeft20');
const ChevronLeft24 = require('@andes/icons/ChevronLeft24');
const ChevronLeft32 = require('@andes/icons/ChevronLeft32');

const ChevronRight12 = require('@andes/icons/ChevronRight12');
const ChevronRight16 = require('@andes/icons/ChevronRight16');
const ChevronRight20 = require('@andes/icons/ChevronRight20');
const ChevronRight24 = require('@andes/icons/ChevronRight24');
const ChevronRight32 = require('@andes/icons/ChevronRight32');

const ChevronUp12 = require('@andes/icons/ChevronUp12');
const ChevronUp16 = require('@andes/icons/ChevronUp16');
const ChevronUp20 = require('@andes/icons/ChevronUp20');
const ChevronUp24 = require('@andes/icons/ChevronUp24');
const ChevronUp32 = require('@andes/icons/ChevronUp32');

const Close12 = require('@andes/icons/Close12');
const Close16 = require('@andes/icons/Close16');
const Close20 = require('@andes/icons/Close20');
const Close24 = require('@andes/icons/Close24');
const Close32 = require('@andes/icons/Close32');

const ContactNew16 = require('@andes/icons/ContactNew16');
const ContactNew20 = require('@andes/icons/ContactNew20');
const ContactNew24 = require('@andes/icons/ContactNew24');
const ContactNew32 = require('@andes/icons/ContactNew32');
const ContactNew48 = require('@andes/icons/ContactNew48');

const Copy16 = require('@andes/icons/Copy16');
const Copy20 = require('@andes/icons/Copy20');
const Copy24 = require('@andes/icons/Copy24');

const CreditCard16 = require('@andes/icons/CreditCard16');
const CreditCard20 = require('@andes/icons/CreditCard20');
const CreditCard24 = require('@andes/icons/CreditCard24');
const CreditCard32 = require('@andes/icons/CreditCard32');
const CreditCard48 = require('@andes/icons/CreditCard48');

const Deals24 = require('@andes/icons/Deals24');

const DebitCard16 = require('@andes/icons/DebitCard16');
const DebitCard20 = require('@andes/icons/DebitCard20');
const DebitCard24 = require('@andes/icons/DebitCard24');
const DebitCard32 = require('@andes/icons/DebitCard32');
const DebitCard48 = require('@andes/icons/DebitCard48');

const Delete16 = require('@andes/icons/Delete16');
const Delete20 = require('@andes/icons/Delete20');
const Delete24 = require('@andes/icons/Delete24');

const DepositExpress32 = require('@andes/icons/DepositExpress32');

const DispatchTime16 = require('@andes/icons/DispatchTime16');
const DispatchTime20 = require('@andes/icons/DispatchTime20');
const DispatchTime24 = require('@andes/icons/DispatchTime24');

const Download16 = require('@andes/icons/Download16');
const Download20 = require('@andes/icons/Download20');
const Download24 = require('@andes/icons/Download24');

const Drawer16 = require('@andes/icons/Drawer16');
const Drawer20 = require('@andes/icons/Drawer20');
const Drawer24 = require('@andes/icons/Drawer24');

const ETH20 = require('@andes/icons/ETH20');

const Edit16 = require('@andes/icons/Edit16');
const Edit20 = require('@andes/icons/Edit20');
const Edit24 = require('@andes/icons/Edit24');

const ExpressPoint32 = require('@andes/icons/ExpressPoint32');

const Fallback16 = require('@andes/icons/Fallback16');
const Fallback20 = require('@andes/icons/Fallback20');
const Fallback24 = require('@andes/icons/Fallback24');
const Fallback32 = require('@andes/icons/Fallback32');
const Fallback48 = require('@andes/icons/Fallback48');

const FastShipping16 = require('@andes/icons/FastShipping16');
const FastShipping20 = require('@andes/icons/FastShipping20');
const FastShipping24 = require('@andes/icons/FastShipping24');
const FastShipping32 = require('@andes/icons/FastShipping32');
const FastShipping48 = require('@andes/icons/FastShipping48');

const FavoriteFilled16 = require('@andes/icons/FavoriteFilled16');
const FavoriteFilled20 = require('@andes/icons/FavoriteFilled20');
const FavoriteFilled24 = require('@andes/icons/FavoriteFilled24');
const FavoriteFilled32 = require('@andes/icons/FavoriteFilled32');

const FavoriteOutlined16 = require('@andes/icons/FavoriteOutlined16');
const FavoriteOutlined20 = require('@andes/icons/FavoriteOutlined20');
const FavoriteOutlined24 = require('@andes/icons/FavoriteOutlined24');
const FavoriteOutlined32 = require('@andes/icons/FavoriteOutlined32');

const FeedbackCaution12 = require('@andes/icons/FeedbackCaution12');
const FeedbackCaution16 = require('@andes/icons/FeedbackCaution16');
const FeedbackCaution24 = require('@andes/icons/FeedbackCaution24');
const FeedbackCaution32 = require('@andes/icons/FeedbackCaution32');
const FeedbackCaution40 = require('@andes/icons/FeedbackCaution40');

const FeedbackInfo12 = require('@andes/icons/FeedbackInfo12');
const FeedbackInfo16 = require('@andes/icons/FeedbackInfo16');
const FeedbackInfo24 = require('@andes/icons/FeedbackInfo24');
const FeedbackInfo32 = require('@andes/icons/FeedbackInfo32');
const FeedbackInfo40 = require('@andes/icons/FeedbackInfo40');

const FeedbackNegative12 = require('@andes/icons/FeedbackNegative12');
const FeedbackNegative16 = require('@andes/icons/FeedbackNegative16');
const FeedbackNegative24 = require('@andes/icons/FeedbackNegative24');
const FeedbackNegative32 = require('@andes/icons/FeedbackNegative32');
const FeedbackNegative40 = require('@andes/icons/FeedbackNegative40');

const FeedbackPositive12 = require('@andes/icons/FeedbackPositive12');
const FeedbackPositive16 = require('@andes/icons/FeedbackPositive16');
const FeedbackPositive24 = require('@andes/icons/FeedbackPositive24');
const FeedbackPositive32 = require('@andes/icons/FeedbackPositive32');
const FeedbackPositive40 = require('@andes/icons/FeedbackPositive40');

const File16 = require('@andes/icons/File16');
const File20 = require('@andes/icons/File20');
const File24 = require('@andes/icons/File24');

const Filter16 = require('@andes/icons/Filter16');
const Filter20 = require('@andes/icons/Filter20');
const Filter24 = require('@andes/icons/Filter24');

const GPSPinFilled16 = require('@andes/icons/GPSPinFilled16');
const GPSPinFilled20 = require('@andes/icons/GPSPinFilled20');
const GPSPinFilled24 = require('@andes/icons/GPSPinFilled24');
const GPSPinFilled32 = require('@andes/icons/GPSPinFilled32');
const GPSPinFilled48 = require('@andes/icons/GPSPinFilled48');

const GPSPinOutlined16 = require('@andes/icons/GPSPinOutlined16');
const GPSPinOutlined20 = require('@andes/icons/GPSPinOutlined20');
const GPSPinOutlined24 = require('@andes/icons/GPSPinOutlined24');
const GPSPinOutlined32 = require('@andes/icons/GPSPinOutlined32');
const GPSPinOutlined48 = require('@andes/icons/GPSPinOutlined48');

const Help24 = require('@andes/icons/Help24');

const Helper16 = require('@andes/icons/Helper16');
const Helper20 = require('@andes/icons/Helper20');
const Helper24 = require('@andes/icons/Helper24');

const History16 = require('@andes/icons/History16');
const History20 = require('@andes/icons/History20');
const History24 = require('@andes/icons/History24');
const History32 = require('@andes/icons/History32');
const History48 = require('@andes/icons/History48');

const Home24 = require('@andes/icons/Home24');

const Info16 = require('@andes/icons/Info16');
const Info20 = require('@andes/icons/Info20');
const Info24 = require('@andes/icons/Info24');

const Laptop24 = require('@andes/icons/Laptop24');
const Laptop32 = require('@andes/icons/Laptop32');

const Link16 = require('@andes/icons/Link16');

const LinkPago24 = require('@andes/icons/LinkPago24');

const Mail20 = require('@andes/icons/Mail20');
const Mail24 = require('@andes/icons/Mail24');
const Mail32 = require('@andes/icons/Mail32');

const MercadoCredito16 = require('@andes/icons/MercadoCredito16');
const MercadoCredito20 = require('@andes/icons/MercadoCredito20');
const MercadoCredito24 = require('@andes/icons/MercadoCredito24');
const MercadoCredito32 = require('@andes/icons/MercadoCredito32');
const MercadoCredito48 = require('@andes/icons/MercadoCredito48');

const MercadoPagoCard16 = require('@andes/icons/MercadoPagoCard16');
const MercadoPagoCard20 = require('@andes/icons/MercadoPagoCard20');
const MercadoPagoCard24 = require('@andes/icons/MercadoPagoCard24');
const MercadoPagoCard32 = require('@andes/icons/MercadoPagoCard32');
const MercadoPagoCard48 = require('@andes/icons/MercadoPagoCard48');

const MercadoPuntos16 = require('@andes/icons/MercadoPuntos16');
const MercadoPuntos20 = require('@andes/icons/MercadoPuntos20');
const MercadoPuntos24 = require('@andes/icons/MercadoPuntos24');
const MercadoPuntos32 = require('@andes/icons/MercadoPuntos32');
const MercadoPuntos48 = require('@andes/icons/MercadoPuntos48');

const MoviesAndSeries16 = require('@andes/icons/MoviesAndSeries16');
const MoviesAndSeries20 = require('@andes/icons/MoviesAndSeries20');
const MoviesAndSeries24 = require('@andes/icons/MoviesAndSeries24');
const MoviesAndSeries32 = require('@andes/icons/MoviesAndSeries32');
const MoviesAndSeries48 = require('@andes/icons/MoviesAndSeries48');

const Notification16 = require('@andes/icons/Notification16');
const Notification20 = require('@andes/icons/Notification20');
const Notification24 = require('@andes/icons/Notification24');
const Notification32 = require('@andes/icons/Notification32');
const Notification48 = require('@andes/icons/Notification48');

const OfficialStores16 = require('@andes/icons/OfficialStores16');
const OfficialStores24 = require('@andes/icons/OfficialStores24');
const OfficialStores32 = require('@andes/icons/OfficialStores32');

const OverflowMenuHorizontal16 = require('@andes/icons/OverflowMenuHorizontal16');
const OverflowMenuHorizontal20 = require('@andes/icons/OverflowMenuHorizontal20');
const OverflowMenuHorizontal24 = require('@andes/icons/OverflowMenuHorizontal24');

const OverflowMenuVertical16 = require('@andes/icons/OverflowMenuVertical16');
const OverflowMenuVertical20 = require('@andes/icons/OverflowMenuVertical20');
const OverflowMenuVertical24 = require('@andes/icons/OverflowMenuVertical24');

const OverflowMenuVerticalHalf8 = require('@andes/icons/OverflowMenuVerticalHalf8');
const OverflowMenuVerticalHalf10 = require('@andes/icons/OverflowMenuVerticalHalf10');
const OverflowMenuVerticalHalf12 = require('@andes/icons/OverflowMenuVerticalHalf12');
const OverflowMenuVerticalHalf16 = require('@andes/icons/OverflowMenuVerticalHalf16');
const OverflowMenuVerticalHalf20 = require('@andes/icons/OverflowMenuVerticalHalf20');
const OverflowMenuVerticalHalf24 = require('@andes/icons/OverflowMenuVerticalHalf24');

const Package16 = require('@andes/icons/Package16');
const Package20 = require('@andes/icons/Package20');

const PackageExpress32 = require('@andes/icons/PackageExpress32');

const PadlockClosed16 = require('@andes/icons/PadlockClosed16');
const PadlockClosed20 = require('@andes/icons/PadlockClosed20');
const PadlockClosed24 = require('@andes/icons/PadlockClosed24');

const PadlockOpen16 = require('@andes/icons/PadlockOpen16');
const PadlockOpen20 = require('@andes/icons/PadlockOpen20');
const PadlockOpen24 = require('@andes/icons/PadlockOpen24');

const Payment16 = require('@andes/icons/Payment16');
const Payment20 = require('@andes/icons/Payment20');
const Payment24 = require('@andes/icons/Payment24');

const PaymentLink24 = require('@andes/icons/PaymentLink24');

const Phone16 = require('@andes/icons/Phone16');
const Phone20 = require('@andes/icons/Phone20');
const Phone24 = require('@andes/icons/Phone24');
const Phone32 = require('@andes/icons/Phone32');

const PhoneAdd16 = require('@andes/icons/PhoneAdd16');
const PhoneAdd20 = require('@andes/icons/PhoneAdd20');
const PhoneAdd24 = require('@andes/icons/PhoneAdd24');
const PhoneAdd32 = require('@andes/icons/PhoneAdd32');

const PhoneRechargeExpress32 = require('@andes/icons/PhoneRechargeExpress32');

const PhoneTopUp24 = require('@andes/icons/PhoneTopUp24');

const PickUp16 = require('@andes/icons/PickUp16');
const PickUp20 = require('@andes/icons/PickUp20');
const PickUp24 = require('@andes/icons/PickUp24');

const PinPickUp24 = require('@andes/icons/PinPickUp24');
const PinPickUp32 = require('@andes/icons/PinPickUp32');

const PlaceholderImage16 = require('@andes/icons/PlaceholderImage16');
const PlaceholderImage20 = require('@andes/icons/PlaceholderImage20');
const PlaceholderImage24 = require('@andes/icons/PlaceholderImage24');
const PlaceholderImage32 = require('@andes/icons/PlaceholderImage32');
const PlaceholderImage48 = require('@andes/icons/PlaceholderImage48');

const PointMLA16 = require('@andes/icons/PointMLA16');
const PointMLA20 = require('@andes/icons/PointMLA20');
const PointMLA24 = require('@andes/icons/PointMLA24');
const PointMLA32 = require('@andes/icons/PointMLA32');
const PointMLA48 = require('@andes/icons/PointMLA48');

const PointMLB24 = require('@andes/icons/PointMLB24');

const PostOfficeShipping24 = require('@andes/icons/PostOfficeShipping24');
const PostOfficeShipping32 = require('@andes/icons/PostOfficeShipping32');

const Present16 = require('@andes/icons/Present16');
const Present20 = require('@andes/icons/Present20');
const Present24 = require('@andes/icons/Present24');
const Present32 = require('@andes/icons/Present32');
const Present48 = require('@andes/icons/Present48');

const PriceHighest16 = require('@andes/icons/PriceHighest16');
const PriceHighest20 = require('@andes/icons/PriceHighest20');
const PriceHighest24 = require('@andes/icons/PriceHighest24');

const PriceLowest16 = require('@andes/icons/PriceLowest16');
const PriceLowest20 = require('@andes/icons/PriceLowest20');
const PriceLowest24 = require('@andes/icons/PriceLowest24');

const Privacy24 = require('@andes/icons/Privacy24');
const Privacy32 = require('@andes/icons/Privacy32');

const ProtectedPurchase16 = require('@andes/icons/ProtectedPurchase16');
const ProtectedPurchase20 = require('@andes/icons/ProtectedPurchase20');
const ProtectedPurchase24 = require('@andes/icons/ProtectedPurchase24');

const Purchases24 = require('@andes/icons/Purchases24');
const Purchases32 = require('@andes/icons/Purchases32');
const Purchases48 = require('@andes/icons/Purchases48');

const QRCode20 = require('@andes/icons/QRCode20');
const QRCode24 = require('@andes/icons/QRCode24');

const QRCodeCharge24 = require('@andes/icons/QRCodeCharge24');

const Question16 = require('@andes/icons/Question16');
const Question20 = require('@andes/icons/Question20');
const Question24 = require('@andes/icons/Question24');

const RealEstate24 = require('@andes/icons/RealEstate24');

const Recurringpayments16 = require('@andes/icons/Recurringpayments16');
const Recurringpayments20 = require('@andes/icons/Recurringpayments20');
const Recurringpayments24 = require('@andes/icons/Recurringpayments24');
const Recurringpayments32 = require('@andes/icons/Recurringpayments32');
const Recurringpayments48 = require('@andes/icons/Recurringpayments48');

const Refresh16 = require('@andes/icons/Refresh16');
const Refresh20 = require('@andes/icons/Refresh20');
const Refresh24 = require('@andes/icons/Refresh24');

const Return16 = require('@andes/icons/Return16');
const Return20 = require('@andes/icons/Return20');
const Return24 = require('@andes/icons/Return24');

const Sales24 = require('@andes/icons/Sales24');
const Sales32 = require('@andes/icons/Sales32');

const Search16 = require('@andes/icons/Search16');
const Search20 = require('@andes/icons/Search20');
const Search24 = require('@andes/icons/Search24');

const Settings24 = require('@andes/icons/Settings24');

const Share16 = require('@andes/icons/Share16');
const Share20 = require('@andes/icons/Share20');
const Share24 = require('@andes/icons/Share24');

const Shipping16 = require('@andes/icons/Shipping16');
const Shipping20 = require('@andes/icons/Shipping20');
const Shipping24 = require('@andes/icons/Shipping24');
const Shipping32 = require('@andes/icons/Shipping32');
const Shipping48 = require('@andes/icons/Shipping48');

const ShippingAgreement24 = require('@andes/icons/ShippingAgreement24');
const ShippingAgreement32 = require('@andes/icons/ShippingAgreement32');

const Smartphone16 = require('@andes/icons/Smartphone16');
const Smartphone24 = require('@andes/icons/Smartphone24');
const Smartphone32 = require('@andes/icons/Smartphone32');

const Sort16 = require('@andes/icons/Sort16');
const Sort20 = require('@andes/icons/Sort20');
const Sort24 = require('@andes/icons/Sort24');

const StarFilled16 = require('@andes/icons/StarFilled16');
const StarFilled20 = require('@andes/icons/StarFilled20');
const StarFilled24 = require('@andes/icons/StarFilled24');
const StarFilled32 = require('@andes/icons/StarFilled32');

const StarHalfFilled16 = require('@andes/icons/StarHalfFilled16');
const StarHalfFilled20 = require('@andes/icons/StarHalfFilled20');
const StarHalfFilled24 = require('@andes/icons/StarHalfFilled24');
const StarHalfFilled32 = require('@andes/icons/StarHalfFilled32');

const StarOutlined16 = require('@andes/icons/StarOutlined16');
const StarOutlined20 = require('@andes/icons/StarOutlined20');
const StarOutlined24 = require('@andes/icons/StarOutlined24');
const StarOutlined32 = require('@andes/icons/StarOutlined32');

const Substract16 = require('@andes/icons/Substract16');
const Substract20 = require('@andes/icons/Substract20');
const Substract24 = require('@andes/icons/Substract24');

const Supermarket24 = require('@andes/icons/Supermarket24');
const Supermarket48 = require('@andes/icons/Supermarket48');

const Tablet24 = require('@andes/icons/Tablet24');
const Tablet32 = require('@andes/icons/Tablet32');

const ThumbDownFilled16 = require('@andes/icons/ThumbDownFilled16');
const ThumbDownFilled20 = require('@andes/icons/ThumbDownFilled20');
const ThumbDownFilled24 = require('@andes/icons/ThumbDownFilled24');

const ThumbDownOutlined16 = require('@andes/icons/ThumbDownOutlined16');
const ThumbDownOutlined20 = require('@andes/icons/ThumbDownOutlined20');
const ThumbDownOutlined24 = require('@andes/icons/ThumbDownOutlined24');

const ThumbUpFilled16 = require('@andes/icons/ThumbUpFilled16');
const ThumbUpFilled20 = require('@andes/icons/ThumbUpFilled20');
const ThumbUpFilled24 = require('@andes/icons/ThumbUpFilled24');

const ThumbUpOutlined16 = require('@andes/icons/ThumbUpOutlined16');
const ThumbUpOutlined20 = require('@andes/icons/ThumbUpOutlined20');
const ThumbUpOutlined24 = require('@andes/icons/ThumbUpOutlined24');

const ToAgree16 = require('@andes/icons/ToAgree16');
const ToAgree20 = require('@andes/icons/ToAgree20');
const ToAgree24 = require('@andes/icons/ToAgree24');
const ToAgree32 = require('@andes/icons/ToAgree32');
const ToAgree48 = require('@andes/icons/ToAgree48');

const TransportCardExpress32 = require('@andes/icons/TransportCardExpress32');

const USDP20 = require('@andes/icons/USDP20');

const Upload16 = require('@andes/icons/Upload16');
const Upload20 = require('@andes/icons/Upload20');
const Upload24 = require('@andes/icons/Upload24');

const User16 = require('@andes/icons/User16');
const User20 = require('@andes/icons/User20');
const User24 = require('@andes/icons/User24');
const User32 = require('@andes/icons/User32');
const User48 = require('@andes/icons/User48');

const Vehicles16 = require('@andes/icons/Vehicles16');
const Vehicles24 = require('@andes/icons/Vehicles24');

const ViewColumns16 = require('@andes/icons/ViewColumns16');
const ViewColumns20 = require('@andes/icons/ViewColumns20');
const ViewColumns24 = require('@andes/icons/ViewColumns24');

const ViewGalery16 = require('@andes/icons/ViewGalery16');
const ViewGalery20 = require('@andes/icons/ViewGalery20');
const ViewGalery24 = require('@andes/icons/ViewGalery24');

const ViewGrid16 = require('@andes/icons/ViewGrid16');
const ViewGrid20 = require('@andes/icons/ViewGrid20');
const ViewGrid24 = require('@andes/icons/ViewGrid24');

const ViewList16 = require('@andes/icons/ViewList16');
const ViewList20 = require('@andes/icons/ViewList20');
const ViewList24 = require('@andes/icons/ViewList24');

const ViewMosaic16 = require('@andes/icons/ViewMosaic16');
const ViewMosaic20 = require('@andes/icons/ViewMosaic20');
const ViewMosaic24 = require('@andes/icons/ViewMosaic24');

const ViewRelevant16 = require('@andes/icons/ViewRelevant16');
const ViewRelevant20 = require('@andes/icons/ViewRelevant20');
const ViewRelevant24 = require('@andes/icons/ViewRelevant24');

const VisibilityOFF16 = require('@andes/icons/VisibilityOFF16');
const VisibilityOFF20 = require('@andes/icons/VisibilityOFF20');
const VisibilityOFF24 = require('@andes/icons/VisibilityOFF24');

const VisibilityON16 = require('@andes/icons/VisibilityON16');
const VisibilityON20 = require('@andes/icons/VisibilityON20');
const VisibilityON24 = require('@andes/icons/VisibilityON24');

const VolumeOff16 = require('@andes/icons/VolumeOff16');
const VolumeOff20 = require('@andes/icons/VolumeOff20');
const VolumeOff24 = require('@andes/icons/VolumeOff24');

const VolumeOn16 = require('@andes/icons/VolumeOn16');
const VolumeOn20 = require('@andes/icons/VolumeOn20');
const VolumeOn24 = require('@andes/icons/VolumeOn24');

const Wallet16 = require('@andes/icons/Wallet16');
const Wallet20 = require('@andes/icons/Wallet20');
const Wallet24 = require('@andes/icons/Wallet24');
const Wallet32 = require('@andes/icons/Wallet32');
const Wallet48 = require('@andes/icons/Wallet48');

const Warranty20 = require('@andes/icons/Warranty20');
const Warranty24 = require('@andes/icons/Warranty24');

const WithdrawExpress32 = require('@andes/icons/WithdrawExpress32');

const ZoomIn16 = require('@andes/icons/ZoomIn16');
const ZoomIn20 = require('@andes/icons/ZoomIn20');
const ZoomIn24 = require('@andes/icons/ZoomIn24');

const ZoomOut16 = require('@andes/icons/ZoomOut16');
const ZoomOut20 = require('@andes/icons/ZoomOut20');
const ZoomOut24 = require('@andes/icons/ZoomOut24');

module.exports = {
  Add16,
  Add20,
  Add24,
  Answer16,
  Answer20,
  Answer24,
  ArrowDown16,
  ArrowDown20,
  ArrowDown24,
  ArrowDown32,
  ArrowLeft16,
  ArrowLeft20,
  ArrowLeft24,
  ArrowLeft32,
  ArrowRight16,
  ArrowRight20,
  ArrowRight24,
  ArrowRight32,
  ArrowUp16,
  ArrowUp20,
  ArrowUp24,
  ArrowUp32,
  Atention16,
  Atention20,
  Atention24,
  Attach16,
  Attach20,
  Attach24,
  Avatar16,
  Avatar20,
  Avatar24,
  Avatar32,
  Avatar48,
  BTC48,
  BankTransfer16,
  BankTransfer24,
  BankTransfer32,
  BankTransfer48,
  Bill16,
  Bill20,
  Bill24,
  Bill32,
  Bill48,
  Boleto16,
  Boleto20,
  Boleto24,
  Boleto32,
  Boleto48,
  BookmarkFilled16,
  BookmarkFilled20,
  BookmarkFilled24,
  BookmarkOutlined16,
  BookmarkOutlined20,
  BookmarkOutlined24,
  CBT16,
  CBT20,
  CBT24,
  CBT32,
  CBT48,
  Calendar16,
  Calendar20,
  Calendar24,
  Camera16,
  Camera20,
  Camera24,
  CardAdd24,
  CartEmpty16,
  CartEmpty20,
  CartEmpty24,
  CartEmpty32,
  CartEmpty48,
  CartFilled124,
  CartFilled224,
  CartFilled324,
  CartFilled424,
  CartFilled524,
  CartFilled624,
  CartFilled724,
  CartFilled824,
  CartFilled924,
  CartFilled9Plus24,
  Cash16,
  Cash20,
  Cash24,
  Cash32,
  Cash48,
  Categories24,
  Charge24,
  ChevronDown12,
  ChevronDown16,
  ChevronDown20,
  ChevronDown24,
  ChevronDown32,
  ChevronLeft12,
  ChevronLeft16,
  ChevronLeft20,
  ChevronLeft24,
  ChevronLeft32,
  ChevronRight12,
  ChevronRight16,
  ChevronRight20,
  ChevronRight24,
  ChevronRight32,
  ChevronUp12,
  ChevronUp16,
  ChevronUp20,
  ChevronUp24,
  ChevronUp32,
  Close12,
  Close16,
  Close20,
  Close24,
  Close32,
  ContactNew16,
  ContactNew20,
  ContactNew24,
  ContactNew32,
  ContactNew48,
  Copy16,
  Copy20,
  Copy24,
  CreditCard16,
  CreditCard20,
  CreditCard24,
  CreditCard32,
  CreditCard48,
  Deals24,
  DebitCard16,
  DebitCard20,
  DebitCard24,
  DebitCard32,
  DebitCard48,
  Delete16,
  Delete20,
  Delete24,
  DepositExpress32,
  DispatchTime16,
  DispatchTime20,
  DispatchTime24,
  Download16,
  Download20,
  Download24,
  Drawer16,
  Drawer20,
  Drawer24,
  ETH20,
  Edit16,
  Edit20,
  Edit24,
  ExpressPoint32,
  Fallback16,
  Fallback20,
  Fallback24,
  Fallback32,
  Fallback48,
  FastShipping16,
  FastShipping20,
  FastShipping24,
  FastShipping32,
  FastShipping48,
  FavoriteFilled16,
  FavoriteFilled20,
  FavoriteFilled24,
  FavoriteFilled32,
  FavoriteOutlined16,
  FavoriteOutlined20,
  FavoriteOutlined24,
  FavoriteOutlined32,
  FeedbackCaution12,
  FeedbackCaution16,
  FeedbackCaution24,
  FeedbackCaution32,
  FeedbackCaution40,
  FeedbackInfo12,
  FeedbackInfo16,
  FeedbackInfo24,
  FeedbackInfo32,
  FeedbackInfo40,
  FeedbackNegative12,
  FeedbackNegative16,
  FeedbackNegative24,
  FeedbackNegative32,
  FeedbackNegative40,
  FeedbackPositive12,
  FeedbackPositive16,
  FeedbackPositive24,
  FeedbackPositive32,
  FeedbackPositive40,
  File16,
  File20,
  File24,
  Filter16,
  Filter20,
  Filter24,
  GPSPinFilled16,
  GPSPinFilled20,
  GPSPinFilled24,
  GPSPinFilled32,
  GPSPinFilled48,
  GPSPinOutlined16,
  GPSPinOutlined20,
  GPSPinOutlined24,
  GPSPinOutlined32,
  GPSPinOutlined48,
  Help24,
  Helper16,
  Helper20,
  Helper24,
  History16,
  History20,
  History24,
  History32,
  History48,
  Home24,
  Info16,
  Info20,
  Info24,
  Laptop24,
  Laptop32,
  Link16,
  LinkPago24,
  Mail20,
  Mail24,
  Mail32,
  MercadoCredito16,
  MercadoCredito20,
  MercadoCredito24,
  MercadoCredito32,
  MercadoCredito48,
  MercadoPagoCard16,
  MercadoPagoCard20,
  MercadoPagoCard24,
  MercadoPagoCard32,
  MercadoPagoCard48,
  MercadoPuntos16,
  MercadoPuntos20,
  MercadoPuntos24,
  MercadoPuntos32,
  MercadoPuntos48,
  MoviesAndSeries16,
  MoviesAndSeries20,
  MoviesAndSeries24,
  MoviesAndSeries32,
  MoviesAndSeries48,
  Notification16,
  Notification20,
  Notification24,
  Notification32,
  Notification48,
  OfficialStores16,
  OfficialStores24,
  OfficialStores32,
  OverflowMenuHorizontal16,
  OverflowMenuHorizontal20,
  OverflowMenuHorizontal24,
  OverflowMenuVertical16,
  OverflowMenuVertical20,
  OverflowMenuVertical24,
  OverflowMenuVerticalHalf8,
  OverflowMenuVerticalHalf10,
  OverflowMenuVerticalHalf12,
  OverflowMenuVerticalHalf16,
  OverflowMenuVerticalHalf20,
  OverflowMenuVerticalHalf24,
  Package16,
  Package20,
  PackageExpress32,
  PadlockClosed16,
  PadlockClosed20,
  PadlockClosed24,
  PadlockOpen16,
  PadlockOpen20,
  PadlockOpen24,
  Payment16,
  Payment20,
  Payment24,
  PaymentLink24,
  Phone16,
  Phone20,
  Phone24,
  Phone32,
  PhoneAdd16,
  PhoneAdd20,
  PhoneAdd24,
  PhoneAdd32,
  PhoneRechargeExpress32,
  PhoneTopUp24,
  PickUp16,
  PickUp20,
  PickUp24,
  PinPickUp24,
  PinPickUp32,
  PlaceholderImage16,
  PlaceholderImage20,
  PlaceholderImage24,
  PlaceholderImage32,
  PlaceholderImage48,
  PointMLA16,
  PointMLA20,
  PointMLA24,
  PointMLA32,
  PointMLA48,
  PointMLB24,
  PostOfficeShipping24,
  PostOfficeShipping32,
  Present16,
  Present20,
  Present24,
  Present32,
  Present48,
  PriceHighest16,
  PriceHighest20,
  PriceHighest24,
  PriceLowest16,
  PriceLowest20,
  PriceLowest24,
  Privacy24,
  Privacy32,
  ProtectedPurchase16,
  ProtectedPurchase20,
  ProtectedPurchase24,
  Purchases24,
  Purchases32,
  Purchases48,
  QRCode20,
  QRCode24,
  QRCodeCharge24,
  Question16,
  Question20,
  Question24,
  RealEstate24,
  Recurringpayments16,
  Recurringpayments20,
  Recurringpayments24,
  Recurringpayments32,
  Recurringpayments48,
  Refresh16,
  Refresh20,
  Refresh24,
  Return16,
  Return20,
  Return24,
  Sales24,
  Sales32,
  Search16,
  Search20,
  Search24,
  Settings24,
  Share16,
  Share20,
  Share24,
  Shipping16,
  Shipping20,
  Shipping24,
  Shipping32,
  Shipping48,
  ShippingAgreement24,
  ShippingAgreement32,
  Smartphone16,
  Smartphone24,
  Smartphone32,
  Sort16,
  Sort20,
  Sort24,
  StarFilled16,
  StarFilled20,
  StarFilled24,
  StarFilled32,
  StarHalfFilled16,
  StarHalfFilled20,
  StarHalfFilled24,
  StarHalfFilled32,
  StarOutlined16,
  StarOutlined20,
  StarOutlined24,
  StarOutlined32,
  Substract16,
  Substract20,
  Substract24,
  Supermarket24,
  Supermarket48,
  Tablet24,
  Tablet32,
  ThumbDownFilled16,
  ThumbDownFilled20,
  ThumbDownFilled24,
  ThumbDownOutlined16,
  ThumbDownOutlined20,
  ThumbDownOutlined24,
  ThumbUpFilled16,
  ThumbUpFilled20,
  ThumbUpFilled24,
  ThumbUpOutlined16,
  ThumbUpOutlined20,
  ThumbUpOutlined24,
  ToAgree16,
  ToAgree20,
  ToAgree24,
  ToAgree32,
  ToAgree48,
  TransportCardExpress32,
  USDP20,
  Upload16,
  Upload20,
  Upload24,
  User16,
  User20,
  User24,
  User32,
  User48,
  Vehicles16,
  Vehicles24,
  ViewColumns16,
  ViewColumns20,
  ViewColumns24,
  ViewGalery16,
  ViewGalery20,
  ViewGalery24,
  ViewGrid16,
  ViewGrid20,
  ViewGrid24,
  ViewList16,
  ViewList20,
  ViewList24,
  ViewMosaic16,
  ViewMosaic20,
  ViewMosaic24,
  ViewRelevant16,
  ViewRelevant20,
  ViewRelevant24,
  VisibilityOFF16,
  VisibilityOFF20,
  VisibilityOFF24,
  VisibilityON16,
  VisibilityON20,
  VisibilityON24,
  VolumeOff16,
  VolumeOff20,
  VolumeOff24,
  VolumeOn16,
  VolumeOn20,
  VolumeOn24,
  Wallet16,
  Wallet20,
  Wallet24,
  Wallet32,
  Wallet48,
  Warranty20,
  Warranty24,
  WithdrawExpress32,
  ZoomIn16,
  ZoomIn20,
  ZoomIn24,
  ZoomOut16,
  ZoomOut20,
  ZoomOut24,
};
