import { buildContext } from 'nordic/restclient';

const deviceType = device => {
  if (device.webView) return 'webview';
  if (device.mobile || device.tablet) return 'mobile';
  return 'desktop';
};

const defaultDataToService = req => {
  const { params, platform, auth, i18n, headers, body, query } = req;
  let siteId;
  if (platform) {
    siteId = platform.siteId;
  } else {
    const domainToCBT = 'sellers.mercadolibre.com';
    if (req.hostname.endsWith(domainToCBT)) {
      req.platform = {
        siteId: 'CBT',
        countryId: 'US',
        id: 'ML',
        domain: domainToCBT,
      };
      i18n.locale = 'en-US';
      siteId = req.platform.siteId;
    } else {
      siteId = req.params.siteId;
    }
  }

  return {
    params,
    body,
    context: buildContext(req),
    userId: auth ? auth.user.id : '',
    nickname: auth ? auth.user.nickname : '',
    siteId,
    referrer: deviceType(req.device),
    locale: i18n ? i18n.locale : '',
    headers,
    query,
  };
};

export default defaultDataToService;
