import defaultDataToService from './dataToService';

export const suScopePath = `
const path = new URL(window.location.href);
window.suScope = path.searchParams.get('suScope');
path.searchParams.delete('suScope');
window.history.replaceState({}, '', path.toString());
`;

export const siteTitle = (siteId) => {
  let title = 'Centro de Vendedores';
  if (siteId === 'MLB') title = 'Central de Vendedores';
  if (siteId === 'CBT') title = 'Mercado Libre';
  return title;
};

export const dataToService = defaultDataToService;
