/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
const React = require('react');
const PropTypes = require('prop-types');

const DesLikeIcon = ({ color }) => (
  <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" strokeWidth="0" clipRule="evenodd" d="M6.12244 13.841L8.44783 15.08L12.891 21.4515L14.7588 20.5702C16.2287 19.8765 16.9296 18.1784 16.3767 16.6499L15.5097 14.2529H19.6855C20.1419 14.2529 20.5929 14.1537 21.0072 13.9622C22.5863 13.2323 23.2747 11.3604 22.5448 9.78125L19.857 3.96648C18.8085 1.69807 16.3732 0.410192 13.9081 0.820444L9.87686 1.49133H6.12244V0.25293H0.127441V15.2529H6.12244V13.841ZM9.47175 13.9259L13.3967 19.5543L14.1187 19.2136C14.8886 18.8503 15.2558 17.9608 14.9662 17.1602L13.372 12.7529H19.6855C19.9246 12.7529 20.1608 12.701 20.3778 12.6007C21.205 12.2183 21.5656 11.2378 21.1832 10.4106L18.4954 4.59585C17.7303 2.94052 15.9532 2.00072 14.1543 2.30009L10.062 2.98115L9.93884 2.99133H6.12244V12.1413L9.47175 13.9259ZM1.62744 13.7529V1.75293H4.62244V13.7529H1.62744Z" style={{ fill: `${color} !important` }} fillOpacity="0.9" />
  </svg>
);

DesLikeIcon.defaultProps = {
  color: '#000000',
};

DesLikeIcon.propTypes = {
  color: PropTypes.string,
};

module.exports = DesLikeIcon;
