/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

const RenderChildren = props => {
  const { type, className, children, brick, performEvent } = props;

  if (brick && brick.id === 'view_note' && brick.data) {
    performEvent({
      type: 'update_storage',
      data: {
        mode: 'merge',
        storage_data: {
          tracks: brick.data.tracks,
        },
      },
    });
  }

  if (type === 'fragment') return <>{children}</>;
  if (type !== undefined) return React.createElement(type, { className }, children);
  return <div className={className}>{children}</div>;
};

RenderChildren.propTypes = {
  type: PropTypes.string,
  brick: PropTypes.shape({
    id: PropTypes.string,
    data: PropTypes.shape({
      tracks: PropTypes.shape({}),
    }),
  }),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(), PropTypes.node]),
  performEvent: PropTypes.func,
};

RenderChildren.defaultProps = {
  className: 'sc-render-children',
};

export default RenderChildren;
