import React, { useState } from 'react';
import Thumbnail from '@andes/thumbnail';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { handlerLogin } from '../../helpers/actionsAuth';
import Icon from '../Icon';
import { getContext } from '../../helpers/selectors';

const restclient = require('nordic/restclient');
const config = require('../../../config/restclient/browser');

const ActionsSharedAndFavorite = props => {
  const { brick, parentNoteId } = props;
  const { auth, platform, fullUrl } = useSelector(getContext);
  // eslint-disable-next-line max-len, react/destructuring-assignment, react/prop-types
  const [isDeterminateIcon, setIsDeterminateIcon] = useState(brick && brick.data && brick.data.mark ? 'BookMarkIsChecked' : 'Shape');

  const handlerSavingMaskNote = () => {
    if (auth && auth.user) {
      const request = restclient(config(fullUrl));
      const funcCall = isDeterminateIcon === 'Shape' ? request
        .post('/note/bookmark-note', {
          data: { id: parentNoteId },
        }) : request
        .delete('/note/delete-bookmark-note', {
          data: { id: parentNoteId },
        });
      funcCall.then(() => {
        setIsDeterminateIcon(isDeterminateIcon === 'Shape' ? 'BookMarkIsChecked' : 'Shape');
      });
    } else {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(handlerLogin(platform.siteId), '_system');
    }
  };

  return (
    <div className="actions-container-shared">
      <Thumbnail className="note-saving">
        <Icon color="black" type={isDeterminateIcon} onClick={handlerSavingMaskNote} />
      </Thumbnail>
    </div>
  );
};

ActionsSharedAndFavorite.propTypes = {
  brick: PropTypes.shape({}),
  parentNoteId: PropTypes.string,
};

export default ActionsSharedAndFavorite;
