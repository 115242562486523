import events from './custom-events';

export default {
  redirect: events.redirect,
  back: events.back,
  login: events.login,
  request: events.request,
  update_complete_bricks: events.updateCompleteBricks,
  pagination_request: events.pagination_request,
  subscribe_event: events.nativeSubscribeEvent,
  unsubscribe_event: events.nativeUnsubscribeEvent,
  sc_deeplink: events.nativeRedirect,
  dispatch_event: events.nativeDispatchEvent,
  sc_tracking: events.trackingEvent,
};
