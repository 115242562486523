
/* eslint-disable max-len */
/* eslint-disable react/no-unknown-property */
const React = require('react');

const SuccessCheckedIcon = () => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="21" cy="20.75" r="18.5" transform="rotate(-180 21 20.75)" fill="#00A650" stroke="white" strokeWidth="3" />
    <circle cx="21" cy="20.75" r="20" transform="rotate(-180 21 20.75)" stroke="#333333" strokeWidth="1.5" />
    <path d="M18.5166 23.4087L27.8928 13.75L29.9999 15.9206L18.5166 27.75L11.9999 21.0368L14.107 18.8662L18.5166 23.4087Z" fill="white" />
  </svg>
);


module.exports = SuccessCheckedIcon;
