/* global melidata */
export const trackingEvent = ({ eventData }) => {
  if (eventData.provider === 'melidata' && typeof window !== 'undefined') {
    const { type, path, eventData: trackData } = eventData.data;
    melidata('cleanAndSend', type, {
      path,
      event_data: trackData || {},
    });
  }
};
