/* eslint-disable max-len */
import React from 'react';

const MlIconDesktop = () => (
  <svg fill="none" viewBox="0 0 80 56" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M79.999 25.887C79.92 11.54 62.102 0 40.273 0h-.518A56.312 56.312 0 0 0 19.11 3.912C8.068 8.322.528 16.147 0 25.124v4.088c.43 7.14 4.303 13.633 11.012 18.377 7.276 5.144 17.154 7.823 28.586 7.823h.607c11.736-.097 21.809-2.992 29.114-8.352C76.273 41.955 80.077 34.963 80 27.354v-1.467Z"
      fill="#2D3277"
    />
    <path
      d="M36.058 37.046c.36-.141.74-.227 1.125-.254a2.886 2.886 0 1 1 .049 5.76 2.856 2.856 0 0 1-2.876-2.718c0-.656-.38-.763-.586-.763a.841.841 0 0 0-.538.273 1.957 1.957 0 0 1-1.36.636c-.316.001-.63-.066-.919-.195a2.093 2.093 0 0 1-1.555-2.299.645.645 0 0 0-.127-.577.803.803 0 0 0-.323-.166.676.676 0 0 0-.528.166 2.857 2.857 0 0 1-1.418.646c-.26.002-.52-.044-.763-.137a2.992 2.992 0 0 1-2.063-3.404.519.519 0 0 0-.176-.547c-.264-.166-.509.078-.665.235a1.613 1.613 0 0 1-1.35.44 2.425 2.425 0 0 1-2.25-2.289 2.19 2.19 0 0 1 2.172-2.21 2.005 2.005 0 0 1 2.269 1.653.5.5 0 0 1 0 .127c0 .186.058.44.293.518.235.078.41-.166.509-.323.023-.04.05-.08.078-.117l.068-.088a2.62 2.62 0 0 1 2.475-1.095c.331 0 .66.049.978.146a2.25 2.25 0 0 1 1.467 2.23.567.567 0 0 0 .537.558.606.606 0 0 0 .45-.225 2.426 2.426 0 0 1 1.692-.91 3.12 3.12 0 0 1 1.252.274c2.093.88 1.144 3.58 1.134 3.599-.127.313-.332.85.108 1.134h.264c.162-.013.32-.052.47-.117l.107.04ZM77.818 22.787a91.588 91.588 0 0 0-16.372 5.487c-3.784-3.247-12.527-10.758-14.904-12.48a10.64 10.64 0 0 0-3.178-1.809 4.89 4.89 0 0 0-1.585-.225 6.27 6.27 0 0 0-1.956.352 15.892 15.892 0 0 0-4.586 2.934l-.089.079a12.655 12.655 0 0 1-3.784 2.435c-.453.1-.915.153-1.38.156a3.58 3.58 0 0 1-2.434-.714l.107-.166 3.296-3.609c2.552-2.601 4.958-5.046 10.533-5.222h.274c2.53.093 5.02.681 7.325 1.73a23.47 23.47 0 0 0 10.298 2.329 26.571 26.571 0 0 0 11.002-2.68c3.873 3.286 6.504 7.198 7.433 11.403Z"
      fill="#fff"
    />
    <path
      d="M39.823 1.79h.5a56.478 56.478 0 0 1 15.54 2.122l.342.098.684.205a41.183 41.183 0 0 1 11.677 5.76 23.825 23.825 0 0 1-9.183 2.084h-.147a21.516 21.516 0 0 1-9.27-2.191A21.643 21.643 0 0 0 41.778 8h-.44a14.308 14.308 0 0 0-9.3 3.364 19.255 19.255 0 0 0-4.607.89c-1.018.357-2.081.571-3.159.636h-1.232a63.276 63.276 0 0 1-11.295-3.012c7.227-5.057 17.388-8 28.077-8.088Z"
      fill="#FFD100"
    />
    <path
      d="M9.936 11.266c5.056 2.025 10.924 3.462 12.714 3.56h.4c.402 0 .822.05 1.243.059a14.192 14.192 0 0 0 3.794-.694c.49-.147.979-.284 1.468-.402l-.069.069-3.354 3.677a1.955 1.955 0 0 0-.5 2.142c.218.45.556.83.979 1.095a7.324 7.324 0 0 0 3.736.92 6.511 6.511 0 0 0 1.476-.176c1.458-.333 2.934-1.536 4.558-2.836a14.835 14.835 0 0 1 4.293-2.631 4.834 4.834 0 0 1 1.154-.167h.294a6.475 6.475 0 0 1 3.227 1.516c2.65 1.956 13.975 11.736 15.286 12.812a2.16 2.16 0 0 1 .704 1.613 1.702 1.702 0 0 1-.9 1.428 2.778 2.778 0 0 1-1.477.48 2.514 2.514 0 0 1-1.251-.343s-.147-.137-.284-.332c-1.047-.978-4.225-3.785-5.535-4.89-.23-.247-.544-.4-.88-.43a.656.656 0 0 0-.519.244c-.284.362-.137.88.381 1.31l4.969 4.89c.328.325.54.747.606 1.203a2.015 2.015 0 0 1-1.037 1.83c-.467.339-1.026.53-1.604.547a2.397 2.397 0 0 1-1.27-.342l-.754-.744c-1.29-1.242-2.621-2.523-3.59-3.325a1.525 1.525 0 0 0-.89-.43.685.685 0 0 0-.488.215c-.137.166-.313.509.088.978.077.107.166.205.264.293l3.618 3.99a1.115 1.115 0 0 1 .079 1.409l-.108.156a4.212 4.212 0 0 1-.303.294 2.611 2.611 0 0 1-1.604.518 2.912 2.912 0 0 1-.44 0c-.401-.068-.528-.166-.567-.215l-.118-.117a41.145 41.145 0 0 0-3.237-3.042 1.428 1.428 0 0 0-.85-.41.666.666 0 0 0-.51.224c-.302.342-.165.841.353 1.31l2.719 3.042c-.069.098-.372.43-1.409.567-.136.01-.273.01-.41 0a6.464 6.464 0 0 1-2.65-.733 4.821 4.821 0 0 0-4.41-6.611 4.206 4.206 0 0 0-2.515-4.245 5.203 5.203 0 0 0-1.956-.42 4.675 4.675 0 0 0-1.28.205 4.137 4.137 0 0 0-2.28-1.81 4.705 4.705 0 0 0-1.72-.283c-.89.005-1.76.266-2.504.753a4.117 4.117 0 0 0-3.032-1.34 4.166 4.166 0 0 0-2.738 1.076c-1.154-.811-5.057-3.09-14.817-5.222-.45-.098-1.408-.362-2.21-.587.802-4.195 3.481-8.244 7.667-11.648Z"
      fill="#fff"
    />
    <path
      d="M78.15 27.462c0 .322-.068.645-.107.978-.86 5.545-4.636 10.757-10.846 14.767C60 47.921 50.405 50.542 40.185 50.63h-.508c-20.85 0-37.829-10.758-37.936-24.107 0-.42 0-.88.059-1.399l2.122.48c9.623 2.053 13.232 4.224 14.122 4.89a4.127 4.127 0 0 0 3.883 5.496c.185.01.371.01.557 0a4.762 4.762 0 0 0 3.051 3.295c.458.181.946.274 1.438.274.256-.002.511-.029.763-.078a4.381 4.381 0 0 0 2.474 2.171 4.548 4.548 0 0 0 1.613.313c.355.003.709-.05 1.047-.156a4.802 4.802 0 0 0 4.342 2.738 4.832 4.832 0 0 0 3.306-1.36 8.802 8.802 0 0 0 3.834 1.067h.616a3.747 3.747 0 0 0 2.767-1.409l.088-.117c.365.089.74.135 1.115.137a4.45 4.45 0 0 0 2.64-.978 3.912 3.912 0 0 0 1.517-2.103h.635a4.89 4.89 0 0 0 2.69-.88 3.911 3.911 0 0 0 1.956-3.227c.201.02.404.02.606 0a4.684 4.684 0 0 0 2.533-.802 3.717 3.717 0 0 0 1.75-2.934 3.912 3.912 0 0 0-.44-2.103 100.74 100.74 0 0 1 15.286-4.89c0 .42.079.851.079 1.281 0 .43-.01.822-.04 1.233Z"
      fill="#FFD100"
    />
  </svg>
);

export default MlIconDesktop;
