import React from 'react';
import PropTypes from 'prop-types';
import TitleAndSubTitle from '../TitleAndSubTitle';
import Icon from '../Icon';
import Fallback from '../Fallback';

const CardContainer = (props) => {
  const {
    title,
    subTitle,
    icon,
    color,
    children,
    className,
    defaultClassName,
    fallback,
  } = props;

  if (fallback) {
    return <Fallback {...fallback} className={`${defaultClassName}-fallback`} />;
  }

  return (
    <div className={`${defaultClassName} ${className}  ${icon ? 'flex' : ''}`}>
      <div className={`${defaultClassName}-content ${color} ${icon ? 'flex' : ''}`}>
        {icon && (
          <Icon
            containerClassName={[`${defaultClassName}-icon`]}
            type={icon}
            color={color}
          />
        )}
        <div className={`${defaultClassName}-text`}>
          <TitleAndSubTitle
            className="home"
            title={title}
            subTitle={subTitle}
          />
        </div>
      </div>
      {children}
    </div>
  );
};

CardContainer.propTypes = {
  defaultClassName: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  fallback: PropTypes.shape(),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(),
    PropTypes.node,
  ]),
};

CardContainer.defaultProps = {
  defaultClassName: 'sc-card-container',
  color: '',
  className: '',
  fallback: undefined,
};

export default CardContainer;
