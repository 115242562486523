/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable security/detect-object-injection */
/* eslint-disable react/no-danger */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { RestClient } from 'nordic/restclient';
import { useSelector } from 'react-redux';
import { getStorage } from 'flox-web-client/src/ducks/flox-selectors';
import config from '../../../config/restclient/browser';
import PushLogin from '../PushLogin';
import RightContent from '../../containers/RightContent';
import { useScrollPercentage } from '../../hooks/useScrollPercentage';
import { removeAccents } from '../../helpers/dom-utils';
import MetaDataNote from '../MetaDataNote';
import { getContext, getNoteData } from '../../helpers/selectors';
import Banner from '../Banner';
import { useTracks } from '../../hooks/useTracks';
import { useStateRef } from '../../hooks/useStateRef';

const TracksContext = require('../../context/tracks-context');

const NoteHtml = (props) => {
  const { content, rightContent, push, banner, bannerType, className } = props;
  const html = content ? JSON.parse(content).view : '';
  const regexImg = /<img*/gi;
  const lazyImagesHtml = html.replace(regexImg, '<img loading="lazy"');
  const regex = /style="[^"]*"»/gi;
  const cleanHtml = lazyImagesHtml.replace(regex, '');
  const [isRead, setIsRead, isReadRef] = useStateRef(false);
  const [scrollRef, scrollPercentage] = useScrollPercentage();
  const { auth, fullUrl } = useSelector(getContext);
  const { parentNoteId, advance, maxAdvance, parentGuides, parentModules } =
    useSelector(getNoteData);
  const { trackDataFromStorage } = useTracks();
  const { trackMelidata } = React.useContext(TracksContext.Consumer);
  const request = RestClient(config(fullUrl));
  const { title, subtitle } = useSelector(getStorage);
  const [advanceScroll, setAdvance, advanceRef] = useStateRef(advance || 0);
  const currentDate = useRef(null);

  const getClickedCTAMainInfo = ({ target }) => {
    const contentFilter = JSON.parse(content);
    const infoCTA = contentFilter.content.filter(
      ({ values }) => values?.button1 === target?.innerText ||
        values?.cta1 === target?.innerText,
    );
    if (parentNoteId) {
      const mainInfo = {
        type: 'primary',
        note_id: parentNoteId,
        cta: removeAccents(
          infoCTA[0]?.values?.cta1 || infoCTA[0]?.values?.button1,
        ),
        link: infoCTA[0]?.values?.textLink1,
        cust_logged_in: !!(auth && auth.user),
        cust_id: auth && auth.user ? auth.user.id : undefined,
      };
      trackMelidata('/seller_central/seller_journey/university/cta', mainInfo);
    }
  };

  const getClickedCTASecInfo = ({ target }) => {
    const contentFilter = JSON.parse(content);
    const infoCTA = contentFilter.content.filter(
      ({ values }) => values?.button2 === target?.innerText ||
        values?.cta2 === target?.innerText,
    );
    if (parentNoteId) {
      const secInfo = {
        type: 'secondary',
        note_id: parentNoteId,
        cta: removeAccents(infoCTA[0].values.cta2 || infoCTA[0].values.button2),
        link: infoCTA[0].values.textLink2,
        cust_logged_in: !!(auth && auth.user),
        cust_id: auth && auth.user ? auth.user.id : undefined,
      };

      trackMelidata('/seller_central/seller_journey/university/cta', secInfo);
    }
  };

  const sendEndTimeToMelidata = () => {
    const endTime = new Date();
    const time = Math.round(
      (endTime.getTime() - currentDate.current.getTime()) / 1000,
    );
    if (bannerType !== 'VIDEO') {
      if (advanceRef.current !== 100) {
        trackDataFromStorage('ADVANCE', {
          entity_advance: advanceRef.current,
          type: 'SCROLL',
        });
      }

      if (isReadRef.current) {
        trackDataFromStorage('COMPLETED', {
          is_completed: true,
          type: 'SCROLL',
        });
      }
      if ((advance === 0 || !advance) && !isReadRef.current) {
        trackDataFromStorage('COMPLETED', {
          is_completed: false,
          type: 'SCROLL',
        });
      }
    }
    trackDataFromStorage('END_TIME', { date_time: endTime, time });
  };

  useEffect(() => {
    if (
      advanceScroll < scrollPercentage &&
      scrollPercentage >= 90 &&
      scrollPercentage <= 100 &&
      bannerType !== 'VIDEO'
    ) {
      if (scrollPercentage % 10 === 0) {
        setAdvance(scrollPercentage);
        if (auth?.user?.id && parentNoteId) {
          request.post('/note/advance', {
            data: {
              noteId: parentNoteId,
              percentage: scrollPercentage,
              parentGuides,
              parentModules,
            },
          });
        }
      }
      if (!isRead && scrollPercentage >= maxAdvance) {
        setIsRead(true);
      }
    }
  }, [scrollPercentage]);

  useEffect(() => {
    const note = document.getElementsByClassName('sc-html-content')[0];
    let CTAMainElements = note.getElementsByClassName('cta-button-main');

    if (CTAMainElements.length === 0) {
      CTAMainElements = note.getElementsByClassName('cta-button');
    }

    if (CTAMainElements.length === 0) {
      CTAMainElements = note.getElementsByClassName('btn-button_main');
    }

    let CTASecElements = note.getElementsByClassName('cta-button-secondary');

    if (CTASecElements.length === 0) {
      CTASecElements = note.getElementsByClassName('btn-button_secondary');
    }

    for (let i = 0; i < CTAMainElements.length; i += 1) {
      CTAMainElements[i].addEventListener(
        'click',
        getClickedCTAMainInfo,
        false,
      );
      CTAMainElements[i].addEventListener(
        'contextmenu',
        getClickedCTAMainInfo,
        false,
      );
    }
    for (let i = 0; i < CTASecElements.length; i += 1) {
      CTASecElements[i].addEventListener('click', getClickedCTASecInfo, false);
      CTASecElements[i].addEventListener(
        'contextmenu',
        getClickedCTASecInfo,
        false,
      );
    }
    currentDate.current = new Date();

    window.addEventListener('beforeunload', sendEndTimeToMelidata);
  }, []);

  return (
    <>
      <MetaDataNote title={title} subtitle={subtitle} href={fullUrl} />
      {push && <PushLogin {...push} />}
      <Banner
        type={bannerType}
        source={
          content
            ? banner || JSON.parse(content).content[0].values.imageLink
            : banner
        }
      />
      <div
        ref={scrollRef}
        className={className}
        id="html-content"
        dangerouslySetInnerHTML={{ __html: cleanHtml }}
      />
      <div className="right-content-container">
        {rightContent && <RightContent {...rightContent} />}
      </div>
    </>
  );
};

NoteHtml.defaultProps = {
  className: 'sc-html-content',
};

NoteHtml.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  rightContent: PropTypes.shape({
    push: PropTypes.shape({}),
  }),
  push: PropTypes.shape({}),
  banner: PropTypes.string,
  bannerType: PropTypes.string,
};

export default NoteHtml;
