/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events
 */
/* eslint-disable-next-line jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Title } from '@andes/typography';
import Icon from '../../Icon';
import Tag from '../../Tag';
import IconText from '../../IconText';
import SimpleRow from '../SimpleRow';
import Fallback from '../../Fallback';

const Spinner = require('@andes/spinner');

const ExpandableRow = (props) => {
  const { dataItem, bricks, performEvent, expandableRow, setExpandableRow, keyId } = props;
  const { title, label, description, chevron, modifier, events, fallback } = dataItem;
  const [subList, setSubList] = useState(null);
  const [fallbackData, setFallbackData] = useState(undefined);
  const [showExpander, setShowExpander] = useState(chevron?.expand);
  const [showExpanderSubList, setShowExpanderSubList] = useState(chevron?.expandSubList);
  const [showSpinner, setShowSpinner] = useState(false);
  const [sentRequest, setsSentRequest] = useState(true);

  useEffect(() => {
    if (expandableRow === keyId) {
      setShowExpander(true);
      setShowExpanderSubList(true);
      const actualPath = new URL(window?.location?.href);
      /* istanbul ignore next */
      if (actualPath?.hash) {
        setTimeout(() => {
          window.location.href = actualPath?.href;
        }, 100);
      }
    } else {
      setShowExpander(false);
      setShowExpanderSubList(false);
    }

    if (chevron?.expand) {
      setExpandableRow(keyId);
      chevron.expand = false;
    }
    const filterSubList = bricks.find((x) => x.uiType === 'list');
    const fallbackExpandable = bricks.find((x) => x.uiType === 'list')?.data?.fallback;
    if (fallbackExpandable !== undefined) {
      setFallbackData(fallbackExpandable);
    }

    if (filterSubList) {
      setSubList(filterSubList.bricks);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bricks, expandableRow]);


  const onClickChevron = () => {
    if (expandableRow === keyId) {
      setShowExpander((prev) => !prev);
      setShowExpanderSubList((prev) => !prev);
    }
    setShowSpinner(true);
    setExpandableRow(keyId);
    if (sentRequest && performEvent && events) {
      setsSentRequest(false);
      events.forEach((e) => performEvent(e));
    }
  };

  const renderShowList = () => (
    fallbackData === undefined ? (
      subList?.map((item) => <SimpleRow dataItem={item?.data} bricks={item?.bricks} performEvent={performEvent} />)
    ) : (
      <Fallback {...fallbackData} className="sc-fallback-sub-list-expanded" />
    )
  );

  return (
    <div id={keyId?.split('_')[1]} className="container-expande">
      <div className="content-expandable-guie" onClick={() => onClickChevron()}>
        <div className="row-guie-label">
          <Title component="h1" size="xs" color={modifier || 'secondary'}>
            {label}
          </Title>
        </div>
        {fallback && <Fallback {...fallback} className="sc-fallback-expandable-row" />}
        {!fallback && (
          <div className="content-row-description">
            <Title
              color="primary"
              className="row-guie-title"
              component="h1"
              size="m"
            >
              {title}
            </Title>
            <p className="row-guie-description">{description}</p>
            <div className="row-tag-icon-container">
              {bricks?.map((item) => (
                // eslint-disable-next-line max-len, implicit-arrow-linebreak
                <>
                  {item.uiType === 'icon_text' && (
                    <IconText
                      styles={{ marginRight: 12 }}
                      color="grey"
                      icon={item?.data.icon}
                      label={item?.data.label}
                    />
                  )}
                  {item.uiType === 'tag' && <Tag {...item.data} />}
                </>
              ))}
            </div>
          </div>
        )}
        {chevron && chevron.isShow && (
          <Icon
            containerClassName={showExpander ? ['icon-chevron-show'] : ['icon-chevron']}
            type={!showExpander ? 'ChevronDown24' : chevron.icon}
            color="rgba(0, 0, 0, 0.9)"
          />
        )}
      </div>
      {showExpanderSubList && (
        <div className="sub-list-expande">
          {subList || fallbackData ? renderShowList() : (
            showSpinner && (
              <div className="sub-list-spinner">
                <Spinner modifier="inline" size="small" />
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

ExpandableRow.defaultProps = {
  dataItem: {},
  bricks: [],
};

ExpandableRow.propTypes = {
  dataItem: PropTypes.shape({
    title: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    modifier: PropTypes.string,
    chevron: PropTypes.shape({
      expandSubList: PropTypes.bool,
      expand: PropTypes.bool,
      isShow: PropTypes.bool,
      icon: PropTypes.string,
    }),
    events: PropTypes.arrayOf(PropTypes.shape({})),
    fallback: PropTypes.shape({}),
  }),
  bricks: PropTypes.shape([]),
  performEvent: PropTypes.func,
  expandableRow: PropTypes.string,
  setExpandableRow: PropTypes.string,
  keyId: PropTypes.string,
};

export default ExpandableRow;
