/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
const React = require('react');
const PropTypes = require('prop-types');
const RadioButton = require('@andes/radio-button');
const { get } = require('lodash');

const RadioButtonFilters = props => {
  const {
    id,
    className,
    idGroup,
    storageValue,
    updateSelection,
    label,
  } = props;

  const currentValue = get(storageValue, idGroup)?.current;
  const selected = currentValue === id;

  const handleOptionClick = () => {
    const newValue = {
      ...get(storageValue, idGroup),
      current: id,
    };
    updateSelection(newValue);
  };

  return (
    <div className={className} onClick={handleOptionClick} role="button">
      <RadioButton
        label={label}
        value={id}
        id={id}
        name={id}
        checked={selected}
        inverted
      />
    </div>
  );
};

RadioButtonFilters.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  storageValue: PropTypes.shape({}),
  idGroup: PropTypes.string,
  id: PropTypes.string,
  updateSelection: PropTypes.func,
};

RadioButtonFilters.defaultProps = {
  className: 'sc-radio-button-filter',
  label: null,
  storageValue: null,
  idGroup: null,
  id: null,
  updateSelection: null,
};

module.exports = RadioButtonFilters;
