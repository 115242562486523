import React from 'react';
import PropTypes from 'prop-types';
import Card, { CardContent } from '@andes/card';
import { Text } from '@andes/typography';
import { useSelector } from 'react-redux';
import Icon from '../Icon';
import { handlerLogin } from '../../helpers/actionsAuth';
import { getContext } from '../../helpers/selectors';

const PushLogin = props => {
  const { title, icon } = props;
  const { platform, fullUrl } = useSelector(getContext);
  return (
    <Card shadow="elevated" className={`note-html-login container-${icon}`}>
      {icon === 'Warranty30' ? (
        <CardContent className="warranty-icon-container">
          <Icon containerClassName={['note-html-login-warranty-icon']} type="Warranty30" />
        </CardContent>
      ) : (
        <CardContent className="rocket-icon-container">
          <Icon color="#3483FA" containerClassName={['note-html-login-rocket-icon']} type="Rocket40" />
        </CardContent>
      )}
      <CardContent className={`push-login-title-container ${icon}`}>
        <Text id="push-login-title" component="p" size="s">
          {title}
        </Text>
      </CardContent>
      {icon === 'Warranty30' && (
        <CardContent className="chevron-icon-container">
          <a aria-labelledby="push-login-title" href={handlerLogin(platform?.siteId, fullUrl)}>
            <Icon containerClassName={['note-html-login-chevron-icon']} type="ChevronRight20" />
          </a>
        </CardContent>
      )}
    </Card>
  );
};

PushLogin.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
};

export default PushLogin;
